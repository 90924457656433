<template>
  <loading-container :loading="isLoading">
    <activity
      ref="activity"
      v-slot="{
        variableContext,
        viewMode,
        toggleSplitView,
        splitViewAvailable
      }"
      :activity="activity"
      :response="response"
    >
      <sticky-header>
        <template #primary-navigation>
          <breadcrumb v-if="activity">
            <breadcrumb-item
              :to="{
                name: 'activities',
                query: { library: backLink }
              }"
            >
              {{ backLinkText }}
            </breadcrumb-item>
          </breadcrumb>
        </template>
        <template #page-action>
          <form-button
            v-if="isVetted && !viewAsStudent"
            link
            @click="toggleAnswers()"
          >
            {{
              answersShown === true
                ? 'Hide Answers & Notes'
                : 'Show Answers & Notes'
            }}
          </form-button>
          <toggle
            v-if="splitViewAvailable"
            link
            :toggle="viewMode === 'split'"
            class="split-question-view"
            @toggle="toggleSplitView"
          >
            <template #on>Show Inline Question View</template>
            <template #off>Show Split Question View</template>
          </toggle>
        </template>
        <template #title="{ isStuck }">
          <sticky-header-title>
            Activities: {{ activity.name }}
          </sticky-header-title>
          <span
            v-if="activity.isArchived"
            class="archived-warning"
            :class="{ 'archived-warning--stuck': isStuck }"
          >
            <icon icon="triangle-exclamation" class="flash-icon" /> Archived
          </span>
        </template>
        <template #sub-title>
          <sticky-header-sub-title>
            <button-dropdown tertiary>
              <template #button>
                Viewing as {{ viewAsStudent ? 'Student' : 'Teacher' }}
              </template>
              <dropdown-action
                v-if="!viewAsStudent"
                @click="toggleViewAsStudent"
                >View as Student</dropdown-action
              >
              <dropdown-action v-else @click="toggleViewAsStudent"
                >View as Teacher</dropdown-action
              >
            </button-dropdown>
            <activity-score :previewingAsTeacher="!viewAsStudent" />
          </sticky-header-sub-title>
        </template>
        <template v-if="!activity.isArchived" #actions>
          <template v-if="!canAssign">
            <form-button @click="addToLibrary"> Copy to Library </form-button>
          </template>
          <template v-else>
            <button-dropdown right secondary>
              <template #button>
                <icon icon="ellipsis-v" />
                <span class="sr-only">Actions</span>
              </template>
              <dropdown-action @click="addToLibrary">
                Copy to Other Library
              </dropdown-action>
              <dropdown-action v-if="canEdit" @click="moveActivity">
                Move to Other Library
              </dropdown-action>
              <dropdown-action v-if="canEdit" @click="archiveActivity">
                Archive activity
              </dropdown-action>
            </button-dropdown>
            <button-link
              v-if="canEdit"
              secondary
              :to="{
                name: 'activity',
                params: { id: activity.id, tab: 'content' }
              }"
            >
              Edit
            </button-link>
            <form-button
              v-if="canAssign"
              @click="assign"
              style="position: relative"
              ref="popoverAnchorRef"
            >
              <template v-if="showVersionHistory">
                <base-popover
                  :anchor-ref="popoverAnchorRef"
                  :visible="popoverVisible"
                  placement="top"
                  >New Version Available
                </base-popover>
                <div
                  class="version-icon"
                  @mouseover="openPopover"
                  @mouseout="closePopover"
                  @focusin="openPopover"
                  @focusout="closePopover"
                >
                  <icon :icon="['far', 'lightbulb']" style="color: #000" />
                </div>
              </template>
              <template v-if="!showVersionHistory && showChanges">
                <base-popover
                  :anchor-ref="popoverAnchorRef"
                  :visible="popoverVisible"
                  placement="top"
                  >Updates Available
                </base-popover>
                <div
                  class="version-icon"
                  @mouseover="openPopover"
                  @mouseout="closePopover"
                  @focusin="openPopover"
                  @focusout="closePopover"
                >
                  <icon :icon="['far', 'lightbulb']" style="color: #000" />
                </div>
              </template>
              Assign
            </form-button>
          </template>
        </template>
      </sticky-header>
      <version-alert
        :showChanges="showChanges && !ignoredUpdates"
        :showVersionHistory="showVersionHistory && !ignoredUpdates"
        :activity="activity"
        @update:ignoredUpdates="refreshActivity"
      />

      <activity-objectives
        :changelog="activity.changelogEntries"
        :variable-context="variableContext"
        :view-as-student="viewAsStudent"
      />
      <div
        v-if="!viewAsStudent"
        :class="viewMode === 'split' ? 'instructor-activity-sections' : ''"
      >
        <activity-notes
          :show="answersShown"
          :variable-context="variableContext"
        />
      </div>

      <activity-sections
        v-slot="{ section, index }"
        :isTeacherPreview="!viewAsStudent"
        :class="{
          'instructor-activity-sections': viewMode === 'split',
          'split-activity-view': splitViewComponentEnabled
        }"
      >
        <activity-section
          :section="section"
          class="instructor-activity-section"
          :variable-context="variableContext"
          :openByDefault="!viewAsStudent"
          :override="!viewAsStudent"
          :view-as-student="viewAsStudent"
        >
          <activity-section-components
            v-slot="{ component }"
            :view-mode="viewMode"
            :split-view-component-enabled="splitViewComponentEnabled"
          >
            <activity-split-view-component
              v-if="component.componentType === 'SplitView'"
              :ref="el => (componentRefs[component._id] = el)"
              :component="component"
              :componentRefs="componentRefs"
              :variable-context="variableContext"
              preview="activity"
              :overrideHiding="!viewAsStudent"
              @change="updateResponse"
              @updateComponentRef="val => (componentRefs = val)"
              :viewAsStudent="viewAsStudent"
              :answersShown="answersShown"
              :viewMode="viewMode"
            />
            <activity-section-component
              v-if="component.componentType !== 'SplitView'"
              v-slot="{ onSubmit, onComplete, onCanSubmitChange, canSubmit }"
              :ref="el => (componentRefs[component._id] = el)"
              :component="component"
              :variable-context="variableContext"
              preview="activity"
              :overrideHiding="!viewAsStudent"
              @change="updateResponse"
              :viewAsStudent="viewAsStudent"
            >
              <activity-section-component-renderer
                :component="component"
                :variable-context="variableContext"
                :answersShown="answersShown"
                :viewAsStudent="viewAsStudent"
                :canSubmit="canSubmit"
                preview
                @submit="onSubmit"
                @complete="onComplete"
                @canSubmitChange="onCanSubmitChange"
              />
            </activity-section-component>
          </activity-section-components>
          <activity-section-lock
            isPreview
            :activityIndex="index"
            @lock="lockSection"
          />
        </activity-section>
      </activity-sections>
    </activity>
  </loading-container>
</template>

<script>
import { ref } from 'vue'
import Activity from 'src/modules/activities/components/Activity'
import ActivityScore from 'src/modules/activities/components/ActivityScore'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivityNotes from 'src/modules/activities/components/ActivityNotes'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySection from 'src/modules/activities/components/ActivitySection'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent'
import ActivitySectionLock from 'src/modules/activities/components/ActivitySectionLock'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent.vue'
import CopyActivityToLibraryModal from 'src/shared/components/modals/CopyActivityToLibraryModal'
import client from 'src/shared/api-client.ts'
import { mapState, mapGetters } from 'vuex'
import MoveActivityBetweenLibrariesModal from 'src/shared/components/modals/MoveActivityBetweenLibrariesModal'
import { getActivityAnswers } from '../correct-answers'
import ActivityVersionModal from 'src/modules/activities/components/ActivityVersionModal.vue'
import ActivityChangesModal from 'src/modules/activities/components/ActivityChangesModal.vue'
import ActivitySectionComponentRenderer from '../components/ActivitySectionComponentRenderer.vue'
import useTitle from 'src/shared/hooks/title'
import VersionAlert from 'src/modules/activities/components/VersionAlert.vue'

export default {
  name: 'ActivityPreviewView',
  components: {
    Activity,
    ActivityScore,
    ActivityObjectives,
    ActivityNotes,
    ActivitySections,
    ActivitySection,
    ActivitySectionComponents,
    ActivitySectionComponent,
    ActivitySectionLock,
    ActivitySplitViewComponent,
    ActivitySectionComponentRenderer,
    VersionAlert
  },
  inject: ['$modal', 'browseActivities'],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup() {
    const popoverAnchorRef = ref()
    const componentRefs = {}
    return { componentRefs, popoverAnchorRef }
  },
  data() {
    return {
      activity: {},
      library: null,
      response: {
        responses: [],
        lockedSections: [],
        variables: []
      },
      classes: [],
      isLoading: true,
      answersShown: false,
      viewAsStudent: false,
      popoverVisible: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    splitViewComponentEnabled() {
      return this.activity.splitViewComponentEnabled
    },
    ...mapGetters(['isAdmin', 'isContentDeveloper', 'isVetted']),
    canAssign() {
      return (
        ['owner', 'admin', 'editor', 'viewer'].includes(
          this.activity.library.permissions
        ) && !this.browseActivities.isUnvetted
      )
    },
    ignoredUpdates() {
      return (
        this.activity.ignoredUpdates.findIndex(
          userId => userId === this.user.id
        ) !== -1
      )
    },
    showVersionHistory() {
      return this.activity.revisedVersions
    },
    showChanges() {
      return this.activity.parentChanges?.length > 0
    },
    canEdit() {
      return (
        this.isAdmin ||
        this.isContentDeveloper ||
        ['owner', 'admin', 'editor'].includes(this.activity.library.permissions)
      )
    },
    backLinkText() {
      if (this.activity.library.id === 'pivot') {
        return 'Pivot Interactives Library'
      }

      if (
        this.activity.library.id !== 'pivot' &&
        !this.canAssign &&
        !this.isAdmin
      ) {
        return 'Community Library'
      }
      return this.activity.library.name
    },

    backLink() {
      if (
        this.activity.library.id !== 'pivot' &&
        !this.canAssign &&
        !this.isAdmin
      ) {
        return 'community'
      }

      return this.activity.library.id
    }
  },
  methods: {
    toggleViewAsStudent() {
      this.viewAsStudent = !this.viewAsStudent
    },
    async refreshActivity() {
      const updatedActivity = await client.activities.get({
        activityId: this.id
      })
      this.activity = {
        ...this.activity,
        ignoredUpdates: updatedActivity.ignoredUpdates
      }
    },
    async load() {
      this.isLoading = true
      const [
        activityRequest,
        activityChangelogRequest,
        classesRequest,
        response
      ] = await Promise.all([
        client.activities.get({ activityId: this.id }),
        client.activities.getChangelog({ activityId: this.id }).catch(error => {
          if (error.status !== 403) {
            throw error
          }
        }),
        !this.isContentDeveloper ? client.classes.search({ limit: 1000 }) : [],
        client.activities.getActivityPreviewResponse({ activityId: this.id })
      ])
      this.activity = activityRequest

      this.activity.changelogEntries = activityChangelogRequest
      this.isLoading = false
      this.classes = classesRequest
      this.response = response

      this.response.assignment = {
        showIndicators: true,
        showFeedback: true,
        showScore: true,
        studentFeedbackTiming: 'after-question-submit'
      }
    },
    updateResponse({ response, isDirty }) {
      // Update response object.
      const index = this.response.responses.findIndex(
        r => r.component === response.component
      )
      if (index >= 0) {
        this.response.responses.splice(index, 1, response)
      } else {
        this.response.responses.push(response)
      }
      if (!isDirty) {
        // if the response has been graded, update the dependency info
        this.$refs.activity.checkComponentDependencies()
      }
    },
    lockSection(index) {
      this.response.lockedSections.push(index)
    },
    toggleAnswers() {
      if (this.answersShown) {
        this.hideAnswers()
      } else {
        this.showAnswers()
      }
    },
    showAnswers() {
      this.response.responses = getActivityAnswers(this.activity)
      this.answersShown = true
    },
    hideAnswers() {
      this.response.responses = []
      this.answersShown = false
    },
    async addToLibrary() {
      await this.$modal.show(CopyActivityToLibraryModal, {
        activity: {
          id: this.activity._id,
          ...this.activity
        }
      })
    },

    async assign() {
      if (this.showVersionHistory) {
        const { status } = await this.$modal.show(ActivityVersionModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          updatedActivity: this.activity.revisedVersions[0],
          libraryId: this.activity.library.id,
          ignored: this.ignoredUpdates
        })
        if (status === 'refresh') {
          await this.refreshActivity()
          return
        }
        if (status !== 'ok') {
          return
        }
      } else if (this.showChanges) {
        const { status } = await this.$modal.show(ActivityChangesModal, {
          activityId: this.activity.id,
          activityName: this.activity.name,
          changelogEntries: this.activity.parentChanges,
          libraryId: this.activity.library.id,
          parentActivityId: this.activity.pivotParentId,
          ignored: this.ignoredUpdates
        })
        if (status === 'refresh') {
          await this.refreshActivity()
          return
        }
        if (status !== 'ok') {
          return
        }
      }
      this.$router.push({
        name: 'new_assignment',
        query: { activityIds: [this.id] }
      })
    },
    async archiveActivity() {
      await client.activities.archive({
        activityId: this.activity.id,
        isArchived: true
      })
      await this.load()
    },
    async moveActivity() {
      await this.$modal.show(MoveActivityBetweenLibrariesModal, {
        activity: this.activity
      })
    },
    async unarchiveActivity() {
      await client.activities.archive({
        activityId: this.activity.id,
        isArchived: false
      })
      await this.load()
    },
    closePopover() {
      this.popoverVisible = false
    },
    openPopover() {
      this.popoverVisible = true
    }
  },
  async created() {
    await this.load()
    useTitle(() =>
      this.activity.name
        ? `${this.activity.name} | Preview`
        : 'Preview Activity'
    )
    this.$refs.activity?.checkComponentDependencies()

    let hashParams = window.location.hash
    hashParams = hashParams.replace('#component-', '')

    if (hashParams) {
      const el = this.componentRefs[hashParams]
      if (el) {
        el.$el.scrollIntoView()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-button:not(.modal-button-submit):not(.form-button--link) + .form-button {
  margin-right: 0;
}

.split-question-view {
  margin-left: 10px;
}

.instructor-activity-sections {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-left: 30px;
  padding-right: 30px;
}

.archived-warning {
  font-size: 18px;
  color: $color-warning;
  margin-left: 10px;
}
.archived-warning--stuck {
  font-size: 14px;
}

p {
  font-style: italic;
}
.version-icon {
  background: #efcc4f;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: -10px;
  right: -10px;
}
</style>
