<template>
  <form-button
    v-bind="{ ...attrs, secondary, tertiary, unstyled, link }"
    :id="id"
    ref="buttonRef"
    :class="{ [buttonClass]: true, open: showMenu, [$attrs.class]: true }"
    aria-haspopup="true"
    :aria-expanded="showMenu"
    :disabled="disabled"
    @click="onButtonClick"
  >
    <!-- @slot Button content. -->
    <slot name="button" :is-open="showMenu">{{ text }}</slot>
  </form-button>
  <component
    :is="menuTag"
    v-if="showMenu"
    ref="menuRef"
    class="button-dropdown__menu"
    :class="{ [menuClass]: true }"
    :aria-labelledby="id"
    :role="menuTag === 'ul' ? undefined : 'group'"
    @focusout="onMenuBlur"
    @keydown="onKeyDown"
  >
    <!-- @slot Menu options. Use `dropdown-action`, `dropdown-link`, and `dropdown-copy` here.  -->
    <slot />
  </component>
</template>

<script>
import { computed, ref, watchPostEffect } from 'vue'

import {
  computePosition,
  autoUpdate,
  flip,
  shift,
  offset
} from '@floating-ui/dom'
let idCounter = 0

export default {
  name: 'ButtonDropdown',
  inheritAttrs: false,
  provide() {
    return {
      buttonDropdown: computed(() => ({
        isOpen: this.showMenu,
        close: this.close
      }))
    }
  },
  props: {
    /**
     * If controlled, determines the open state of the dropdown.
     */
    open: {
      type: Boolean,
      default: false
    },
    /**
     * The text of the button.
     * Prefer the button slot as it is more flexible.
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * Aligns the menu with the right edge of the button.
     * Default is with the left edge.
     */
    right: {
      type: Boolean,
      default: false
    },
    /**
     * Aligns the menu with the enter of the button.
     * Default is with the left edge.
     */
    center: {
      type: Boolean,
      default: false
    },
    /**
     * Aligns the menu with the side of the button.
     * Default is with the left side, but the right prop moves to the right side.
     */
    side: {
      type: Boolean,
      default: false
    },
    /**
     * Shows the menu above the button.
     * Default is below.
     */
    up: {
      type: Boolean,
      default: false
    },
    /** Uses a secondary style `form-button`. */
    secondary: {
      type: Boolean,
      default: false
    },
    /** Uses a tertiary style `form-button`. */
    tertiary: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    unstyled: {
      type: Boolean,
      default: false
    },
    /** Additional css classes to add to the button element. */
    buttonClass: {
      type: String,
      default: ''
    },
    /** Additional css classes to add to the menu list element. */
    menuClass: {
      type: String,
      default: ''
    },
    menuTag: {
      type: String,
      default: 'ul'
    },
    offset: {
      type: null,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const buttonRef = ref()
    const menuRef = ref()

    const placement = computed(() => {
      if (props.side) {
        if (props.center) {
          return props.right ? 'right' : 'left'
        } else {
          return `${props.right ? 'right' : 'left'}-${
            props.up ? 'end' : 'start'
          }`
        }
      } else {
        if (props.center) {
          return props.up ? 'top' : 'bottom'
        } else {
          return `${props.up ? 'top' : 'bottom'}-${
            props.right ? 'end' : 'start'
          }`
        }
      }
    })

    watchPostEffect(onCleanup => {
      if (buttonRef.value && menuRef.value) {
        const cleanup = autoUpdate(
          buttonRef.value.$el,
          menuRef.value,
          async () => {
            if (buttonRef.value.$el && menuRef.value) {
              const { x, y } = await computePosition(
                buttonRef.value.$el,
                menuRef.value,
                {
                  placement: placement.value,
                  middleware: [offset(props.offset), flip(), shift()]
                }
              )
              if (menuRef.value) {
                Object.assign(menuRef.value.style, {
                  left: `${x}px`,
                  top: `${y}px`
                })
              }
            }
          }
        )

        onCleanup(cleanup)
      }
    })

    return {
      buttonRef,
      menuRef
    }
  },
  data() {
    return {
      id: `button-dropdown-${idCounter++}`,
      localShowMenu: false,
      keyboardNavigation: false
    }
  },
  computed: {
    attrs() {
      const { 'onUpdate:open': onUpdate, class: _, ...attrs } = this.$attrs
      return attrs
    },
    onUpdate() {
      return this.$attrs['onUpdate:open']
    },
    isControlled() {
      return !!this.onUpdate
    },
    showMenu: {
      get() {
        return this.isControlled ? this.open : this.localShowMenu
      },
      set(value) {
        if (this.isControlled) {
          this.onUpdate(value)
        } else {
          this.localShowMenu = value
        }
      }
    }
  },
  methods: {
    focus(e) {
      this.buttonRef.focus()
    },
    close() {
      this.showMenu = false
    },
    onButtonClick() {
      this.showMenu = !this.showMenu
    },
    // This allows the menu blur event to determine whether the blurring took place
    // because of a click or a keyboard control.
    onKeyDown(e) {
      if (e.key === 'Tab') {
        this.keyboardNavigation = true
      }
    },
    // When the menu is blurred from keyboard navigation, we want to close it and focus the button again.
    onMenuBlur(e) {
      if (
        e.relatedTarget !== this.buttonRef &&
        !this.menuRef?.contains(e.relatedTarget) &&
        this.keyboardNavigation
      ) {
        this.showMenu = false
        if (this.keyboardNavigation && this.buttonRef?.$el.tabIndex !== -1) {
          this.buttonRef?.focus()
        }
      }
      this.keyboardNavigation = false
    },
    onWindowClick(e) {
      const isWithinComponent = document
        .elementsFromPoint(e.clientX, e.clientY)
        .some(el => el === this.menuRef || el === this.buttonRef.$el)
      if (!isWithinComponent) {
        this.showMenu = false
      }
    }
  },
  unmounted() {
    window.removeEventListener('click', this.onWindowClick)
  },
  watch: {
    showMenu: {
      handler() {
        if (this.showMenu) {
          setTimeout(() => {
            window.addEventListener('click', this.onWindowClick)
          })
        } else {
          window.removeEventListener('click', this.onWindowClick)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss">
.button-dropdown__menu {
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0;
  text-align: left;
  list-style: none;
  font-size: 14px;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  &.__scrollable {
    max-height: 300px;
    overflow-y: auto;
  }
}

.split {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 2px solid;
}

.divider {
  height: 1px;
  margin: 8px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
</style>
