<template>
  <view-container normal>
    <flash />
    <help-banner :license-type="quote.licenseType" />
    <div v-if="quote.licenseType !== 'trial'" class="card banner-card">
      <order-banner :quote="quote" :allow-add="false" />
    </div>
    <div class="card">
      <h1 class="header">Education Contact Person</h1>
      <br />
      <h2 class="sub-header">
        Who will manage the license and distribute seats?
      </h2>
      <div class="plain-text">
        The Education Contact will manage login credentials for the account.
        This is usually an instructor, department chair, or curriculum director.
        Payment may be managed by someone different; we’ll collect contact
        information later.
      </div>
      <async-form class="form" @submit="onSubmit">
        <form-group class="input-30">
          <form-label for="contactFirstName">First Name</form-label>
          <text-input
            id="contactFirstName"
            v-model="educator.contactFirstName"
            :rules="isAdmin ? null : 'required'"
            label="first name"
            autocomplete="given-name"
          />
        </form-group>
        <form-group class="input-30">
          <form-label for="contactLastName">Last Name</form-label>
          <text-input
            id="contactLastName"
            v-model="educator.contactLastName"
            :rules="isAdmin ? null : 'required'"
            label="last name"
            autocomplete="family-name"
          />
        </form-group>
        <form-group class="input-50">
          <form-label for="contactEmail">Email Address</form-label>
          <text-input
            id="contactEmail"
            v-model="educator.contactEmail"
            rules="required|email"
            label="email address"
            type="email"
            autocomplete="email"
            @change="handleContactEmailChange"
          />
        </form-group>
        <form-group>
          <h2 class="sub-header">
            What is the Education Contact’s role or job title?
            <span class="sub-header--helper">Select all that apply.</span>
          </h2>
          <choice-input
            v-model="educator.contactRoles"
            label="roles"
            :rules="isAdmin ? null : 'required'"
          >
            <checkbox
              v-for="role in contactRoleOptions"
              :key="role"
              :value="role"
            >
              {{ role }}
            </checkbox>
          </choice-input>
        </form-group>
        <form-group>
          <h2 class="sub-header">
            For which subjects are you interested in using Pivot Interactives?
            <span class="sub-header--helper">Select all that apply.</span>
          </h2>
          <choice-input
            v-model="educator.contactSubjects"
            label="subjects"
            :rules="isAdmin ? null : 'required'"
          >
            <checkbox
              v-for="subject in contactSubjectOptions"
              :key="subject"
              :value="subject"
            >
              {{ subject }}
            </checkbox>
          </choice-input>
        </form-group>
        <div><hr /></div>
        <h1 class="header">Institution Details</h1>
        <form-group class="input-50">
          <form-label for="institutionZipCode">Zip/Postal Code</form-label>
          <div class="input-flex">
            <text-input
              id="institutionZipCode"
              v-model="educator.institutionZipCode"
              :rules="{
                required: !isAdmin,
                postalCode: { country: educator.institutionCountry }
              }"
              label="zip code"
              autocomplete="postal-code"
              v-on:keydown.enter.prevent="queryLocation"
              @blur="queryLocation"
            />
          </div>
        </form-group>
        <form-group class="input-50">
          <form-label for="institutionName">Name of Institution</form-label>
          <select-field
            :disabled="!institutionEnabled"
            id="institutionName"
            :rules="isAdmin ? null : 'required'"
            label="institutionName"
            @update:modelValue="value => selectSchool(value)"
            :modelValue="initialSelection"
          >
            <option
              v-for="institution in schoolList"
              :key="institution.AccountName"
              :value="institution"
            >
              {{ institution.AccountName }}
            </option>
          </select-field>
          <p v-if="institutionEnabled">
            Talk to
            <a href="mailto:sales@pivotinteractives.com"
              >sales@pivotinteractives.com</a
            >
            if your school is not available.
          </p>
        </form-group>
        <form-group class="input-30">
          <form-label for="institutionCountry">Country</form-label>
          <select-field
            :disabled="true"
            id="institutionCountry"
            v-model="educator.institutionCountry"
            :rules="isAdmin ? null : 'required'"
            label="country"
          >
            <option
              v-for="option in countryOptions"
              :key="option.code"
              :value="option.code"
            >
              {{ option.name }}
            </option>
          </select-field>
        </form-group>
        <form-group class="input-50">
          <form-label for="institutionAddress">Street Address</form-label>
          <text-input
            :disabled="!institutionEnabled"
            id="institutionAddress"
            v-model="educator.institutionAddress"
            :rules="isAdmin ? null : 'required'"
            label="address"
            type="address"
            autocomplete="address-line1"
          />
        </form-group>
        <form-group class="input-50">
          <form-label for="institutionAddress2"
            >Department, Building, Unit, etc. (Optional)
          </form-label>
          <text-input
            :disabled="!institutionEnabled"
            id="institutionAddress2"
            v-model="educator.institutionAddress2"
            label="address 2"
            autocomplete="address-line2"
          />
        </form-group>
        <form-group class="input-30">
          <form-label for="institutionCity">City</form-label>
          <text-input
            :disabled="!institutionEnabled"
            id="institutionCity"
            v-model="educator.institutionCity"
            :rules="isAdmin ? null : 'required'"
            label="city"
            autocomplete="address-level2"
          />
        </form-group>
        <form-group class="input-30">
          <form-label for="institutionState">State/Province/Region</form-label>
          <text-input
            :disabled="!institutionEnabled"
            v-if="educator.institutionCountry !== 'US'"
            id="institutionState"
            v-model="educator.institutionState"
            :rules="isAdmin ? null : 'required'"
            label="state"
            autocomplete="address-level1"
          />
          <select-field
            :disabled="!institutionEnabled"
            id="institutionState"
            v-if="educator.institutionCountry === 'US'"
            v-model="educator.institutionState"
            :rules="isAdmin ? null : 'required'"
            label="state"
            autocomplete="address-level1"
          >
            <option
              v-for="option in usStateOptions"
              :key="option[0]"
              :value="option[0]"
            >
              {{ option[1] }}
            </option>
            <option value="other">Other</option>
          </select-field>
        </form-group>
        <div><hr /></div>
        <form-group class="input-30">
          <form-label for="emailDomain"
            >Institution Domain (Optional)</form-label
          >
          <text-input
            :disabled="!institutionEnabled"
            id="emailDomain"
            v-model="educator.emailDomain"
            label="institution domain"
            rules="schoolDomain"
          />
        </form-group>
        <form-group class="input-50">
          <form-label for="institutionWebsite">
            Institution Website (optional)
          </form-label>
          <text-input
            :disabled="!institutionEnabled"
            id="institutionWebsite"
            v-model="educator.institutionWebsite"
            label="website"
            type="url"
            autocomplete="url"
          />
        </form-group>
        <div v-if="isStudentPaid">
          <hr />
          <form-group>
            <checkbox v-model="termsAccepted">
              On behalf of the school or institution identified above, I accept
              the
              <hyper-link :to="{ name: 'terms' }">Standard Terms</hyper-link>
              and certify that I have the authority to bind the school to the
              Standard Terms.</checkbox
            >

            <checkbox v-model="privacyAccepted">
              On behalf of the school or institution identified above, I have
              reviewed
              <hyper-link :to="{ name: 'privacy' }">
                Discovery Education’s Privacy Policy</hyper-link
              >
              and acknowledge Discovery Education will process personal
              information in accordance with this Privacy Policy.</checkbox
            >
          </form-group>
        </div>
        <form-group class="button-group">
          <submit-button
            action="submit"
            class="form-button--beta"
            :disabled="isStudentPaid && !termsAccepted && !privacyAccepted"
          >
            {{ nextButtonText }}
          </submit-button>
          <submit-button
            v-if="isAdmin && !isStudentPaid"
            action="saveQuote"
            class="form-button--alpha"
          >
            Save Quote
          </submit-button>

          <submit-button
            v-if="showQuoteButton"
            action="quote"
            class="form-button--alpha"
          >
            {{ isUpdatingQuote ? 'Send me Updated Quote' : 'Send me a quote' }}
          </submit-button>
        </form-group>
        <p v-if="invalidPurchaseAttempt" class="invalid-warning">
          This email is associated with an invalid account on Pivot
          Interactives. If you are a student, please contact your instructor for
          a code. If you feel you have reached this message in error, contact
          support.
        </p>
      </async-form>
    </div>
  </view-container>
</template>

<script>
import {
  getPurchaseData,
  mergePurchaseData,
  getQuoteInProgress,
  usStateOptions,
  countryOptions
} from 'src/shared/utils/purchases'
import OrderBanner from 'src/modules/purchases/components/OrderBanner'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import { mapGetters } from 'vuex'
import Flash from 'src/shared/components//Flash'
import { isBefore, add } from 'date-fns'
import client from 'src/shared/api-client'

const initialFormData = {
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactRoles: [],
  contactSubjects: [],
  institution: '',
  institutionCountry: 'US',
  institutionAddress: '',
  institutionAddress2: '',
  institutionCity: '',
  institutionState: '',
  institutionZipCode: '',
  institutionWebsite: ''
}

export default {
  name: 'EducatorContactView',
  components: { OrderBanner, HelpBanner, Flash },
  props: ['licenseType'],
  data() {
    return {
      initialSelection: null,
      educator: {
        ...initialFormData,
        ...getPurchaseData().educatorContactForm
      },
      usStateOptions,
      countryOptions,
      contactRoleOptions: [
        'Instructor',
        'Department Head',
        'Curriculum Administrator',
        'School Administrator',
        'IT Administrator',
        'Teaching Assistant',
        'Other'
      ],
      contactSubjectOptions: [
        'Biology',
        'Chemistry',
        'Earth Science',
        'Environmental Science',
        'Physics',
        'Astronomy',
        'None'
      ],
      emailDomainTouched: false,
      invalidPurchaseAttempt: false,
      schoolList: undefined,
      termsAccepted: false,
      privacyAccepted: false
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentPurchaseData = getPurchaseData()
    if (
      (currentPurchaseData.purchaseType !== 'license' &&
        currentPurchaseData.purchaseType !== 'renewal') ||
      !currentPurchaseData.configureLicenseForm
    ) {
      return next({
        name: 'new_license_session',
        params: { licenseType: 'institution' }
      })
    }
    return next()
  },
  computed: {
    ...mapGetters(['isAdmin']),
    institutionEnabled() {
      return this.schoolList
    },
    purchaseType() {
      return getPurchaseData().purchaseType
    },
    quote() {
      return getQuoteInProgress()
    },
    otherRoleRule() {
      return this.educator.contactRoles.includes('other') ? 'required' : ''
    },
    isTrialLicense() {
      return this.quote.licenseType === 'trial'
    },
    isStudentPaid() {
      return this.quote.licenseType === 'student'
    },
    showQuoteButton() {
      return this.quote.licenseType === 'institution'
    },
    nextButtonText() {
      return this.isStudentPaid || this.isTrialLicense
        ? 'Create Account'
        : 'Continue to Payment'
    },
    isUpdatingQuote() {
      return typeof this.quote.id !== 'undefined'
    },
    contactRoles() {
      return (this.educator.contactRoles || [])
        .map(r => r.replace(/ /g, '-').toLowerCase())
        .join(',')
    },
    contactSubjects() {
      return (this.educator.contactSubjects || [])
        .map(r => r.replace(/ /g, '-').toLowerCase())
        .join(',')
    }
  },
  methods: {
    async onSubmit({ action, done }) {
      try {
        mergePurchaseData({ educatorContactForm: this.educator })
        const { configureLicenseForm, educatorContactForm, id, customPricing } =
          getPurchaseData()

        const {
          contactFirstName: firstName,
          contactLastName: lastName,
          contactEmail: email,
          contactCountry: country,
          institution: institutionName,
          emailDomain,
          salesforceId
        } = educatorContactForm
        const { licenseLevel: level, isDiscovery } = configureLicenseForm

        if (this.isTrialLicense) {
          await client.licenses.createTrialLicense({
            firstName,
            lastName,
            email,
            country,
            institutionName,
            emailDomain,
            salesforceId,
            level,
            isDiscovery,
            roles: this.contactRoles,
            subjects: this.contactSubjects
          })
          this.$router.push({ name: 'purchase_complete' })

          return
        } else if (this.isStudentPaid) {
          await client.licenses.createStudentLicense({
            firstName,
            lastName,
            email,
            country,
            institutionName,
            emailDomain,
            salesforceId,
            level,
            isDiscovery,
            roles: this.contactRoles,
            subjects: this.contactSubjects
          })
          this.$router.push({ name: 'purchase_complete' })

          return
        }
        let currentQuoteId = id
        if (this.isUpdatingQuote) {
          const payload =
            !customPricing || this.isAdmin
              ? {
                  ...configureLicenseForm,
                  ...educatorContactForm
                }
              : educatorContactForm
          if (
            this.isAdmin &&
            isBefore(new Date(this.quote.quoteExpires), new Date())
          ) {
            payload.quoteExpires = add(new Date(), { months: 1 })
          }
          await client.purchases.updateLicenseQuote({ id, ...payload })
        } else {
          const { id } = await client.purchases.createLicenseQuote({
            ...configureLicenseForm,
            ...educatorContactForm
          })
          currentQuoteId = id
          mergePurchaseData({ id })
        }

        if (action === 'quote') {
          try {
            await client.purchases.sendQuoteEmail({ id: currentQuoteId })
          } catch (e) {
            this.$error('Could not send email')
          } finally {
            this.$router.push({
              name: 'purchase_quote',
              params: { id: currentQuoteId },
              query: { quoteSent: true }
            })
          }
        } else if (action === 'saveQuote') {
          this.$router.push({
            name: 'purchase_quote',
            params: { id: currentQuoteId }
          })
        } else {
          this.$router.push({ name: 'purchase_billing_information' })
        }
        done()
      } catch (err) {
        done(false)
        if (
          (err?.body?.errors ?? []).some(
            error =>
              error.message === 'Account is associated with invalid email.'
          )
        ) {
          this.invalidPurchaseAttempt = true
        } else {
          throw err
        }
      }
    },
    handleContactEmailChange(contactEmail) {
      this.invalidPurchaseAttempt = false

      if (!this.educator.emailDomain) {
        const domainEnding = contactEmail.slice(-4)
        const domainIndex = contactEmail.indexOf('@')

        const emailDomain =
          domainIndex > -1 ? contactEmail.slice(domainIndex + 1) : undefined

        if (['.edu', '.org', '.gov'].includes(domainEnding)) {
          this.educator.emailDomain = emailDomain
        }
      }
    },

    async queryLocation() {
      if (this.educator.institutionZipCode) {
        const response = await fetch(
          `https://discoveryed.my.salesforce-sites.com/WebLead/services/apexrest/Account/?postalCode=${this.educator.institutionZipCode}`
        )
        const queryData = await response.json()
        //filter quetyData to only show results where they Type property does not contain "District"
        this.schoolList = queryData.filter(
          data => !data.Type.includes('District')
        )
        for (const school in this.schoolList) {
          if (this.educator.salesforceId === this.schoolList[school].Id) {
            this.initialSelection = this.schoolList[school]
          }
        }
      }
    },
    selectSchool(value) {
      this.educator.institution = value.AccountName
      this.educator.institutionState = value.AccountState
      this.educator.institutionCountry = 'US'
      this.educator.salesforceId = value.Id
    }
  },
  mounted() {
    this.queryLocation()
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.banner-card {
  padding: 16px 64px;
}
.sub-header--helper {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
}
.overlay {
  background-color: #d5d2d3;
  padding: 24px 0px;
  display: flex;
  justify-content: center;
}
.payment-view {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 48px 64px;
  line-height: 150%;
  width: 928px;
}
.header {
  font-size: 30px;
}
.sub-header {
  font-size: 20px;
  font-weight: 700;
}
.plain-text {
  font-size: 20px;
  line-height: 150%;
  font-weight: normal;
}
.form-label {
  font-size: 14px;
  line-height: 20px;
}
.pi-form-control {
  height: 40px;
  background: #fafafa;
  border: 1px solid #d4d3d3;
  box-sizing: border-box;
  border-radius: 3px;
}
.form-input {
  position: static;
  background-color: grey;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.input-50 {
  width: 50%;
}
.input-30 {
  width: 33%;
}
.input-25 {
  width: 25%;
}
.input-flex {
  width: 75%;
  display: inline-flex;
  align-items: baseline;
  gap: 5px;
}
.invalid-warning {
  color: $color-error;
}
</style>
