import {
  BigNumber,
  ConditionalNode,
  MathExpression,
  RelationalNode
} from 'mathjs'
import { Variable } from './types'
import math from './utils/math'

const NUMBER_AS_STRING = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)?$/
const VARIABLE_MATCH = /\$([A-Za-z0-9_ℹ]+)(?:\.\$?([A-Za-z0-9_ℹ]+))?/g

export function replaceVariablesWithNames(
  expression: string,
  variables: Variable[]
): string {
  return expression.replaceAll(
    VARIABLE_MATCH,
    (match: string, parentId: string, childId: string): string => {
      const variable = variables.find(v => v.id === parentId)
      if (!variable) return match

      if (!childId) {
        return variable.variableType === 'collection'
          ? match
          : `$${variable.name}`
      }

      if (variable.variableType !== 'collection') return match

      const child = variable.variables?.find(v => v.id === childId)
      if (!child) return match

      return `$${variable.name}.${child.name}`
    }
  )
}
export function replaceVariablesWithIds(
  expression: string,
  variables: Variable[]
): string {
  return expression.replaceAll(
    VARIABLE_MATCH,
    (match: string, parentName: string, childName: string): string => {
      const variable = variables.find(v => v.name === parentName)
      if (!variable) return match

      if (!childName) {
        return variable.variableType === 'collection'
          ? match
          : `$${variable.id}`
      }

      if (variable.variableType !== 'collection') return match

      const child = variable.variables?.find(v => v.name === childName)
      if (!child) return match

      return `$${variable.id}.$${child.id}`
    }
  )
}

export function validateExpression(
  expr: string,
  variables: Variable[]
): { isValid: true } | { isValid: false; invalidSymbols: string[] } {
  if (!expr) return { isValid: true }

  try {
    const replaced = replaceVariablesWithIds(expr, variables)
    const expression = math.parse(replaced)

    const symbols = expression.filter(
      (node, _, parent) =>
        node instanceof math.SymbolNode &&
        !(node.name in math) &&
        !(parent instanceof math.AccessorNode)
    ) as math.SymbolNode[]
    const accesses = expression.filter(
      node =>
        node instanceof math.AccessorNode &&
        node.object instanceof math.SymbolNode &&
        !(node.object.name in math)
    ) as math.AccessorNode[]

    const invalidSymbols = [
      ...symbols
        .filter(node => {
          return !variables.some(v => `$${v.id}` === node.name)
        })
        .map(node => node.name),
      ...accesses
        .filter(node => {
          const collection = variables.find(
            v =>
              node.object instanceof math.SymbolNode &&
              `$${v.id}` === node.object.name
          )
          return (
            collection?.variableType !== 'collection' ||
            !collection.variables.some(
              v => `$${v.id}` === (node.index.dimensions[0] as any).value
            )
          )
        })
        .map(
          (node: any) => `${node.object.name}.${node.index.dimensions[0].value}`
        )
    ]

    if (invalidSymbols.length === 0) {
      return { isValid: true }
    } else {
      return { isValid: false, invalidSymbols }
    }
  } catch (err) {
    return {
      isValid: false,
      invalidSymbols: []
    }
  }
}

const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 8 })
export function formatExpressionResult(result: any): any {
  if (typeof result === 'number') {
    return formatter.format(result)
  } else {
    return result
  }
}

interface ExpressionVariableMap {
  [key: string]:
    | BigNumber
    | string
    | Record<string, BigNumber | string>
    | undefined
}

export function evaluateExpression(
  formula: string,
  variables: Variable[],
  response?: string | number
) {
  let match = formula?.match(VARIABLE_MATCH)
    ? replaceVariablesWithIds(formula, variables)
    : formula
  if (!match) return formula

  const expressionVariables: ExpressionVariableMap = {
    $response:
      typeof response === 'number'
        ? math.bignumber(response)
        : response ?? 'response'
  }
  const usedVariables = variables.filter(
    v => v.id && match.includes(`$${v.id}`)
  )

  const variablesWithoutValues = usedVariables.some(variable => {
    if (variable.variableType === 'studentResponse')
      return (
        variable.value === null ||
        variable.value === undefined ||
        variable.value === variable.content
      )
    return variable.value === null || variable.value === undefined
  })
  if (variablesWithoutValues) {
    return ''
  }
  variables.forEach(variable => {
    if (variable.value === null || variable.value === undefined) return
    switch (typeof variable.value) {
      case 'number': {
        expressionVariables[`$${variable.id}`] = math.bignumber(variable.value)
        break
      }
      case 'string': {
        if (NUMBER_AS_STRING.test(variable.value)) {
          expressionVariables[`$${variable.id}`] = math.bignumber(
            variable.value
          )
        } else {
          expressionVariables[`$${variable.id}`] = variable.value
        }
        break
      }
      case 'object': {
        expressionVariables[`$${variable.id}`] = Object.fromEntries(
          Object.entries(variable.value).map(([key, value]) => {
            // Older activities don't have the dollar sign in nested variables, so we have to add it.
            match = match.replaceAll(
              `$${variable.id}.${key}`,
              `$${variable.id}.$${key}`
            )
            return [
              `$${key}`,
              typeof value === 'number' ? math.bignumber(value) : value
            ]
          })
        )
        break
      }
    }
  })
  try {
    const result = math.evaluate(match ?? '', expressionVariables)
    return math.isBigNumber(result) ? result.toNumber() : result
  } catch (_e) {
    return ''
  }
}

export function getExpressionVariables(
  expression: string,
  variables: Variable[]
): Variable[] {
  const matches = Array.from(expression.matchAll(VARIABLE_MATCH))
  return variables.filter(variable =>
    matches.some(
      match => variable.name === match[1] || variable.id === match[1]
    )
  )
}

// MathJS doesn't support simplifying conditions,
// so if we can evaluate the condition,
// then we can replace it with the true or false expression.
export function simplifyExpression(
  expression: math.MathNode,
  variableScope: { [k: string]: number | string }
): math.MathNode {
  const simplify = (expression: math.MathNode) => {
    return math.simplify(
      expression,
      [
        node => {
          if (node instanceof math.ConditionalNode && node.isConditionalNode) {
            const condition = math.evaluate(
              node.condition.toString(),
              variableScope
            )
            return condition ? node.trueExpr : node.falseExpr
          }
          return node
        },
        ...math.simplify.rules
      ],
      variableScope,
      { exactFractions: false }
    )
  }
  let simplifiedCondition
  // If we can't simplify the entire expression because it has conditions and relations,
  // then try to simplify just the sub parts of these operations.
  try {
    simplifiedCondition = simplify(expression)
  } catch (error) {
    simplifiedCondition = expression.transform(node => {
      if (node instanceof RelationalNode && node.isRelationalNode) {
        node.params = node.params.map(param => {
          try {
            return simplify(param)
          } catch (error) {
            return param
          }
        })
      } else if (node instanceof ConditionalNode && node.isConditionalNode) {
        try {
          node.condition = simplify(node.condition)
        } catch (e) {
          /* empty */
        }
        try {
          node.trueExpr = simplify(node.trueExpr)
        } catch (e) {
          /* empty */
        }
        try {
          node.falseExpr = simplify(node.falseExpr)
        } catch (e) {
          /* empty */
        }
      }
      return node
    })
  }
  return simplifiedCondition
}

// Replace all known variables.
export function resolveExpressionVariables(
  expr: MathExpression,
  variableScope: { [k: string]: number | string },
  variables: Variable[]
): math.MathNode {
  const processedScope = Object.fromEntries(
    Object.entries(variableScope).map(([key, value]) => [
      key,
      typeof value === 'number' ? math.bignumber(value) : value
    ])
  )

  return math.resolve(
    math.parse(expr).transform(node => {
      if (node instanceof math.AccessorNode && node.isAccessorNode) {
        const variable = variables.find(v => `$${v.id}` === node.object.name)
        if (variable && variable.variableType === 'collection') {
          const subVariable = variable.variables?.find(
            //@ts-expect-error: The type of node.index.dimensions[0].value is unknown but exists
            v => `$${v.id}` === node.index.dimensions[0].value
          )
          if (typeof subVariable?.value === 'number') {
            //@ts-expect-error: we need big number conversion
            return new math.ConstantNode(math.bignumber(subVariable.value))
          } else {
            return math.parse(`$${variable.name}.${subVariable?.name}`)
          }
        } else {
          return node
        }
      } else if (
        node instanceof math.SymbolNode &&
        node.isSymbolNode &&
        node.name === '$response'
      ) {
        return new math.SymbolNode('answer')
      } else {
        return node
      }
    }),
    processedScope
  )
}
