<template>
  <loading-container :loading="isLoading">
    <activity
      ref="activity"
      v-slot="{
        variableContext,
        viewMode,
        toggleSplitView,
        splitViewAvailable
      }"
      :activity="activity"
      :response="response"
    >
      <sticky-header>
        <template #primary-navigation>
          <breadcrumb>
            <breadcrumb-item
              :to="{
                name: 'activities'
              }"
              >Pivot Interactives Library
            </breadcrumb-item>
          </breadcrumb>
        </template>
        <template #title>
          <sticky-header-title>
            Activities: {{ activity.name }}
          </sticky-header-title>
        </template>
        <template #page-action>
          <toggle
            v-if="splitViewAvailable"
            link
            :toggle="viewMode === 'split'"
            class="split-question-view"
            @toggle="toggleSplitView"
          >
            <template #on>Show Inline Question View</template>
            <template #off>Show Split Question View</template>
          </toggle>
          <div
            class="mt-8 mb-[-50px] w-[380px] p-[6px] rounded-md text-warning-orange-600 bg-warning-orange-50 flex items-center"
          >
            <icon icon="warning" class="text-[20px]" />
            <p class="mb-0 ml-[10px]">
              Your work will be saved locally to this computer, and it will
              reappear if you reload this activity in the same browser on this
              computer.
            </p>
          </div>
        </template>
        <template #sub-title>
          <sticky-header-sub-title>
            <activity-score />
          </sticky-header-sub-title>
        </template>
      </sticky-header>
      <activity-objectives
        :variable-context="variableContext"
        :view-as-student="true"
      />
      <activity-sections
        v-slot="{ section, index }"
        :class="viewMode === 'split' ? 'instructor-activity-sections' : ''"
      >
        <activity-section
          v-if="section.index === 0 || activity.isFree"
          :section="section"
          :variable-context="variableContext"
          :view-as-student="true"
        >
          <activity-section-components
            v-slot="{ component }"
            :view-mode="viewMode"
            :split-view-component-enabled="splitViewComponentEnabled"
          >
            <activity-split-view-component
              v-if="component.componentType === 'SplitView'"
              :ref="el => (componentRefs[component._id] = el)"
              :componentRefs="componentRefs"
              :component="component"
              :variable-context="variableContext"
              preview="activity"
              @change="updateResponse"
              view-as-student
              unauthenticated
              :isFreeActivity="activity.isFree"
              :viewMode="viewMode"
            />
            <activity-section-component
              v-if="component.componentType !== 'SplitView'"
              v-slot="{ onSubmit, onCanSubmitChange, canSubmit }"
              :component="component"
              preview="activity"
              @change="updateResponse"
              :variable-context="variableContext"
              view-as-student
            >
              <activity-section-component-renderer
                :component="component"
                :variable-context="variableContext"
                view-as-student
                :canSubmit="canSubmit"
                preview
                @submit="onSubmit"
                @canSubmitChange="onCanSubmitChange"
                unauthenticated
                :readonly="!activity.isFree"
              />
            </activity-section-component>
          </activity-section-components>
          <activity-section-lock :activityIndex="index" @lock="lockSection" />
        </activity-section>
        <template v-else>
          <div class="activity-view__locked-section">
            <h3 class="activity-view__locked-section-header">
              {{ section.name }}
            </h3>
            <router-link :to="{ name: 'free_trial' }">
              Request A Free Trial
            </router-link>
          </div>
        </template>
      </activity-sections>
    </activity>
  </loading-container>
</template>

<script>
import Activity from 'src/modules/activities/components/Activity'
import ActivityScore from 'src/modules/activities/components/ActivityScore'
import ActivityObjectives from 'src/modules/activities/components/ActivityObjectives'
import ActivitySections from 'src/modules/activities/components/ActivitySections'
import ActivitySection from 'src/modules/activities/components/ActivitySection'
import ActivitySectionComponents from 'src/modules/activities/components/ActivitySectionComponents'
import ActivitySectionComponent from 'src/modules/activities/components/ActivitySectionComponent'
import ActivitySectionLock from 'src/modules/activities/components/ActivitySectionLock'
import ActivitySectionComponentRenderer from 'src/modules/activities/components/ActivitySectionComponentRenderer'
import ActivitySplitViewComponent from 'src/modules/activities/components/ActivitySplitViewComponent'
import client from 'src/shared/api-client'

export default {
  name: 'ActivityPreviewView',
  components: {
    Activity,
    ActivityScore,
    ActivityObjectives,
    ActivitySections,
    ActivitySection,
    ActivitySectionComponents,
    ActivitySectionComponent,
    ActivitySectionLock,
    ActivitySectionComponentRenderer,
    ActivitySplitViewComponent
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activity: null,
      isLoading: true,
      response: {
        responses: [],
        lockedSections: [],
        variables: []
      }
    }
  },
  setup() {
    const componentRefs = {}
    return { componentRefs }
  },
  computed: {
    readonly() {
      return !this.activity.isFree
    },
    splitViewComponentEnabled() {
      return this.activity.splitViewComponentEnabled
    }
  },
  methods: {
    async load() {
      this.isLoading = true
      this.activity = await client.activities.get({ activityId: this.id })

      const activityKey = `activity_${this.id}_response`
      const savedResponse = localStorage.getItem(activityKey)
      if (savedResponse) {
        this.response = JSON.parse(savedResponse)
      } else {
        this.response = await client.activities.getActivityPreviewResponse({
          activityId: this.id
        })
      }
      this.response.assignment = {
        showIndicators: true,
        showScore: true,
        showFeedback: true
      }
      this.isLoading = false
    },
    updateResponse({ response, isDirty }) {
      // Update response object.
      const index = this.response.responses.findIndex(
        r => r.component === response.component
      )
      if (index >= 0) {
        this.response.responses.splice(index, 1, response)
      } else {
        this.response.responses.push(response)
      }

      if (!isDirty) {
        // if the response has been graded, update the dependency info
        this.$refs.activity.checkComponentDependencies()
      }
    },
    lockSection(index) {
      this.response.lockedSections.push(index)
    },
    restrictedComponent(componentType) {
      const restrictedComponents = [
        'IFrame',
        'EmbeddedInstance',
        'PhetIOSim',
        'PhetIOSimulation',
        'StudentInstance',
        'InstructorInstance'
      ]

      if (restrictedComponents.includes(componentType) && this.readonly) {
        return true
      }
      return false
    }
  },
  async created() {
    await this.load()
    this.$refs.activity?.checkComponentDependencies()
  },
  watch: {
    response: {
      deep: true,
      handler(newResponse) {
        const activityKey = `activity_${this.id}_response`
        localStorage.setItem(activityKey, JSON.stringify(newResponse))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-view__locked-section {
  margin: 0 0 22px 0;
  padding: 20px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  color: $plum;
  font-weight: bold;
  position: relative;
  display: flex;
}

.split-question-view {
  margin-left: 10px;
}

.activity-view__locked-section-header {
  margin: 0 16px 0 0;
  flex-grow: 1;
}

.instructor-activity-sections {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
