import VueGtag, { optIn, set as gtagSet } from 'vue-gtag'
import { loadScriptAsync } from 'src/shared/hooks/script'
import { format } from 'date-fns'
import { App, nextTick } from 'vue'
import { onConsentLoaded } from './consent'
import { UserRoleValue } from '@pi/types'
import { RouteLocationNormalized, Router } from 'vue-router'
import { Store } from 'vuex'
// import * as Sentry from '@sentry/vue'

declare global {
  interface Window {
    PI_SEGMENT_KEY?: string
    PI_INTERCOM_APP_ID?: string
    PI_ENV?: string
    PI_GA_TRACKING_ID?: string
    PI_GTM_TRACKING_ID?: string
    Intercom: any
    DEAnalyticsInitData: any
    DEAnalytics: any
  }
}

// Page state changes are queued here while the analytics library loads.
// Once the library loads, they are sent to segment in order.

interface StateChange {
  url: string
  previousUrl?: string
}

const queuedStateChanges: StateChange[] = []

export function trackPageChanges(router: Router) {
  if (!window.PI_SEGMENT_KEY) return

  function track(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
    const DEAnalytics = window.DEAnalytics
    if (DEAnalytics) {
      try {
        DEAnalytics.viewStateChange({
          url: `${location.protocol}//${location.host}${to.fullPath}`,
          previousUrl: from
            ? `${location.protocol}//${location.host}${from.fullPath}`
            : ''
        })
      } catch (error) {
        // We stopped capturing these errors because it generates so many errors.
        // Sentry.captureException(error)
      }
    } else {
      queuedStateChanges.push({
        url: `${location.protocol}//${location.host}${to.fullPath}`,
        previousUrl: from
          ? `${location.protocol}//${location.host}${from.fullPath}`
          : ''
      })
    }
  }

  router.isReady().then(() => {
    nextTick().then(() => {
      const { currentRoute } = router
      track(currentRoute.value)
    })

    router.afterEach((to, from) => {
      nextTick().then(() => {
        track(to, from)
      })
    })
  })
}

interface User {
  role: UserRoleValue
  id: string
  intercomHash: string
  firstName: string
  lastName: string
  email: string
  createdAt: Date
  siteLicense?: {
    id: string
  }
}

async function initSegment(user?: User) {
  if (!window.PI_SEGMENT_KEY) return

  const commonFields = {
    key: window.PI_SEGMENT_KEY,
    regionTLD: '.com',
    disableAutoPageTrack: true
  }

  if (!user) {
    window.DEAnalyticsInitData = {
      ...commonFields,
      version: 2
    }
  } else if (user.role === 'teacher') {
    window.DEAnalyticsInitData = {
      ...commonFields,
      version: 5,
      id: user.id,
      username: user.email,
      accountId: user.siteLicense?.id
    }
  } else if (user.role === 'student') {
    window.DEAnalyticsInitData = {
      ...commonFields,
      version: 6,
      id: user.id
    }
  } else {
    return // Ignore content developers and admin users
  }

  await loadScriptAsync({
    url:
      window.PI_ENV === 'production'
        ? `https://apollo.discoveryeducation.com/js/enterprise-analytics.min.js?key=${format(
            new Date(),
            'yyyyMMddHH'
          )}`
        : `https://apollo.dev.discoveryeducation.com/js/enterprise-analytics.min.js?key=${format(
            new Date(),
            'yyyyMMddHH'
          )}`
  })
  window.DEAnalytics.init()
  queuedStateChanges.forEach(stateChange => {
    try {
      window.DEAnalytics.viewStateChange(stateChange)
    } catch (error) {
      // We stopped capturing these errors because it generates so many errors.
      // Sentry.captureException(error)
    }
  })
  queuedStateChanges.splice(0, queuedStateChanges.length)
}

async function initIntercom(user?: User): Promise<void> {
  if (!window.PI_INTERCOM_APP_ID) return

  if (!user) {
    window.Intercom('boot', {
      app_id: window.PI_INTERCOM_APP_ID
    })
  } else if (user.role === 'teacher' && user.intercomHash) {
    window.Intercom('boot', {
      app_id: window.PI_INTERCOM_APP_ID,
      user_id: user.id,
      user_hash: user.intercomHash
    })
  }
}

async function initGoogleAnalytics(user?: User): Promise<void> {
  if (window.PI_GA_TRACKING_ID) {
    gtagSet({ role: user?.role })
    optIn()
  }
}

export function initAnalytics(user?: User) {
  initIntercom(user)

  // No consent is required for logged in users.
  if (user) {
    initSegment(user)
    initGoogleAnalytics(user)
  } else {
    onConsentLoaded(({ performance }) => {
      if (performance) {
        initSegment(user)
        initGoogleAnalytics(user)
      }
    })
  }
}

export function trackEvent(eventName: string, parameters = {}) {
  if (window.Intercom && eventName) {
    window.Intercom('trackEvent', eventName, parameters)
  }
}

export function initVueGtag(app: App, router: Router) {
  if (window.PI_GA_TRACKING_ID) {
    const ids = window.PI_GA_TRACKING_ID.split(',')
    app.use(
      VueGtag,
      {
        config: {
          id: ids[0],
          params: {
            custom_map: {
              dimension2: 'role'
            }
          }
        },
        pageTrackerUseFullPath: true,
        pageTrackerSkipSamePath: false,
        enabled: false,
        includes: ids.slice(1).map(id => ({ id }))
      } as any, // necessary because types don't match available options
      router
    )
  }
}
