<template>
  <view-container>
    <view-header>
      <view-title>My Classes</view-title>
      <view-header-actions>
        <button-dropdown
          button-class="archived-classes-dropdown"
          class="pull-right"
          secondary
          right
        >
          <template #button>
            <icon icon="gear" />
            <span class="sr-only">Classes Menu</span>
          </template>
          <dropdown-action @click="toggleShowArchived">
            {{ `${!showingArchived ? 'Show' : 'Hide'} Archived Classes` }}
          </dropdown-action>
        </button-dropdown>
      </view-header-actions>
    </view-header>
    <loading-container :loading="isLoading">
      <form-button
        link
        class="no-roster-button"
        v-if="instructorRosterIsLinked"
        @click.prevent="openModal"
      >
        <div class="no-roster">
          We cannot roster your classes. Click here to learn more.
        </div>
      </form-button>

      <div v-if="classes.length == 0" class="row">
        <div
          class="col-sm-12 text-center no-classes-msg"
          data-testid="noClassesPrompt"
        >
          <p>
            It looks like you don’t have any classes yet. Click below to create
            a class.
          </p>
          <button-dropdown
            v-if="rostered"
            button-class="archived-classes-dropdown"
            primary
          >
            <template #button> Add Classes </template>
            <dropdown-link :to="{ name: 'new_class' }">
              Add Manually
            </dropdown-link>
            <dropdown-action @click="addRosteredClass()">
              Add from Rostering service
            </dropdown-action>
          </button-dropdown>
          <button-link v-else :to="{ name: 'new_class' }"
            >Add Classes</button-link
          >
        </div>
      </div>
      <pagination-container
        :limit="20"
        :page="currentPage"
        :count="totalPages * 20"
        @loadpage="paginateCallback"
      >
        <div v-if="classes.length > 0" class="pi-classes row">
          <list :items="classes" class="class-list col-xs-12">
            <template v-if="classes.length" #firstRow>
              <div class="first-row-content">
                <button-dropdown
                  v-if="rostered"
                  button-class="archived-classes-dropdown"
                  link
                >
                  <template #button>
                    <icon icon="plus" /> Add Classes
                  </template>
                  <dropdown-link :to="{ name: 'new_class' }">
                    Add Manually
                  </dropdown-link>
                  <dropdown-action @click="addRosteredClass()">
                    Add from Rostering Service
                  </dropdown-action>
                </button-dropdown>
                <router-link
                  v-else
                  :to="{ name: 'new_class' }"
                  data-testid="firstRowAddBtn"
                >
                  <icon icon="plus" />
                  Add Class
                </router-link>
              </div>
            </template>
            <list-column v-slot="{ name, id }" title="Name">
              <router-link
                :to="{
                  name: 'existing_class',
                  params: {
                    id
                  }
                }"
              >
                {{ name }}
              </router-link>
            </list-column>
            <list-column
              v-slot="{ activeStudents, id }"
              title="Active Students"
            >
              <router-link
                :to="{
                  name: 'existing_class_roster',
                  params: {
                    id
                  }
                }"
                aria-label="View Active Students"
                >{{ activeStudents || 0 }}</router-link
              >
            </list-column>
            <list-column
              v-slot="{ droppedStudents, id }"
              title="Dropped Students"
            >
              <router-link
                :to="{
                  name: 'existing_class_roster',
                  params: {
                    id
                  },
                  hash: '#dropped-students'
                }"
                aria-label="View Dropped Students"
                >{{ droppedStudents || 0 }}</router-link
              >
            </list-column>
            <list-column v-slot="{ assignments, id }" title="Assignments">
              <router-link
                :to="{
                  name: 'existing_class',
                  params: {
                    id
                  }
                }"
                aria-label="View Assignments"
                >{{ assignments || '0' }}
              </router-link>
            </list-column>
            <list-column v-slot="{ role }" property="role" title="Role">
              <span class="user-role">{{ role }}</span>
            </list-column>
            <list-column v-slot="{ endDate, id }" title="End Date">
              <date-popover
                v-if="endDatePast(endDate)"
                :classId="id"
                :endDate="endDate"
              >
              </date-popover>

              <span v-else>{{ endDateDisplay(endDate) }} </span>
            </list-column>
            <list-column v-slot="klass" class="actions-column" title="Actions">
              <button-dropdown
                tertiary
                right
                class="pull-right"
                :up="nearEnd(klass)"
              >
                <template #button>
                  <icon icon="ellipsis-h" />
                  <span class="sr-only">Class Actions Menu</span>
                </template>
                <dropdown-link
                  v-if="!klass.isArchived && klass.role === 'instructor'"
                  :to="{
                    name: 'class_assign_activity',
                    params: { id: klass.id }
                  }"
                >
                  Assign an Activity
                </dropdown-link>
                <dropdown-link
                  :to="{
                    name: 'existing_class_roster',
                    params: { id: klass.id }
                  }"
                >
                  View Roster
                </dropdown-link>
                <dropdown-action
                  v-if="
                    !klass.isArchived &&
                    !klass.fromRoster &&
                    !klass.ltiConnected
                  "
                >
                  <click-to-copy
                    label="Copy link to join class"
                    :copy-text="`${baseURL + klass.classKey}`"
                    success-message="A link to join this class has been copied."
                    class="class-card__copy-link"
                  />
                </dropdown-action>
                <dropdown-action
                  v-if="klass.role === 'instructor'"
                  @click="toggleClassArchive(klass)"
                >
                  {{ klass.isArchived ? `Unarchive Class` : `Archive Class` }}
                </dropdown-action>
                <dropdown-action
                  @click="downloadGradesForClass(klass.id, klass.name)"
                >
                  Download Grades
                </dropdown-action>
              </button-dropdown>
            </list-column>
          </list>
        </div>
      </pagination-container>
    </loading-container>
  </view-container>
</template>

<script>
import { mapActions } from 'vuex'
import ClickToCopy from 'src/shared/components/ClickToCopy'
import { addProtocolAndHost } from 'src/shared/utils/environment-tools'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { format, isAfter, addDays } from 'date-fns'
import DatePopover from 'src/modules/classes/components/DatePopover'
import RosterClassesModal from './RosterClassesModal.vue'
import { mapState } from 'vuex'
import RosteringHelpModal from 'src/shared/components/modals/RosteringHelpModal.vue'
import client from 'src/shared/api-client'
import { trackEvent } from '../../../setup/analytics'

export default {
  name: 'ClassesView',
  components: { ClickToCopy, DatePopover },
  inject: ['$modal'],
  props: {
    isArchived: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      classes: [],
      totalPages: 0,
      isLoading: false,
      currentPage: this.page
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {}
    }),
    rostered() {
      return this.user.externalRoster
    },
    baseURL() {
      return addProtocolAndHost(`join-class?classKey=`)
    },
    showingArchived() {
      return typeof this.isArchived === 'string'
        ? this.isArchived.toLowerCase() === 'true'
        : this.isArchived
    },
    instructorRosterIsLinked() {
      if (this.user.siteLicense) {
        return (
          !this.user.externalRoster &&
          this.user.siteLicense.rosteredConnectionType !== undefined
        )
      } else {
        return false
      }
    }
  },
  async created() {
    await this.loadClasses()
  },

  methods: {
    ...mapActions(['refreshAuth']),

    async paginateCallback({ page }) {
      await this.$router.push({
        query: { page: page, isArchived: this.isArchived }
      })

      window.scroll(0, 0)
    },
    async archiveClass(klass) {
      const { name, id } = klass
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'You will no longer be able to assign activities to this class and students in this class will be unable to view their assignments. This will free up seats on your account, allowing you to invite new students to your classes.',
        prompt: `Are you sure you want to archive the class ${name}?`
      })
      if (status === 'ok') {
        await client.classes.archive({ classId: id })
        trackEvent('archive_class')
        this.$success(`Archived class ${name} successfully.`)
        await Promise.all([this.loadClasses(), this.refreshAuth()])
      }
    },
    async unarchiveClass(klass) {
      const { name, id } = klass
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'Unarchiving will add the students enrolled in this class to the number of seats used on your account.',
        prompt: `Are you sure you want to unarchive the class ${name}?`
      })
      if (status === 'ok') {
        await client.classes.unarchive({ classId: id })
        this.$success(`Unarchived class ${name} successfully.`)
        await Promise.all([this.loadClasses(), this.refreshAuth()])
      }
    },
    async addRosteredClass() {
      const { status } = await this.$modal.show(RosterClassesModal, {
        instructorId: this.user.id
      })
      if (status === 'ok') {
        this.$success(`rostered class successfully.`)
        await Promise.all([this.loadClasses(), this.refreshAuth()])
      }
    },
    async downloadGradesForClass(classId, className) {
      const csv = await client.classes.downloadGradesAsCSV({
        classId: classId
      })

      const blob = new Blob([csv], {
        type: 'text/csv'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${className} - ${format(new Date(), 'M-d-yyyy')}.csv`
      if (window.Cypress) {
        document.body.appendChild(link)
      } else {
        link.click()
      }
    },
    toggleShowArchived() {
      this.setisArchived(!this.isArchived)
    },
    toggleClassArchive(klass) {
      if (klass.isArchived) {
        this.unarchiveClass(klass)
      } else {
        this.archiveClass(klass)
      }
    },
    endDatePast(endDate) {
      return isAfter(new Date(), new Date(endDate))
    },
    endDateDisplay(endDate) {
      return endDate ? new Date(endDate).toLocaleDateString() : null
    },

    async loadClasses() {
      this.isLoading = true
      try {
        const body = await client.classes.search({
          isArchived: this.isArchived,
          page: this.page
        })

        this.totalPages = parseInt(body.total)
        this.classes = body.classes ?? []
      } finally {
        this.isLoading = false
      }
    },
    setisArchived(show) {
      if (this.isArchived !== show) {
        this.$router.push({
          query: { page: 1, isArchived: show }
        })
      }
    },
    nearEnd(classRow) {
      const index = this.classes.findIndex(a => a.id === classRow.id)
      return index >= this.classes.length - 2 && this.classes.length > 3
    },
    async openModal() {
      const { status } = await this.$modal.show(RosteringHelpModal, {
        name: this.user.siteLicense.name,
        connectionType: this.user.siteLicense.rosteredConnectionType,
        email: this.user.email
      })
    }
  },
  watch: {
    isArchived: {
      async handler(show) {
        await this.loadClasses()
      }
    },
    page: {
      async handler(pageNumber) {
        this.currentPage = pageNumber
        await this.loadClasses()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.no-roster {
  color: white;
  padding: 10px;
  background-color: $color-warning;
  margin-top: 5px;
  margin-bottom: 10px;
}

.no-roster-button {
  width: 100%;
  text-align: left;
  &:link {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  & :active {
    text-decoration: none;
  }
}
.classes-view {
  .no-classes-msg {
    margin: 4rem 0;
  }
}
.first-row-content {
  padding: 8px 0;
}
.class-list {
  .user-role {
    text-transform: capitalize;
  }
}
.pi-classes {
  margin-top: 20px;
}
.class-card__copy-link {
  color: #333333;
  font-weight: 400;

  &:focus,
  &:hover {
    color: #262626;
  }
}
.archived-classes-dropdown {
  margin-left: 16px;
}

.actions-column {
  text-align: right;
}
.classes-view__help-button {
  margin-left: 8px;
}
</style>
