<template>
  <div class="w-full h-48 p-2">
    <p v-if="errorType === LtiErrorTypeValue.AssignmentMissing">
      The Pivot Interactives assignment connected to your LMS has been archived
      or deleted. Your instructor will need to connect the LMS assignment to a
      new Pivot Interactives one.
    </p>
    <p v-else-if="errorType === LtiErrorTypeValue.ContextNotConnected">
      Your instructor needs to finish connecting their LMS classes to the
      appropriate Pivot Interactives classes. Please direct them to
      <HyperLink :to="{ name: 'schoology_connect_classes_step_3' }"
        >this help article.</HyperLink
      >
    </p>
    <p v-else-if="errorType === LtiErrorTypeValue.ConnectState">
      Something went wrong with opening your assignment. Please close all
      browser windows and launch the assignment again. Please make sure to click
      the assignment one time only and wait patiently for it to load.
      Alternatively, try using another browser like Chrome or Firefox.
    </p>
    <p v-else-if="errorType === LtiErrorTypeValue.ClassArchived">
      The Pivot Interactives class connected to
      <strong
        >{{ ltiMessage.context?.label }} -
        {{ ltiMessage.context?.title }}</strong
      >
      has been archived. Your instructor will need to unarchive the associated
      Pivot Interactives class. Please direct them to
      <HyperLink :to="{ name: 'archive_class_help' }"
        >this help article</HyperLink
      >
      for instructions on how to do this.
    </p>
    <p>
      If your instructor needs assistance with this, please ask them to contact
      us via chat during our normal business hours (8am-5pm Eastern) or via
      email at support@pivotinteractives.com
    </p>
  </div>
</template>
<script setup>
import { LtiErrorTypeValue, LtiMessageClaimValue } from '@pi/types'
import { jwtDecode } from '../lti-utils'
import { onBeforeMount, ref } from 'vue'
import client from 'src/shared/api-client'

let ltiSession
const ltiMessage = ref({})

const props = defineProps({
  errorType: { required: true }
})

const extractFromJwt = token => {
  const decoded = jwtDecode(token)
  ltiMessage.value = {
    school: decoded[LtiMessageClaimValue.ToolPlatform].name,
    platformName:
      decoded[LtiMessageClaimValue.ToolPlatform].product_family_code,
    context: decoded[LtiMessageClaimValue.Context],
    sub: decoded[LtiMessageClaimValue.Subject],
    email: decoded[LtiMessageClaimValue.Email] ?? '',
    resource: decoded[LtiMessageClaimValue.ResourceLink]
  }
}

onBeforeMount(async () => {
  ltiSession = await client.lti.getSession()
  const token = ltiSession?.context.token
  extractFromJwt(token)
})
</script>
