<template>
  <div
    class="version-flash"
    v-if="showChanges"
    :class="{
      'version-flash--stuck': isStuck,
      'version-flash--inline': inline
    }"
  >
    <div class="version-flash__content">
      <span>
        There are {{ totalChanges }} new changes to activities in this library.
        <a
          @click="
            () =>
              showChangesModal(library?.id ?? '', library?.changedActivities)
          "
          >View Activities</a
        >
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import ActivitiesChangedModal, {
  ChangedActivityView
} from 'src/modules/libraries/components/ActivitiesChangedModal.vue'

const isStuck = ref(false)
const observer = ref()
const modal = inject<any>('$modal')
const browseActivities = inject<any>('browseActivities')
const props = defineProps({
  inline: {
    type: Boolean,
    default: false
  },
  library: {
    type: Object,
    default: null
  }
})

const totalChanges = computed(
  () => props.library?.changedActivities?.length || 0
)
const showChanges = computed(() => totalChanges.value > 0)

const showChangesModal = async (
  libraryId: string,
  changedActivities?: ChangedActivityView[]
) => {
  const { status } = await modal.show(ActivitiesChangedModal, {
    libraryId,
    changedActivities,
    refreshLibrary: browseActivities.value.refreshLibrary
  })
}

onMounted(() => {
  if (!window.IntersectionObserver) return

  // This is a transparent element 100px tall at the top of the view container.
  // See the ViewContainer for where this sentinel is defined.
  const scrollSentinel = document.querySelector('#scroll-sentinel')

  observer.value = new IntersectionObserver(
    ([e]) => {
      isStuck.value = e.intersectionRatio < 0.6
    },
    {
      // The #scroll-container element should be the ancestry of this component,
      // but in storybook it may not be, so we use the body.
      root:
        document.querySelector('#scroll-container') ||
        document.querySelector('body'),
      threshold: [0, 0.6]
    }
  )
  if (scrollSentinel) {
    observer.value.observe(scrollSentinel)
  }
})

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
    observer.value = null
  }
})
</script>

<style lang="scss" scoped>
.version-flash {
  background: #fdf2e7;
  position: relative;
  left: 0;
  z-index: 999;
  padding: 12px 9999% 12px 9999%;
  margin: 0 -9999% 0 -9999%;

  &--inline {
    margin-top: 16px;
    margin-bottom: -16px;
  }
  &--stuck {
    position: sticky;
    top: 82px;
  }
  .version-flash__content {
    color: #383838;
    width: 100%;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      color: #c2620a;
      cursor: pointer;
    }
  }

  .last-updated {
    font-weight: 400;
    font-style: italic;
  }
}
</style>
