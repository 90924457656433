<template>
  <modal @submit="submit">
    <modal-header>Update Student Profile</modal-header>
    <modal-body>
      <template v-if="!removingSSOProvider">
        <form-group>
          <form-label for="firstName"> First Name </form-label>
          <div>
            <text-input
              id="firstName"
              v-model="firstName"
              label="first name"
              rules="required"
            />
          </div>
        </form-group>
        <form-group>
          <form-label for="lastName"> Last Name </form-label>
          <div>
            <text-input
              id="lastName"
              v-model="lastName"
              label="last name"
              rules="required"
            />
          </div>
        </form-group>
      </template>
      <template v-if="canUpdateProfile">
        <form-group>
          <form-label for="email">
            Email Address<span id="required-asterisk">*</span>
          </form-label>
          <div>
            <text-input
              id="email"
              v-model="email"
              label="email"
              rules="required|email"
            />
          </div>
        </form-group>
        <form-group>
          <form-label for="password"> New Password </form-label>
          <div>
            <text-input
              id="password"
              v-model="password"
              type="password"
              label="password"
              :rules="{
                password: { isStudent: true }
              }"
            />
          </div>
        </form-group>
      </template>
      <p v-else>
        <template v-if="!canRemoveSSO">
          This student's account is configured with
          {{
            student.ssoProviders.length > 1
              ? student.ssoProviders.slice(0, -1).join(', ') +
                ', and ' +
                student.ssoProviders.at(-1)
              : student.ssoProviders[0]
          }}
          Single Sign On. They will need to login using
          {{
            student.ssoProviders.length > 1
              ? 'one of these single sign on providers'
              : 'this single sign on provider'
          }}
          to change their email or password.
        </template>
        <template v-else>
          <div v-if="!removingSSOProvider">
            <label>SSO Providers:</label>
            <ul class="w-1/2">
              <li
                v-for="provider in ssoProviders"
                :key="provider.name"
                class="flex justify-between"
              >
                <span>{{ provider.name }}</span>
                <span v-if="provider.canRemove">
                  <button
                    @click="e => removeSSOProvider(e, provider.name)"
                    class="btn btn-link text-error-red-600"
                  >
                    Remove
                  </button>
                </span>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>
              Are you sure you want to remove {{ removingSSOProvider }} as a
              Single Sign On provider? <br />
              If completed, one of the following must be completed before the
              student can log in again: <br />
              You can set a new password for the student <strong>OR</strong>,
              the student can click Forgot Login Details on our login page to
              request a password reset email.
            </p>
            <div class="flex justify-between">
              <FormButton @click="confirmRemoveSSOProvider" destructive
                >Confirm</FormButton
              >
              <button @click="cancelRemoveSSOProvider" class="btn btn-link">
                Cancel
              </button>
            </div>
          </div>
        </template>
      </p>
    </modal-body>
    <modal-actions v-if="!removingSSOProvider">
      <modal-button-submit>Update</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import client from 'src/shared/api-client.ts'
export default {
  name: 'UpdateSutdentProfileModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  inject: ['$modal'],
  props: {
    student: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstName: this.student.firstName,
      lastName: this.student.lastName,
      email: this.student.email,
      password: '',
      canUpdateProfile: this.student.canUpdateProfile,
      removingSSOProvider: null
    }
  },
  computed: {
    canRemoveSSO() {
      return (
        this.student.ssoProviders?.filter(
          provider => provider !== 'Clever' && provider !== 'ClassLink'
        ).length > 0
      )
    },
    ssoProviders() {
      return this.student.ssoProviders?.map(provider => ({
        name: provider,
        canRemove: provider !== 'Clever' && provider !== 'ClassLink'
      }))
    }
  },
  methods: {
    removeSSOProvider(e, provider) {
      e.preventDefault()
      this.removingSSOProvider = provider
    },
    async confirmRemoveSSOProvider() {
      const data = {
        id: this.student._id,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        removeSSOProvider: this.removingSSOProvider
      }
      await client.users.updateUserProfile(data)
      this.$success('Single Sign On provider removed successfully.')
      this.removingSSOProvider = null
      this.canUpdateProfile = true
    },
    cancelRemoveSSOProvider() {
      this.removingSSOProvider = null
    },
    async submit(e) {
      try {
        const data = {
          id: this.student._id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }
        if (this.student.canUpdateProfile) {
          data.email = this.email
          if (this.password) {
            data.password = this.password
          }
        }
        await client.users.updateUserProfile(data)
        this.$success('Student Profile updated successfully.')
        e.done({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        })
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  }
}
</script>
