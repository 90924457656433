<template>
  <loading-container :loading="gettingQuote">
    <view-container normal>
      <flash />
      <help-banner />
      <div class="card">
        <h1 class="header">Order Summary</h1>
        <div class="license-summary">
          <order-banner :quote="quote" sales-tax :allow-add="false" />
        </div>
        <div class="sub-header flex space-between">
          <div>
            <h2 class="sub-header">Sales Tax</h2>
            <div v-if="billing.taxId" class="light-grey">
              Exempt ID# {{ billing.taxId }}
            </div>
          </div>
          <div class="tax-amount-container">
            <p>
              {{ $format.currency(quote.taxes, '--') }}
            </p>
          </div>
        </div>
        <div><hr /></div>
        <div class="sub-header flex space-between">
          <h2>Total</h2>
          <div>{{ $format.currency(quote.total, '--') }}</div>
        </div>
        <div class="grey-contact-box">
          <h2 class="sub-header">Billing Contact</h2>
          <div class="flex center">
            <div class="contact-col-1">Name</div>
            <div class="contact-col-2">
              {{ billing.billingName }}
            </div>
          </div>
          <div class="flex center">
            <div class="contact-col-1">Email</div>
            <div class="contact-col-2">{{ billing.billingEmail }}</div>
          </div>
          <div><hr /></div>
          <h2 class="sub-header">Billing Address</h2>
          <div v-if="quote.institution" class="flex center">
            <div class="contact-col-1">Institution</div>
            <div class="contact-col-2">{{ quote.institution }}</div>
          </div>
          <div class="flex center">
            <div class="contact-col-1">Address</div>
            <div class="contact-col-2">
              <div>{{ billing.billingAddress }}</div>
              <div>
                {{ billing.billingCity }}, {{ billing.billingState }}
                {{ billing.billingZip }}
              </div>
            </div>
          </div>
          <div><hr /></div>
          <h2 class="sub-header">Payment Method</h2>
          <!-- <div v-if="payment.paymentMethod === 'card'" class="flex">
            <div class="contact-col-1">Credit Card</div>
            <div class="contact-col-2">
              **** **** **** {{ payment.cardLast4 }}
            </div>
          </div>
          <div
            v-else-if="payment.paymentMethod === 'purchase-order'"
            class="flex"
          >
            <div class="contact-col-1">Purchase Order</div>
            <div class="contact-col-2">{{ payment.purchaseOrderNumber }}</div>
          </div> -->
          <div v-if="quote.paymentMethod === 'credit-card'" class="flex">
            <div class="contact-col-1">Credit Card</div>
          </div>
          <div
            v-else-if="quote.paymentMethod === 'purchase-order'"
            class="flex"
          >
            <div class="contact-col-1">Purchase Order</div>
          </div>
          <div v-else class="flex">
            <div class="contact-col-1">No payment method set</div>
          </div>
          <async-form class="form" @submit="onSubmit">
            <form-group class="button-group">
              <submit-button action="submit" class="form-button--beta">
                Complete Purchase
              </submit-button>
              <submit-button action="back" class="form-button--alpha">
                Back
              </submit-button>
            </form-group>
          </async-form>
        </div>
      </div>
    </view-container>
  </loading-container>
</template>

<script>
import { getPurchaseData } from 'src/shared/utils/purchases'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import OrderBanner from 'src/modules/purchases/components/OrderBanner'
import Flash from 'src/shared/components//Flash'
import { isBefore } from 'date-fns'
import client from 'src/shared/api-client.ts'

export default {
  name: 'OrderSummaryView',
  components: { OrderBanner, HelpBanner, Flash },
  data() {
    return {
      gettingQuote: true,
      quote: {}
    }
  },
  beforeRouteEnter(to, from, next) {
    const { id, purchaseType } = getPurchaseData()

    if (!id) {
      switch (purchaseType) {
        case 'license':
        default:
          return next({
            name: 'new_license_session',
            params: { licenseType: 'institution' }
          })
      }
    }

    next()
  },
  computed: {
    payment() {
      return this.quote.payment || {}
    },
    billing() {
      return this.quote.billing || {}
    }
  },
  methods: {
    async onSubmit({ action, done }) {
      if (action === 'back') {
        this.$router.push({ name: 'purchase_billing_information' })
        done()
        return
      }

      try {
        await client.purchases.completeQuotePurchase({ id: this.quote.id })
        this.$router.push({ name: 'purchase_complete' })

        done()
      } catch (error) {
        // this.$error(error)
        done(false)
        throw error
      }
    },
    formatInteger(number) {
      if (number) {
        return Number(number)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return number
      }
    }
  },
  async mounted() {
    this.gettingQuote = true

    const { id } = getPurchaseData()
    const { quote } = await client.purchases.getQuote({ id })
    this.quote = quote || {}

    if (isBefore(new Date(this.quote.quoteExpires), new Date())) {
      this.$error('Sorry, this quote is expired')
      this.$router.push({ name: 'config_license' })
      return
    }

    this.gettingQuote = false
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.license-summary {
  margin: 48px 0;
}
.payment-view {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 48px 64px;
  width: 928px;
}
h2 {
  margin: 0px 0px 8px 0px;
}
.pad-40 {
  margin: 0px 0px 40px 0px;
}
.light-grey {
  color: #716a6d;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 0px 0px 8px 0px;
}
.flex {
  display: flex;
}
.center {
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.space-between {
  justify-content: space-between;
}
.grey-contact-box {
  background: #f8f8f8;
  padding: 40px;
  margin: 0;
  margin-top: 64px;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
}
.contact-col-1 {
  margin: 0 40px 0 20px;
  width: 105px;
  font-size: 14px;
  height: 30px;
}
.contact-col-2 {
  font-size: 20px;
  line-height: 30px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.tax-amount-container {
  display: flex;
  flex-direction: column-reverse;
}
</style>
