<template>
  <loading-container :loading="gettingLicense">
    <view-container normal>
      <flash />
      <help-banner />
      <div class="card">
        <h1 class="header">
          {{ isTrialLicense ? 'Purchase ' : 'Renew ' }} License
        </h1>
        <br />
        <div v-if="isTrialLicense">
          <h2 class="">Type of License</h2>
          <div class="switch-field">
            <input
              id="switch-one-type"
              v-model="renewal.licenseType"
              class="switch-one"
              type="radio"
              name="license type"
              value="institution"
              checked
            />
            <label class="switch-one" for="switch-one-type">Institution</label>
            <input
              id="switch-two-type"
              v-model="renewal.licenseType"
              class="switch-two"
              type="radio"
              name="license type"
              value="student"
              @click="renewal.level = 'college'"
            />
            <label class="switch-two" for="switch-two-type">Student Pay</label>
          </div>
        </div>

        <template v-else>
          <h2 class="sub-header">Your Plan Type</h2>
          <div class="flex box flex-col">
            <div class="space-between">
              <h2
                v-if="renewal.level === 'high-school'"
                class="sub-header license-type"
              >
                Grades 6-12 Institution License
              </h2>
              <h2 v-else class="sub-header license-type">
                College/University Institution License
              </h2>
            </div>
            <div>Bulk seats are purchased by the institution.</div>
          </div>
        </template>

        <div v-if="isTrialLicense" class="level-selector">
          <h2 class="">Education Level</h2>
          <div v-if="renewal.licenseType === 'student'" class="switch-field">
            <input
              id="switch-two-level"
              v-model="renewal.level"
              class="switch-two"
              type="radio"
              name="education-level"
              value="college"
            />
            <label class="switch-two" for="switch-two-level"
              >College/University</label
            >
          </div>
          <div v-else class="switch-field">
            <input
              id="switch-one-level"
              v-model="renewal.level"
              class="switch-one"
              type="radio"
              name="education-level"
              value="high-school"
              @change="handleStartDateChange"
              checked
            />
            <label class="switch-one" for="switch-one-level">Grades 5-12</label>
            <input
              id="switch-two-level"
              v-model="renewal.level"
              class="switch-two"
              type="radio"
              name="education-level"
              value="college"
              @change="handleStartDateChange"
            />
            <label class="switch-two" for="switch-two-level"
              >College/University</label
            >
          </div>
        </div>

        <async-form class="form" @submit="onSubmit" @fail="onFail">
          <section v-if="isInstitutionLicense" class="instition-fields">
            <h2 class="">Plan Size</h2>
            <p class="plain-text">
              Don’t worry about being too exact. If you run out of seats on your
              license, you can add more later.
            </p>
            <h3>Projected Number of Seats</h3>
            <form-group class="input-40">
              <number-input
                id="addedSeats"
                v-model="renewal.seats"
                name="seats"
                :rules="{
                  required: true,
                  min_value: 10,
                  integer: true,
                  ...(isAdmin ? {} : { max_value: 500 })
                }"
                label="Projected Number of Seats"
                type="number"
                min="0"
                step="1"
                :disabled="!canEdit"
                oninput="validity.valid||(value='')"
                aria-label="Projected Number of Seats"
              />
            </form-group>
            <table>
              <tr>
                <td>Cost Per Seat</td>
                <td>
                  {{ $format.currency(costPerSeat, '--') }}
                </td>
              </tr>
              <tr>
                <td>Total Cost</td>
                <td>{{ $format.currency(totalCost, '--') }}</td>
              </tr>
            </table>
            <p class="advanced-features-text">
              For licenses over 500 seats and premium features such as LMS
              integration and rostering, standards alignment crosswalks, and
              customized professional development, please
              <hyper-link :to="{ name: 'de_sales_inquiry' }">
                connect with our sales team here</hyper-link
              >.
            </p>

            <div>
              <h2>Student Access Dates</h2>
              <div class="box">
                <h3 class="no-margin-top">Your existing license periods</h3>
                <ul class="license-periods-list">
                  <li class="license-period">
                    <icon class="activity-search__icon" icon="calendar" />
                    <p>{{ $format.date(license.startDate, 'MMMM d, yyyy') }}</p>
                    <p>-</p>
                    <p>{{ $format.date(license.endDate, 'MMMM d, yyyy') }}</p>
                  </li>
                  <li
                    v-for="existingRenewal in activeRenewals"
                    :key="existingRenewal.id"
                    class="license-period"
                  >
                    <icon class="activity-search__icon" icon="calendar" />
                    <p>
                      {{
                        $format.date(existingRenewal.startDate, 'MMMM d, yyyy')
                      }}
                    </p>
                    <p>-</p>
                    <p>
                      {{
                        $format.date(existingRenewal.endDate, 'MMMM d, yyyy')
                      }}
                    </p>
                  </li>
                </ul>
              </div>
              <h3>
                An Institution License lasts for
                {{
                  renewal.level === 'high-school'
                    ? 'one year.'
                    : '1  term or semester'
                }}
              </h3>
              <div class="plain-text">
                {{
                  renewal.level === 'college'
                    ? 'Enter the first and last dates of class in a term. Make sure to include additional time for grading if necessary.'
                    : ''
                }}
              </div>
              <p>Licenses start and end at midnight Greenwich Mean Time.</p>

              <div class="form__datetime-group">
                <form-group class="input-30">
                  <div class="form__datewrapper">
                    <form-label for="start-date">Start Date</form-label>
                    <date-input
                      id="start-date"
                      v-model="startDate"
                      rules="required"
                      :clearable="false"
                      label="Start Date"
                      :disabled="!canEdit"
                      :min-date="isAdmin ? null : minStartDate"
                      @update:modelValue="handleStartDateChange"
                    />
                  </div>
                </form-group>
                <div class="form__datewrapper">
                  <div v-if="startDate">
                    {{ $format.date(startDate, 'p') }}
                  </div>
                </div>
              </div>

              <div class="form__datetime-group">
                <form-group class="input-30">
                  <div class="form__datewrapper">
                    <form-label for="end-date">End Date</form-label>
                    <date-input
                      id="end-date"
                      v-model="endDate"
                      :rules="
                        isAdmin
                          ? null
                          : {
                              required: true,
                              after: license.startDate,
                              before: maxEndDate
                            }
                      "
                      :clearable="isAdmin ? false : true"
                      :disabled="!canSetEndDate"
                      label="End Date"
                      :min-date="license.startDate"
                      :max-date="isAdmin ? null : maxEndDate"
                    />
                  </div>
                </form-group>
                <div class="form__datewrapper">
                  <div v-if="endDate">
                    {{ $format.date(endDate, 'p') }}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section v-else class="student-pay-fields">
            {{ renewal.level === 'high-school' ? '$5.50' : '$10.00' }}
            Per Seat
          </section>
          <form-group v-if="license.salesforceId" class="button-group">
            <submit-button action="submit" class="form-button--beta">
              {{ continueButtonCopy }}
            </submit-button>
            <submit-button
              v-if="isAdmin"
              action="saveQuote"
              class="form-button--alpha"
            >
              Save Quote
            </submit-button>
            <submit-button
              v-if="renewal.licenseType === 'institution'"
              action="quote"
              class="form-button--alpha"
            >
              {{
                isUpdatingQuote ? 'Send me Updated Quote' : 'Send me a quote'
              }}
            </submit-button>
          </form-group>
          <form-group v-else>
            <h3>
              Your account requires an update prior to renewing. Please email
              Pivot Interactives support at
              <hyper-link :to="{ name: 'support' }">
                support@pivotnteractives.com
              </hyper-link>
              or
              <form-button link @click="OpenChat">open a chat.</form-button>
            </h3>
          </form-group>
          <form-group v-if="isAdmin && isInstitutionLicense" class="admin list">
            <form-label for="admin"
              >Select license admin to receive quote</form-label
            >
            <select-field
              id="admin"
              v-model="licenseAdmin"
              :rules="{ required: true }"
            >
              <option
                v-for="admin in adminList"
                :key="admin.email"
                :value="admin"
              >
                {{ admin.lastName }}, {{ admin.firstName }} {{ admin.email }}
              </option>
            </select-field>
          </form-group>
        </async-form>
      </div>
    </view-container>
  </loading-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import HelpBanner from 'src/modules/purchases/components/HelpBanner'
import { getPurchaseData, mergePurchaseData } from 'src/shared/utils/purchases'
import AlertModal from 'src/shared/components/modals/AlertModal'
import { add, sub, endOfDay } from 'date-fns'
import { linksList } from 'src/setup/router/externalLinkUtils'
import { isBefore, startOfToday } from 'date-fns'
import { getUTCMidnightOfDay } from 'src/shared/utils/dates'
import client from 'src/shared/api-client.ts'
import utils from '../pricing.ts'

export default {
  name: 'ConfigureRenewLicense',
  components: { HelpBanner },
  inject: ['$modal'],
  props: {
    id: {
      type: String,
      default: undefined
    },
    licenseId: {
      type: String,
      default: undefined
    }
  },
  data() {
    const renewal = {
      seats: 0,
      startDate: null,
      endDate: null,
      level: 'high-school',
      licenseType: 'institution',
      ...getPurchaseData().configureRenewalForm
    }
    renewal.startDate = renewal.startDate
      ? new Date(renewal.startDate)
      : renewal.startDate
    renewal.endDate = renewal.endDate
      ? new Date(renewal.endDate)
      : renewal.endDate

    return {
      gettingLicense: true,
      initLicenseType: '',
      license: {},
      renewal,
      adminList: [],
      licenseAdmin: undefined
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user || {}
    }),
    ...mapGetters(['isAdmin']),
    isTrialLicense() {
      return this.initLicenseType === 'trial'
    },
    isInstitutionLicense() {
      return this.renewal.licenseType === 'institution'
    },
    siteLicenseId() {
      return this.user.siteLicense && this.user.siteLicense.id
    },
    canSetEndDate() {
      return this.renewal.level === 'college'
    },
    canEdit() {
      return this.isAdmin || !this.quote?.customPricing
    },
    costPerSeat: function () {
      if (!this.isAdmin && this.renewal.seats > 500) {
        return null
      }
      return utils.determineLicensePriceCostPerSeat(this.renewal.level)
    },
    totalCost: function () {
      if (!this.isAdmin && this.renewal.seats > 500) {
        return null
      }
      return utils.determineLicensePrice(this.renewal.level, this.renewal.seats)
    },
    continueButtonCopy() {
      if (this.renewal.licenseType === 'institution') {
        return 'Continue to Purchase'
      }
      return 'Continue'
    },
    minStartDate() {
      const today = startOfToday()
      return sub(today, { minutes: today.getTimezoneOffset() })
    },
    maxEndDate() {
      if (this.renewal.level !== 'high-school' && !!this.renewal.startDate) {
        const maxDate = endOfDay(
          add(new Date(this.renewal.startDate), { days: 135 })
        )
        return sub(maxDate, { minutes: maxDate.getTimezoneOffset() })
      } else {
        return undefined
      }
    },
    overlappingValidation() {
      const ranges = this.license.renewals
        .filter(renewal => renewal.status !== 'cancelled')
        .map(renewal => [
          new Date(renewal.startDate),
          new Date(renewal.endDate)
        ])
      if (this.initLicenseType !== 'trial') {
        ranges.push([
          new Date(this.license.startDate),
          new Date(this.license.endDate)
        ])
      }
      return ranges
    },
    isUpdatingQuote() {
      return this.id
    },
    activeRenewals() {
      return this.license.renewals.filter(
        renewal => renewal.status !== 'cancelled'
      )
    },
    startDate: {
      get() {
        return this.renewal.startDate
      },
      set(date) {
        if (date) {
          date = getUTCMidnightOfDay(date)
          this.renewal.startDate = date
        } else {
          this.renewal.startDate = undefined
        }
      }
    },
    endDate: {
      get() {
        return this.renewal.endDate
      },
      set(date) {
        if (date) {
          date = endOfDay(date)
          date = sub(date, { minutes: date.getTimezoneOffset() })

          if (new Date().getTimezoneOffset() >= 0) {
            this.renewal.endDate = date
          } else {
            this.renewal.endDate = sub(date, { days: 1 })
          }
        } else {
          this.renewal.startDate = undefined
        }
      }
    }
  },
  methods: {
    async showMaxSeatsModal() {
      await this.$modal.show(AlertModal, {
        title: 'Need more than 500 seats?',
        html: `<a href='${linksList.sales_inquiry}'>Contact us</a>
          for premium features and discounted pricing specifically for schools & districts.`,
        body: ''
      })
    },
    openChat() {
      if (window.Intercom) {
        window.Intercom(
          'showNewMessage',
          `My ${
            this.license.name || ''
          } account requires an update before I can renew.`
        )
      }
    },
    async onSubmit({ action, done }) {
      try {
        mergePurchaseData({
          purchaseType: this.isTrialLicense ? 'upgrade' : 'renewal',
          configureRenewalForm: this.renewal
        })
        // if the user is upgrading from a trial to a student paid license, create the trial directly
        if (this.renewal.licenseType === 'student') {
          const { configureRenewalForm } = getPurchaseData()

          const { licenseType } = configureRenewalForm
          const level = 'college'

          await client.licenses.upgradeFromTrial({
            licenseId: this.license.id,
            level,
            licenseType,
            contactFirstName: this.licenseAdmin
              ? this.licenseAdmin.firstName
              : this.user.firstName,
            contactLastName: this.licenseAdmin
              ? this.licenseAdmin.lastName
              : this.user.lastName,
            contactEmail: this.licenseAdmin
              ? this.licenseAdmin.email
              : this.user.email,
            institutionName: this.license.institutionName
          })

          this.$router.push({ name: 'purchase_complete' })
          return
        }
        let currentQuoteId = this.id
        if (this.isUpdatingQuote) {
          if (
            this.isAdmin &&
            isBefore(new Date(this.quote.quoteExpires), new Date())
          ) {
            this.quote.quoteExpires = add(new Date(), { months: 1 })
          }
          await client.purchases.updateRenewalQuote({
            id: this.id,
            ...this.quote,
            ...this.renewal
          })
        } else {
          const { id } = await client.purchases.createRenewalQuote({
            licenseId: this.license.id,
            ...this.renewal,
            conversion: this.initLicenseType === 'trial',
            contactFirstName: this.licenseAdmin
              ? this.licenseAdmin.firstName
              : this.user.firstName,
            contactLastName: this.licenseAdmin
              ? this.licenseAdmin.lastName
              : this.user.lastName,
            contactEmail: this.licenseAdmin
              ? this.licenseAdmin.email
              : this.user.email,
            institution: this.license.institutionName
          })
          currentQuoteId = id
        }

        mergePurchaseData({ id: currentQuoteId })

        if (action === 'quote') {
          try {
            await client.purchases.sendQuoteEmail({ id: currentQuoteId })
          } catch (e) {
            this.$error('Could not send email')
          } finally {
            this.$router.push({
              name: 'purchase_quote',
              params: { id: currentQuoteId },
              query: { quoteSent: true }
            })
          }
        } else if (action === 'saveQuote') {
          this.$router.push({
            name: 'purchase_quote',
            params: { id: currentQuoteId }
          })
        } else {
          this.$router.push({ name: 'purchase_billing_information' })
        }

        done()
      } catch (error) {
        done(false)
        throw error
      }
    },
    async onFail({ values }) {
      if (values.seats >= 500) {
        await this.showMaxSeatsModal()
      } else {
        this.$error('Please correct validation errors before continuing.')
      }
    },
    handleStartDateChange(d) {
      if (!this.canSetEndDate) {
        setTimeout(() => {
          this.endDate = add(new Date(d), { years: 1 })
        }, 50)
      }
    }
  },

  async mounted() {
    try {
      if (this.isUpdatingQuote) {
        const { quote } = await client.purchases.getQuote({ id: this.id })
        this.quote = quote || {}
        this.renewal.pricePerSeat = quote.pricePerSeat
        this.renewal.seats = quote.seats
        this.renewal.startDate = new Date(quote.startDate)
        this.renewal.endDate = new Date(quote.endDate)
      }
      this.gettingLicense = true
      const license = await client.licenses.get({
        id: this.quote
          ? this.quote.licenseId
          : this.isAdmin
            ? this.licenseId
            : this.siteLicenseId
      })

      this.license = license
      this.initLicenseType = license.type
      this.renewal.level = this.license.level
      this.renewal.licenseType =
        license.type === 'trial' ? 'institution' : license.type

      if (!this.renewal.startDate) {
        if (new Date(this.license.endDate) >= this.minStartDate) {
          this.startDate = add(new Date(this.license.endDate), {
            days: 1
          })
        } else {
          this.startDate = this.minStartDate
        }
        if (this.renewal.level === 'high-school') {
          this.endDate = add(this.startDate, { years: 1 })
        } else {
          this.endDate = add(this.startDate, { days: 135 })
        }
      }

      this.adminList = license.members.filter(member => member.role === 'admin')

      this.gettingLicense = false
    } catch {
      this.$error('You cannot purchase a license renewal')
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px rgba(61, 44, 96, 0.2);
  padding: 60px 64px;
}
.container {
  width: 100%;
}
.license-type {
  margin-top: 0px;
}
.button-group {
  display: flex;
  flex-direction: row-reverse;
}
.box {
  border: 2px solid #eeeded;
  border-radius: 6px;
  padding: 24px;
  flex-direction: column;
  display: flex;
  width: 462px;
}
.ref {
  color: #3d2c60;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
#addedSeats {
  text-align: right;
}
#startDate,
#endDate {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='141' height='146'%3E%3Cpath d='M13.3,126.4V37.4c0-2.4,.9-4.5,2.6-6.3c1.7-1.8,3.8-2.6%0A6.2-2.6h8.8v-6.7c0-3.1,1.1-5.7,3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0%0A5.6,1.1,7.8,3.3c2.2,2.2,3.2,4.8,3.2,7.9v6.7h26.4v-6.7c0-3.1,1.1-5.7%0A3.2-7.9c2.2-2.2,4.7-3.3,7.8-3.3h4.4c3,0,5.6,1.1,7.8,3.3c2.2,2.2,3.2%0A4.8,3.2,7.9v6.7h8.8c2.4,0,4.4,.9,6.2,2.6c1.7,1.8,2.6,3.8,2.6,6.3v88.9c0%0A2.4-.9,4.5-2.6,6.3c-1.7,1.8-3.8,2.6-6.2,2.6H22.1c-2.4,0-4.4-.9-6.2-2.6C14.2,130.8%0A13.3,128.8,13.3,126.4z M22.1,126.4h96.8V55.2H22.1V126.4z M39.7,41.9c0,.6,.2,1.2%0A.6,1.6c.4,.4,.9,.6,1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6,0-1.2,.2-1.6%0A.6c-.4,.4-.6,1-.6,1.6V41.9z%0AM92.5,41.9c0,.6,.2,1.2,.6,1.6c.4,.4,.9,.6%0A1.6,.6h4.4c.6,0,1.2-.2,1.6-.6c.4-.4%0A.6-.9,.6-1.6v-20c0-.6-.2-1.2-.6-1.6c-.4-.4-.9-.6-1.6-.6h-4.4c-.6%0A0-1.2,.2-1.6,.6c-.4,.4-.6,1-.6,1.6V41.9z'/%3E%3C/svg%3E");
  background-position: 5% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 35px;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header {
  font-size: 30px;
  line-height: 45px;
}
.sub-header {
  font-size: 20px;
  line-height: 30px;
}
.form-input {
  position: static;
  background-color: grey;
}
.input-75 {
  width: 75%;
}
.input-50 {
  width: 50%;
}
.input-40 {
  width: 38%;
}
.input-30 {
  width: 33%;
}
.input-25 {
  width: 25%;
}
.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  background-color: #ffffff;
  color: #3c393a;
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid #d4d3d3;
  &:hover {
    cursor: pointer;
  }
}
.switch-one {
  border-radius: 3px 0px 0px 3px;
}
.switch-two {
  border-radius: 0px 3px 3px 0px;
}
.switch-field input:checked + label {
  background-color: #3d2c60;
  box-shadow: none;
  border: 1px solid #3d2c60;
  color: #ffffff;
}
table {
  border: 1px solid #eeeded;
  border-radius: 0px 0px 3px 3px;
  width: 292px;
  color: #575153;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}
tr {
  border: 1px solid #eeeded;
}
td {
  padding: 12px 16px;
}
.trial-license {
  h2 {
    margin-bottom: 0;
  }
}
.license-periods-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.license-period {
  display: flex;
  align-items: center;
  margin: 8px 0;
  p {
    margin-bottom: 0;
  }
  * {
    margin-right: 6px;
  }
}
.no-margin-top {
  margin-top: 0;
}
.plain-text {
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.008em;
  text-align: left;
}
.disabled-checkbox {
  color: gray;
  cursor: not-allowed;
}

.advanced-features-text {
  margin-top: 8px;
}
.form__datetime-group {
  display: flex;
  align-items: baseline;
}
.form__datewrapper {
  display: inline-block;
}
</style>
