<template>
  <modal class="replace-activity-modal">
    <modal-header>Updates are Available for this Activity</modal-header>
    <modal-body class="replace-activity-modal__body">
      <div>
        <strong
          >Pivot Interactives has made updates to this activity since it was
          copied to your library.</strong
        >
        <p class="mt-4">
          To take advantage of these new updates, select
          <span class="underline"
            >&ldquo;Archive & Replace Activity&rdquo;</span
          >
          to copy the new activity to your current library. Your old activity
          will be moved to your archive, and you can review the new activity
          before assigning.
          <br />
          <span v-if="!props.hideAssign">
            To assign the current activity without the updates, select
            <span class="underline">&ldquo;Assign Anyway&rdquo;</span>
          </span>
        </p>
        <p v-if="props.ignored" class="mt-4">
          You have chosen to not be alerted to new updates for this activity.
          Would you like to be alerted for future updates,
          <a href="#" @click="updateIgnore">Yes, Remind Me</a>.
        </p>
        <p v-else class="mt-4">
          <a href="#" @click="updateIgnore">Don't Remind Me Again</a>
        </p>
        <collapse-provider v-model:collapsed="collapsed">
          <collapse-toggle class="updated-changes__collapse-toggle">
            <collapse-icon
              class="updated-changes__collapse-icon"
              icon="caret"
            />
            <span class="updated-changes__heading">
              Show Update Changelog
            </span>
          </collapse-toggle>
          <a
            class="updated-changes__link"
            target="_blank"
            :href="`/activities/${props.parentActivityId}/preview`"
            >Preview Updated Activity in New Tab</a
          >
          <collapse-content class="updated-changes__content">
            <div
              v-for="changelogEntry in props.changelogEntries"
              :key="changelogEntry.id"
            >
              <span class="updated-changes__date"
                >{{
                  format(
                    new Date(changelogEntry.createdAt),
                    'MM/dd/yyyy HH:mm:ss'
                  )
                }}
                by Pivot Interactives</span
              ><br />
              <strong>{{ changelogEntry.title }}</strong
              ><br />
              <editor-content
                class="changelog-entry__content"
                :text="changelogEntry.text"
              />
            </div>
          </collapse-content>
        </collapse-provider>
      </div>
    </modal-body>
    <modal-actions class="updated-changes__modal-actions">
      <div>
        <form-button v-if="!props.hideAssign" secondary @click="assignAnyway">
          Assign Anyway
        </form-button>
        <modal-button-submit @click="archiveActivity"
          >Archive & Replace</modal-button-submit
        >
      </div>
      <form-button destructive class="flex-start" @click="closeModal">
        Cancel
      </form-button>
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import CollapseToggle from 'src/shared/components/global/CollapseToggle.vue'
import CollapseIcon from 'src/shared/components/global/CollapseIcon.vue'
import CollapseContent from 'src/shared/components/global/CollapseContent.vue'
import { useFlash } from 'src/shared/hooks/flash'
import { format } from 'date-fns'
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import client from 'src/shared/api-client'

interface ChangelogEntry {
  id: string
  createdBy: string
  createdAt: Date
  updatedAt: Date
  text?: object
  title: string
}

interface Props {
  activityId: string
  activityName: string
  changelogEntries: ChangelogEntry[]
  parentActivityId: string
  libraryId: string
  hideAssign: boolean
  ignored: boolean
}

interface SubmitProps {
  done: () => void
}
const props = defineProps<Props>()
const collapsed = ref(true)
const flash = useFlash()
const router = useRouter()
const $modal = inject<any>('$_modal')
const assignAnyway = () => {
  $modal.status('ok')
}
const closeModal = () => {
  $modal.close()
}

const updateIgnore = async () => {
  await client.activities.updateActivityIgnoredUpdates({
    activityId: props.activityId,
    remove: props.ignored
  })
  $modal.status('refresh')
}

const archiveActivity = async () => {
  const newActivity = await client.activities.copy({
    activityId: props.parentActivityId,
    destinationLibraryId: props.libraryId
  })
  await client.activities.archive({
    activityId: props.activityId,
    isArchived: true
  })
  closeModal()
  flash.success('Activity has been archived and replaced')
}
</script>
<style scoped>
.replace-activity-modal {
  width: 600px;
}

.updated-changes__heading {
  color: #3d2c60;
  font-size: 16px;
}
.updated-changes__collapse-toggle {
  width: 100%;
}

.updated-changes__link {
  float: right;
  margin-top: -23px;
  position: relative;
  z-index: 10;
}
.updated-changes__content {
  font-size: 16px;
}
.updated-changes__date {
  color: #808080;
}

.flex-start {
  align-self: flex-start;
}

.updated-changes__modal-actions {
  justify-content: space-between;
}
</style>
