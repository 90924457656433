<template>
  <li class="sticky-header-shared-libraries">
    <button-dropdown
      link
      class="sticky-header-shared-libraries__dropdown"
      menuClass="__scrollable"
    >
      <template #button="{ isOpen }">
        <icon fixedWidth :icon="isOpen ? 'caret-up' : 'caret-down'" />
        My Libraries
      </template>
      <template #default>
        <dropdown-action v-if="canCreateLibraries" @click="createLibrary">
          + Add New Library
        </dropdown-action>
        <slot name="other-libraries" />
        <template v-if="!isLoading">
          <library-dropdown-item
            v-for="library in libraries"
            :key="library.id"
            :library="library.id"
            :hide-menu="hideMenu"
          >
            {{ library.name }}
          </library-dropdown-item>
          <dropdown-action
            v-if="archivedLibraries.length > 0"
            @click.stop="toggleArchiveLibraries"
          >
            <icon
              :icon="showArchived ? 'caret-up' : 'caret-down'"
              style="width: 10px"
            />
            Archived Libraries
          </dropdown-action>
          <template v-if="showArchived">
            <library-dropdown-item
              v-for="library in archivedLibraries"
              :key="library.id"
              :library="library.id"
              :hide-menu="hideMenu"
            >
              {{ library.name }}
            </library-dropdown-item>
          </template>
        </template>
      </template>
    </button-dropdown>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import NewLibraryModal from './NewLibraryModal'
import LibraryDropdownItem from './LibraryDropdownItem.vue'

export default {
  name: 'StikyHeaderSharedLibraries',
  inject: ['browseActivities', '$modal'],
  components: {
    LibraryDropdownItem
  },
  props: {
    hideMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showArchived: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    canCreateLibraries() {
      return (
        (this.user.role === 'teacher' &&
          ['expiring', 'active'].includes(this.user.siteLicense.status)) ||
        ['admin', 'content_developer', 'sales_admin'].includes(this.user.role)
      )
    },
    libraries() {
      return this.browseActivities.libraries.filter(
        library => library.status !== 'archived'
      )
    },
    archivedLibraries() {
      return this.browseActivities.libraries.filter(
        library => library.status === 'archived'
      )
    },
    isLoading() {
      return this.browseActivities.isLoadingLibraries
    }
  },
  methods: {
    async createLibrary() {
      const { status, data } = await this.$modal.show(NewLibraryModal)
      if (status === 'ok') {
        await this.browseActivities.loadLibraries()
        this.browseActivities.updateQuery({ library: data.libraryId })
      }
    },
    toggleArchiveLibraries() {
      this.showArchived = !this.showArchived
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-header-shared-libraries {
  position: relative;
  display: inline-block;
  margin-right: 30px !important;
}
:deep(.sticky-header-shared-libraries__dropdown) {
  color: inherit;
  font-weight: normal;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: underline;
  }
}
</style>
