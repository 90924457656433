<template>
  <view-container>
    <flash />
    <loading-container :loading="step === 'redirect' || loadingInvitation">
      <profile-details-form
        v-if="step === 'sign-up'"
        :invitation-token="token"
        :initial-field-values="initialFieldValues"
      />
      <create-account-selection v-else-if="step === 'create-account'" />
    </loading-container>
  </view-container>
</template>

<script setup>
import Flash from 'src/shared/components//Flash.vue'
import client from 'src/shared/api-client.ts'
import CreateAccountSelection from 'src/modules/auth/components/CreateAccountSelection.vue'
import ProfileDetailsForm from 'src/modules/auth/components/ProfileDetailsForm.vue'
import { onBeforeMount, ref, watchEffect } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const flash = useFlash()
const router = useRouter()
const initialFieldValues = ref({})
const props = defineProps({
  token: {
    type: String,
    default: undefined
  },
  step: {
    type: String,
    required: true
  },
  session: {
    type: Object,
    default: undefined
  }
})

const loadingInvitation = ref(true)

watchEffect(
  async () => {
    if (props.step === 'redirect') {
      window.location.assign(
        router.resolve({
          path: '/',
          query: {
            flash: {
              success: 'Your account was created successfully.'
            }
          }
        }).href
      )
    } else if (store.getters.isAuthenticated) {
      router.replace('/')
    }
  },
  { immediate: true }
)

onBeforeMount(async () => {
  try {
    if (props.session) {
      initialFieldValues.value = {
        email: props.session.email,
        confirmEmail: props.session.email,
        firstName: props.session.firstName,
        lastName: props.session.lastName,
        primarySubjects: [],
        institutionRoles: []
      }
    } else if (props.token) {
      const body = await client.invitations.get(props.token)

      initialFieldValues.value = {
        email: body.email,
        confirmEmail: body.email,
        firstName: body.firstName,
        lastName: body.lastName,
        primarySubjects: body.primarySubject ?? [],
        institutionRoles: body.role ?? []
      }
    }
    loadingInvitation.value = false
  } catch (err) {
    const isExpiredToken = (err?.body?.errors ?? []).some(
      error => error.message === 'Token expired'
    )
    if (isExpiredToken) {
      flash.error(
        'The signup URL has expired. A new invitation has been sent to your email.'
      )
      router.push({ name: 'home' })
      return
    }
    flash.error(
      'The signup URL is invalid or expired. Please request a new trial invitation.'
    )
    router.push({ name: 'free_trial' })
  }
})
</script>
<style lang="scss" scoped></style>
