<template>
  <li>
    <button-dropdown
      unstyled
      :disabled="disabled"
      class="hidden lg:block"
      :button-class="disabled ? 'nav-dropdown disabled' : 'nav-dropdown'"
      menuClass="__scrollable"
    >
      <template #button>
        {{ title }}
        <icon class="nav-dropdown__icon" icon="caret-down" />
      </template>
      <ul class="nav-dropdown-menu">
        <slot />
      </ul>
    </button-dropdown>
    <div class="lg:hidden pt-[5px] pb-[10px]">
      <collapse-provider collapsed renderless v-slot="{ collapsed }">
        <collapse-toggle class="toggle !text-pivot-purple-800">
          {{ title }}
          <icon
            class="nav-dropdown__icon"
            :icon="collapsed ? 'caret-down' : 'caret-up'"
          />
        </collapse-toggle>
        <collapse-content>
          <ul class="nav-dropdown-menu">
            <slot />
          </ul>
        </collapse-content>
      </collapse-provider>
    </div>
  </li>
</template>

<script>
export default {
  name: 'NavDropdown',
  props: {
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.nav-dropdown) {
  font-size: ceil($font-size-base * 1.14);
  line-height: 33px;
  color: $plum;
  padding: 5px 15px 14px 15px;

  &:hover,
  &:focus {
    // Have to use important because of bootstrap.
    text-decoration: underline;
    color: inherit !important;
    background-color: inherit !important;
  }

  &.disabled {
    color: grey !important;
  }

  .nav-dropdown__icon {
    height: 0.7em;
    vertical-align: 0;
  }
}

.nav-dropdown-menu {
  padding-top: 0;
  list-style: none;
  font-size: 14px;
  margin: 2px 0 0;
  padding: 5px 0;
  z-index: 1000;

  :deep(.nav-link) {
    font-size: 16px;
    // padding: 3px 20px;
    font-weight: 400;
  }

  :deep(.button-link) {
    display: block;
    background-color: $teal;
    border: $teal solid 1px;
    color: #ffffff;
    border-radius: 6px;
    font-weight: 700;
    padding: 7px 14px;
    opacity: 1;
    margin: auto;
    max-width: 12rem;
    -webkit-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: white;
      background-color: $darker-teal;
      border: $darker-teal solid 1px;
    }
  }
}

.toggle {
  font-size: 16px !important;
  line-height: 33px !important;
  font-weight: 700 !important;
  padding-left: 15px !important;

  .nav-dropdown__icon {
    height: 0.7em;
    vertical-align: 0;
  }
}
</style>
