<template>
  <modal class="replace-activity-modal">
    <modal-header>Activites with Changes</modal-header>
    <modal-body class="replace-activity-modal__body">
      <div>
        The following activities have been changed or replaced:
        <ul role="list" class="divide-y divide-gray-100 pt-4">
          <li
            v-for="activity in changedActivities"
            :key="activity.id"
            class="w-full gap-x-6 py-5"
          >
            <div class="flex justify-between">
              <div>
                <strong>{{ activity.name }}</strong>
                <span
                  class="inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-medium text-gray-600"
                >
                  {{
                    activity.changedOrReplaced === 'changed'
                      ? 'Changed'
                      : 'Replaced'
                  }}
                </span>
              </div>

              <div class="flex">
                <a
                  class="pr-4"
                  target="_blank"
                  :href="`/activities/${activity.id}/preview`"
                  title="Preview updated activity in a new tab"
                  >Preview</a
                >
                <form-button
                  link
                  destructive
                  @click="() => updateIgnore(activity.id)"
                >
                  Ignore changes
                </form-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </modal-body>
    <modal-actions class="updated-version__modal-actions">
      <form-button destructive class="flex-start" @click="closeModal">
        Close
      </form-button>
    </modal-actions>
  </modal>
</template>

<script setup lang="ts">
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions
} from 'src/shared/components/modals/components'
import { ref, inject } from 'vue'
import client from 'src/shared/api-client'

export type ChangedActivityView = {
  id: string
  name: string
  changedOrReplaced: 'changed' | 'replaced'
}

interface Props {
  libraryId: string
  changedActivities: ChangedActivityView[]
  refreshLibrary: () => void
}

interface SubmitProps {
  done: () => void
}
const props = defineProps<Props>()

const $modal = inject<any>('$_modal')
const changedActivities = ref(props.changedActivities)

const closeModal = () => {
  $modal.close()
}

const updateIgnore = async (activityId: string) => {
  if (!activityId) return
  await client.activities.updateActivityIgnoredUpdates({
    activityId,
    remove: false
  })
  changedActivities.value = changedActivities.value.filter(
    activity => activity.id !== activityId
  )
  props.refreshLibrary()
  if (changedActivities.value.length === 0) {
    closeModal()
  }
  return
}
</script>
<style scoped>
.replace-activity-modal {
  width: 600px;
}

.updated-version__heading {
  color: #3d2c60;
  font-size: 16px;
}
.updated-version__collapse-toggle {
  width: 100%;
}

.updated-version__link {
  float: right;
  margin-top: -23px;
  position: relative;
  z-index: 10;
}
.updated-version__content {
  font-size: 16px;
}
.updated-version__date {
  color: #808080;
}

.flex-start {
  align-self: flex-start;
}

.updated-version__modal-actions {
  justify-content: space-between;
}
</style>
