<template>
  <div class="score">
    <label class="score-label" for="score">Score:</label>
    <span v-if="disabled">
      {{ model || 0 }}
    </span>
    <number-input
      v-else
      v-model.number="model"
      class="score-value"
      :disabled="disabled"
      aria-label="score"
      min="0"
      :max="max"
      step="any"
    />
    <span v-if="typeof max === 'number'" class="score-total">/{{ max }}</span>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number
    },
    modelValue: {
      type: Number
    }
  },
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss">
.score {
  margin: 8px 0;
}

.score-label {
  display: inline-block;
  margin: 0 8px 0 0;
}

.score-value {
  width: 72px;
  display: inline-block;
  margin: 0 8px 0 0;
}
</style>
