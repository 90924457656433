<template>
  <view-container narrow>
    <view-header>
      <view-title>Send Bookstore Codes</view-title>
    </view-header>

    <async-form
      ref="form"
      class="form-two-column"
      data-testid="create-purchase-form"
      @submit="submit"
    >
      <!-- Email Field -->
      <form-group>
        <form-label for="email">Email</form-label>
        <text-input
          id="email"
          v-model="email"
          rules="required|email"
          label="email"
          type="email"
        />
      </form-group>

      <!-- Seats Field -->
      <form-group>
        <form-label for="seats">Seats</form-label>
        <number-input
          id="seats"
          v-model="seats"
          rules="required|integer|min_value:1"
          label="seats"
        />
      </form-group>

      <!-- Class Type Field -->
      <form-group>
        <form-label id="class-type-label">Class Type</form-label>
        <div>
          <selector-input
            v-model="classType"
            aria-labelledby="class-type-label"
            rules="required"
            label="class type"
          >
            <selector-option value="high-school" title="High School" />
            <selector-option value="college" title="College" />
          </selector-input>
        </div>
      </form-group>
      <form-group>
        <form-label for="opty-id">Opportunity ID</form-label>
        <text-input
          id="opty-id"
          v-model="optyId"
          label="opty-id"
          rules="required"
        />
      </form-group>

      <form-group variant="right-aligned">
        <submit-button>
          <template #default>Send</template>
          <template #submitting>Sending</template>
          <template #submitted>Sent</template>
        </submit-button>
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
export default {
  name: 'SendBookstoreCodesView',
  data() {
    return {
      email: '',
      seats: null,
      classType: '',
      purchaseOrderNumber: '',
      optyId: ''
    }
  },
  computed: {
    isInstitutionSeatType() {
      return this.seatType === 'institution'
    },
    durationPlaceholder() {
      if (this.classType === 'high-school') {
        return '365'
      } else if (this.classType === 'college') {
        return '135'
      }

      return ''
    }
  },
  methods: {
    async submit(e) {
      const { seats, email, classType, purchaseOrderNumber, optyId } = this
      try {
        const purchase = {
          seats,
          email,
          optyId: optyId,
          level: classType,
          ...(purchaseOrderNumber && { purchaseOrderNumber })
        }
        await client.bookstore.create(purchase)

        this.$success(`You just sent ${seats} seats to ${email}.`)
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    }
  },
  watch: {
    classType() {
      this.duration = undefined
    }
  }
}
</script>

<style scoped>
.form-label {
  width: 175px;
}
</style>
