<template>
  <view-container>
    <view-header>
      <view-title v-if="klass.name"
        >{{ klass.name }}
        <span v-if="klass.isArchived">(Archived)</span></view-title
      >
      <view-title v-else>Class</view-title>
      <view-header-actions>
        <button
          v-if="klass.isArchived"
          type="button"
          class="pi-header-action pi-btn-small"
          @click="unarchiveClass"
        >
          Unarchive Class
        </button>
        <button
          v-else
          type="button"
          class="pi-header-action pi-btn-small"
          @click="archiveClass"
        >
          Archive Class
        </button>
      </view-header-actions>
    </view-header>

    <div class="form-two-column">
      <form-group>
        <form-label for="name">Name</form-label>
        <static-value id="name">
          {{ klass.name }}
          <p v-if="klass.fromRoster" class="form__rostering-label">
            This class is provisioned by a rostering service.
          </p>
        </static-value>
      </form-group>

      <form-group>
        <form-label for="createdAt">Created</form-label>
        <static-value id="createdAt">
          {{ $format.date(klass.createdAt, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="class-key"> Class Key </form-label>
        <static-value id="class-key">
          {{ klass.classKey }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="payment"> Payment </form-label>
        <static-value id="payment">
          {{ klass.paymentSource }}
        </static-value>
      </form-group>

      <form-group>
        <form-label for="level"> Level </form-label>
        <static-value id="level">
          {{ klass.level }}
        </static-value>
      </form-group>
      <form-group v-if="klass.endDate && !klass.fromRoster">
        <form-label for="endDate"> End Date </form-label>
        <static-value id="endDate">
          {{ $format.date(klass.endDate, 'MM/dd/yyyy') }}
        </static-value>
      </form-group>
      <form-group>
        <form-label for="student-count"> # Students </form-label>
        <static-value id="student-count">
          {{ (klass.roster || []).length }}
        </static-value>
      </form-group>
      <form-group>
        <form-label for="instructors"> Instructors </form-label>
        <p v-if="(klass.instructors || []).length === 0">
          No instructors have been added to this class yet.
        </p>
        <list v-else :items="klass.instructors" aria-label="Instructors">
          <list-column v-slot="{ firstName, lastName, _id }" title="Name">
            <router-link
              :to="{
                name: 'admin_manage_instructor',
                params: { id: _id }
              }"
              >{{ firstName }} {{ lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ email }" title="Email">
            <a :href="`mailto:${email}`">{{ email }}</a>
          </list-column>
          <list-column v-slot="{ role }" title="Role">
            {{ role }}
          </list-column>
          <list-column v-slot="{ _id }" title="Actions">
            <form-button
              class="impersonate-button"
              link
              @click="impersonate(_id, 'teacher')"
              >Impersonate</form-button
            >
          </list-column>
        </list>
      </form-group>
      <form-group>
        <form-label for="assignments"> Assignments </form-label>
        <list
          v-if="(assignments || []).length > 0"
          id="assignments"
          :items="assignments"
        >
          <list-column v-slot="{ activity }" title="Name">
            {{ activity.name }}
          </list-column>
          <list-column v-slot="{ startDate }" title="Start Date">
            {{ $format.date(startDate, 'MM/dd/yyyy') }}
          </list-column>
          <list-column v-slot="{ endDate }" title="Due Date">
            {{ $format.date(endDate, 'MM/dd/yyyy') }}
          </list-column>
          <list-column
            title="LTI"
            v-if="klass.connectedToLti"
            v-slot="{ connectedToLti }"
          >
            <icon
              :icon="ltiConnectedIcon(connectedToLti)"
              :class="`lti-connected-icon__${ltiConnectedIconStyle(connectedToLti)}`"
            />
          </list-column>
          <list-column v-slot="{ id }" title="Actions">
            <router-link
              :to="{
                name: 'admin_assignment',
                params: { id }
              }"
            >
              View
            </router-link>
          </list-column>
        </list>
        <static-value v-else id="assignments">
          This class has no assignments.
        </static-value>
      </form-group>

      <form-group>
        <form-label for="students"> Students </form-label>
        <list
          v-if="(klass.roster || []).length > 0"
          id="students"
          :items="klass.roster"
        >
          <list-column v-slot="{ student }" title="Name">
            <router-link
              :to="{
                name: 'admin_manage_student',
                params: { id: student._id }
              }"
            >
              {{ student.firstName }} {{ student.lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ student }" title="Email">
            {{ student.email }}
          </list-column>
          <list-column v-slot="{ source }" title="Source">
            {{ source }}
          </list-column>
          <list-column
            v-if="klass.paymentSource === 'student'"
            v-slot="{ expirationDate }"
            title="Expiration"
          >
            {{ $format.date(expirationDate, 'MM/dd/yyyy') }}
          </list-column>
          <list-column
            title="LTI"
            v-if="klass.connectedToLti"
            v-slot="{ connectedToLti }"
          >
            <icon
              :icon="ltiConnectedIcon(connectedToLti)"
              :class="`lti-connected-icon__${ltiConnectedIconStyle(connectedToLti)}`"
            />
          </list-column>
          <list-column v-slot="{ student }" title="Actions">
            <form-button
              class="impersonate-button"
              link
              @click="impersonate(student._id)"
              >Impersonate</form-button
            >
          </list-column>
        </list>
        <static-value v-else id="students">
          No students have joined this class yet.
        </static-value>
      </form-group>

      <form-group>
        <form-label for="dropped-students"> Dropped Students </form-label>
        <list
          v-if="(klass.droppedStudents || []).length > 0"
          id="dropped-students"
          :items="klass.droppedStudents"
        >
          <list-column v-slot="{ student }" title="Name">
            <router-link
              :to="{
                name: 'admin_manage_student',
                params: { id: student._id }
              }"
            >
              {{ student.firstName }} {{ student.lastName }}
            </router-link>
          </list-column>
          <list-column v-slot="{ student }" title="Email">
            {{ student.email }}
          </list-column>
        </list>
        <static-value v-else id="dropped-students">
          There are no dropped students in this class.
        </static-value>
      </form-group>
    </div>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
import { mapActions } from 'vuex'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'

export default {
  name: 'AdminClassView',
  props: ['id'],
  inject: ['$modal'],
  data() {
    return {
      klass: {},
      assignments: []
    }
  },
  computed: {
    instructors() {
      return (this.klass.instructors || []).filter(
        instructor => instructor.role === 'Instructor'
      )
    }
  },
  async created() {
    await this.getClass()
  },
  methods: {
    ...mapActions(['refreshAuth']),

    async getClass() {
      const [classRequest, assignments] = await Promise.all([
        client.classes.get({ classId: this.id }),
        client.classes.getAssignments({ classId: this.id })
      ])

      this.klass = classRequest
      this.assignments = assignments
    },
    async archiveClass() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'The instructor will no longer be able to assign activities to this class and students in this class will be unable to view their assignments. This will free up seats on the instructors account, allowing them to invite new students to their classes.',
        prompt: `Are you sure you want to archive the class ${this.klass.name}?`
      })
      if (status === 'ok') {
        await client.classes.archive({ classId: this.klass.id })
        this.$success(`Archived class ${this.klass.name} successfully.`)
        this.getClass()
      }
    },
    async unarchiveClass() {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: 'Unarchiving will add the students enrolled in this class to the number of seats used on the instructors account.',
        prompt: `Are you sure you want to unarchive the class ${this.klass.name}?`
      })
      if (status === 'ok') {
        await client.classes.unarchive({ classId: this.klass.id })
        this.$success(`Unarchived class ${this.klass.name} successfully.`)
        this.getClass()
      }
    },
    async impersonate(userId, role) {
      window.sessionStorage.setItem('previous_url', window.location.href)
      await client.users.startImpersonating(userId)
      await this.refreshAuth()
      if (role === 'teacher') {
        window.location.assign(`/classes/${this.klass.id}`) //forces an additional refresh
      }
    },
    ltiConnectedIcon(connectedToLti) {
      return connectedToLti ? 'check' : 'xmark'
    },
    ltiConnectedIconStyle(connectedToLti) {
      return connectedToLti ? 'check' : 'xmark'
    }
  },
  watch: {
    id() {
      this.getClass()
    }
  }
}
</script>

<style lang="scss" scoped>
.form-label {
  width: 140px;
}
.form__rostering-label {
  font-style: italic;
}

.form__action-button {
  margin-left: 5px;
}

.lti-connected-icon {
  &__check {
    color: $color-success;
  }
  &__xmark {
    color: $color-warning;
  }
}
</style>
