<template>
  <dashboard-panel class="onboarding-widget">
    <dashboard-panel-header
      >{{ currentSchoolYear }} Onboarding Status</dashboard-panel-header
    >
    <p class="onboarding-widget__intro-copy" v-if="isOnboardingComplete">
      Congratulations! You have completed onboarding for the
      {{ currentSchoolYear }} School Year!
    </p>
    <p class="onboarding-widget__intro-copy" v-else>
      {{ currentSchoolYear }} School Year!
    </p>
    <div class="onboarding-widget__row row">
      <div class="col-md-5 onboarding-widget__donut-col">
        <vc-donut
          :size="110"
          :sections="sections"
          :thickness="60"
          :foreground="COLORS.DEFAULT"
        />
      </div>
      <div class="col-md-7">
        <ul class="onboarding-widget__onboarding-key">
          <li v-for="(status, key) in onboardingStatus" :key="key">
            <span
              v-if="!status.complete"
              class="onboarding-widget__empty-checkmark"
              :style="getIconStyle(key, 'empty')"
            ></span>
            <icon
              v-else
              icon="circle-check"
              class="onboarding-widget__complete-checkmark"
              :style="getIconStyle(key, 'complete')"
            />
            <span class="onboarding-widget__key-title">{{ status.label }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="onboarding-widget__actions">
      <button-link
        v-if="!onboardingStatus.created.complete"
        :to="{ name: 'classes' }"
        >Create A Class</button-link
      >
      <button-link
        v-if="
          onboardingStatus.created.complete &&
          !onboardingStatus.enrolled.complete
        "
        :to="{ name: 'classes' }"
        >Enroll Students</button-link
      >
      <button-link
        v-if="
          !onboardingStatus.assigned.complete &&
          onboardingStatus.enrolled.complete
        "
        :to="{ name: 'activities' }"
        >Assign an Activity</button-link
      >
      <button-link v-if="isOnboardingComplete" :to="{ name: 'classes' }"
        >View Class List</button-link
      >
      <button-link
        secondary
        class="onboarding-widget__secondary-button"
        v-if="isOnboardingComplete"
        :to="{ name: 'activities' }"
        >View My Library</button-link
      >
    </div>
  </dashboard-panel>
</template>
<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { format } from 'date-fns'
import DashboardPanel from 'src/shared/components/DashboardPanel.vue'
import DashboardPanelHeader from 'src/shared/components/DashboardPanelHeader.vue'

const store = useStore()
const $router = useRouter()

const siteLicense = computed(() => store.state.auth.user.siteLicense)

const COLORS = {
  DEFAULT: '#D9D9D9',
  ACCEPTED: '#e30e62',
  CREATED: '#f1af51',
  ENROLLED: '#5a3f85',
  ASSIGNED: '#34BBBB'
}
const sections = computed(() => {
  return [
    {
      value: 25,
      color: onboardingStatus.value.accepted.complete
        ? COLORS.ACCEPTED
        : COLORS.DEFAULT
    },
    {
      value: 25,
      color: onboardingStatus.value.created.complete
        ? COLORS.CREATED
        : COLORS.DEFAULT
    },
    {
      value: 25,
      color: onboardingStatus.value.enrolled.complete
        ? COLORS.ENROLLED
        : COLORS.DEFAULT
    },
    {
      value: 25,
      color: onboardingStatus.value.assigned.complete
        ? COLORS.ASSIGNED
        : COLORS.DEFAULT
    }
  ]
})

const OnboardingStatusOrder = [
  'default',
  'invited',
  'accepted-invitation',
  'created-class',
  'enrolled-students',
  'assigned-activity',
  'reset'
]
const isOnboardingComplete = computed(() => {
  return !Object.keys(onboardingStatus.value).some(
    key => !onboardingStatus.value[key].complete
  )
})
const onboardingStatus = computed(() => {
  const index = OnboardingStatusOrder.indexOf(
    siteLicense?.value?.onboardingStatus
  )
  const isComplete = index < 1 || index > 4
  return {
    accepted: {
      label: 'Accepted Invitation',
      complete: isComplete || index > 1
    },
    created: {
      label: 'Created First Class',
      complete: isComplete || index > 2
    },
    enrolled: {
      label: 'Enrolled Students',
      complete: isComplete || index > 3
    },
    assigned: {
      label: 'Assigned First Activity',
      complete: isComplete || index > 4
    }
  }
})

const currentSchoolYear = computed(() => {
  if (!siteLicense.value) return ''
  if (siteLicense.value.startDate) {
    const start = format(new Date(siteLicense.value.startDate), 'yyyy')
    const end = format(new Date(siteLicense.value.endDate), 'yyyy')
    return start !== end ? `${start}-${end.substring(2)}` : start
  }
  if (siteLicense.value.endDate) {
    return format(new Date(siteLicense.value.endDate), 'yyyy')
  }
  return 'N/A'
})

const getIconStyle = (key, status) => {
  if (status === 'empty') {
    return {
      borderColor: COLORS[key.toUpperCase()]
    }
  }
  return {
    color: COLORS[key.toUpperCase()]
  }
}
</script>
<style lang="scss" scoped>
.onboarding-widget {
  padding: 0 15px;
  height: 100%;
  width: 100%;
}
.onboarding-widget__intro-copy {
  padding-bottom: 20px;
}
.onboarding-widget__row {
  padding: 0 20px 20px;

  .col-md-5 {
    padding-left: 0;
  }
  .col-md-7 {
    padding-right: 0;
    padding-left: 0;
  }
}
.onboarding-widget__onboarding-key {
  list-style-type: none;
  padding-left: 0;
  li {
    margin-bottom: 10px;
    line-height: 20px;
  }
}

.onboarding-widget__empty-checkmark {
  width: 17px;
  height: 17px;
  border: 2px solid;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  vertical-align: middle;
}

.onboarding-widget__complete-checkmark {
  font-size: 18px;
}

.onboarding-widget__key-title {
  font-weight: bold;
  padding-left: 10px;
}

.onboarding-widget__actions {
  justify-content: center;
  display: flex;
  .form-button.form-button--secondary {
    margin-right: 0;
  }
}

.onboarding-widget__secondary-button {
  margin-left: 15px;
}

:deep() {
  .cdc-container {
    justify-content: left;
  }
  .cdc-section-left {
    .cdc-filler {
      border-right: 2px solid #fff;
    }
  }

  .cdc-section-right {
    .cdc-filler {
      border-left: 2px solid #fff;
    }
  }
}
</style>
