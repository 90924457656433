<template>
  <modal @submit="submit">
    <modal-header>License Renewal</modal-header>
    <modal-body>
      <!-- License Date Range Field -->
      <form-group>
        <form-label id="type-label">Type</form-label>
        <div>
          <selector-input
            v-model="type"
            class="selector-input--horizontal"
            aria-labelledby="type-label"
            rules="required"
            label="type"
          >
            <selector-option value="institution" title="Institution" />
            <selector-option value="student" title="Student" />
            <selector-option value="trial" title="Trial" />
          </selector-input>
        </div>
      </form-group>
      <template v-if="type">
        <form-group>
          <form-label for="start-date"> Start Date </form-label>

          <date-input
            id="start-date"
            v-model="startDate"
            rules="required"
            label="start date"
            :clearable="false"
          />
        </form-group>
        <form-group v-if="requireEndDate">
          <form-label for="end-date"> End Date </form-label>
          <date-input
            id="end-date"
            v-model="endDate"
            :rules="{ required: true, after: dateRange.start }"
            label="end date"
            :min-date="dateRange.start"
            :clearable="false"
          />
        </form-group>
        <template v-if="type === 'institution'">
          <!-- Max Seat Count Field -->
          <form-group>
            <form-label for="maxSeats">Max Seat Count</form-label>
            <number-input
              id="maxSeats"
              v-model="maxSeats"
              rules="required|min_value:1|integer"
              label="Max Seats"
              step="1"
            />
          </form-group>
          <form-group>
            <form-label for="opty-id">Opportunity ID</form-label>
            <text-input id="opty-id" v-model="optyId" label="opty-id" />
          </form-group>
        </template>
      </template>
    </modal-body>
    <modal-actions>
      <modal-button-submit>Renew</modal-button-submit>
      <modal-button-cancel />
    </modal-actions>
  </modal>
</template>

<script>
import {
  Modal,
  ModalHeader,
  ModalActions,
  ModalBody,
  ModalButtonSubmit,
  ModalButtonCancel
} from 'src/shared/components/modals/components'
import { add, sub, endOfDay, startOfDay } from 'date-fns'
import { getUTCMidnightOfDay } from 'src/shared/utils/dates'

export default {
  name: 'LicenseRenewalModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalActions,
    ModalButtonSubmit,
    ModalButtonCancel
  },
  props: {
    currentLicense: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateRange: {
        start: null,
        end: null
      },
      maxSeats: 0,
      type: this.currentLicense ? this.currentLicense.type : '',
      optyId: ''
    }
  },
  created() {
    this.initializeDates()
  },
  computed: {
    requireEndDate() {
      return this.type !== 'student'
    },
    startDate: {
      get() {
        return this.dateRange.start
      },
      set(date) {
        if (date) {
          date = getUTCMidnightOfDay(date)
          this.dateRange.start = date
        } else {
          this.dateRange.start = undefined
        }
      }
    },
    endDate: {
      get() {
        return this.dateRange.end
      },
      set(date) {
        if (date) {
          date = endOfDay(date)
          date = sub(date, { minutes: date.getTimezoneOffset() })

          if (new Date().getTimezoneOffset() >= 0) {
            this.dateRange.end = date
          } else {
            this.dateRange.end = sub(date, { days: 1 })
          }
        } else {
          this.dateRange.end = undefined
        }
      }
    }
  },
  methods: {
    initializeDates() {
      const lastRenewal = this.currentLicense.renewals
        .filter(license => license.status === 'scheduled')
        .sort((a, b) => {
          return new Date(a.endDate) - new Date(b.endDate)
        })
        .slice(-1)[0]

      let start = lastRenewal
        ? new Date(lastRenewal.endDate)
        : this.currentLicense.endDate
          ? new Date(this.currentLicense.endDate)
          : new Date()

      this.startDate = add(start, { days: 1 })
      this.startDate.setUTCHours(0, 0, 0, 0)

      if (this.currentLicense.level === 'high-school') {
        this.endDate = add(start, { years: 1 })
      } else {
        this.endDate = add(start, { days: 135 })
      }

      this.maxSeats = lastRenewal?.maxSeats || this.currentLicense.maxSeats
      this.type = lastRenewal?.type || 'institution' || ''
    },
    async submit(e) {
      e.done({
        startDate: this.startDate,
        endDate: this.requireEndDate ? this.endDate : undefined,
        maxSeats: this.maxSeats,
        type: this.type,
        optyId: this.optyId
      })
    }
  }
}
</script>
<style scoped>
.disabled-checkbox {
  color: gray;
  cursor: not-allowed;
}
</style>
