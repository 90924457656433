<template>
  <div
    class="version-flash"
    v-if="showVersionHistory || showChanges"
    :class="{
      'version-flash--stuck': isStuck,
      'version-flash--inline': inline
    }"
  >
    <div class="version-flash__content">
      <span>
        {{
          showChanges
            ? 'There is a new version of this activity in the '
            : 'This activity has been replaced by '
        }}
        <a v-if="showChanges" @click="showChangesModal"
          >Pivot Interactives Library</a
        >
        <a v-if="showVersionHistory" @click="showVersionModal">{{
          newActivityName
        }}</a>
      </span>
      <span class="last-updated">
        Last Updated {{ format(new Date(lastUpdated), 'MMM d, yyyy h:mm aaa') }}
      </span>
    </div>
    <FormButton link @click="ignore">Don't remind me again</FormButton>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue'
import ActivityVersionModal from 'src/modules/activities/components/ActivityVersionModal.vue'
import ActivityChangesModal from 'src/modules/activities/components/ActivityChangesModal.vue'
import { format } from 'date-fns'
import client from 'src/shared/api-client'

const isStuck = ref(false)
const observer = ref()
const modal = inject<any>('$modal')
const emit = defineEmits(['update:ignoredUpdates'])
const props = defineProps({
  showVersionHistory: Boolean,
  showChanges: Boolean,
  inline: {
    type: Boolean,
    default: false
  },
  activity: {
    type: Object,
    required: true
  }
})

const showVersionModal = async () => {
  await modal.show(ActivityVersionModal, {
    activityId: props.activity.id,
    activityName: props.activity.name,
    updatedActivity: props.activity.revisedVersions[0],
    libraryId: props.activity.library.id,
    hideAssign: true
  })
}

const showChangesModal = async () => {
  await modal.show(ActivityChangesModal, {
    activityId: props.activity.id,
    activityName: props.activity.name,
    changelogEntries: props.activity.parentChanges,
    libraryId: props.activity.library.id,
    parentActivityId: props.activity.pivotParentId,
    hideAssign: true
  })
}

const ignore = async () => {
  await client.activities.updateActivityIgnoredUpdates({
    activityId: props.activity.id
  })
  emit('update:ignoredUpdates')
}

const lastUpdated = computed(() =>
  props.showVersionHistory
    ? props.activity.revisedVersions[0].createdAt
    : props.activity.parentChanges[0].createdAt
)
const newActivityName = computed(() =>
  props.showVersionHistory ? props.activity.revisedVersions[0].name : ''
)

onMounted(() => {
  if (!window.IntersectionObserver) return

  // This is a transparent element 100px tall at the top of the view container.
  // See the ViewContainer for where this sentinel is defined.
  const scrollSentinel = document.querySelector('#scroll-sentinel')

  observer.value = new IntersectionObserver(
    ([e]) => {
      isStuck.value = e.intersectionRatio < 0.6
    },
    {
      // The #scroll-container element should be the ancestry of this component,
      // but in storybook it may not be, so we use the body.
      root:
        document.querySelector('#scroll-container') ||
        document.querySelector('body'),
      threshold: [0, 0.6]
    }
  )
  if (scrollSentinel) {
    observer.value.observe(scrollSentinel)
  }
})

onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect()
    observer.value = null
  }
})
</script>

<style lang="scss" scoped>
.version-flash {
  background: #fdf2e7;
  position: relative;
  left: 0;
  z-index: 1002;
  padding: 12px 9999% 12px 9999%;
  margin: 0 -9999% 0 -9999%;

  &--inline {
    margin-top: 16px;
    margin-bottom: -16px;
  }
  &--stuck {
    position: sticky;
    top: 82px;
  }
  .version-flash__content {
    color: #383838;
    width: 100%;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      color: #c2620a;
      cursor: pointer;
    }
  }

  .last-updated {
    font-weight: 400;
    font-style: italic;
  }
}
</style>
