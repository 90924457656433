<template>
  <view-container>
    <view-header>
      <view-title>Bookstore Purchases</view-title>
    </view-header>

    <loading-container :loading="isLoading">
      <paginated-list
        v-bind="{ page, items, limit, count }"
        @loadpage="loadPage"
      >
        <list-column title="Email" property="email" />
        <list-column v-slot="{ createdAt }" title="Purchase Date">
          {{ $format.date(createdAt, 'MM/dd/yyyy') }}
        </list-column>
        <list-column v-slot="{ purchasedSeats, redeemedSeats }" title="Seats">
          {{ redeemedSeats }} / {{ purchasedSeats }}
        </list-column>
        <list-column title="Level" property="level" />
        <list-column title="Opportunity" v-slot="{ optyId }">
          <HyperLink
            v-if="optyId"
            :to="{ name: 'salesforce_opty', params: { id: optyId } }"
          >
            View
          </HyperLink>
        </list-column>
      </paginated-list>
    </loading-container>
  </view-container>
</template>

<script>
import client from 'src/shared/api-client'
export default {
  name: 'BookstorePurchasesView',
  data() {
    return {
      isLoading: true,
      page: 1,
      items: [],
      count: 0,
      limit: 10
    }
  },
  methods: {
    async loadPage({ page }) {
      this.isLoading = true
      const body = await client.bookstore.search({ page, limit: this.limit })
      this.page = page
      this.items = body.items
      this.count = body.totalCount
      this.isLoading = false
    }
  },
  async created() {
    await this.loadPage({ page: this.page, limit: this.limit })
  }
}
</script>
