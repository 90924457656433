<template>
  <view-container narrow class="profile-page">
    <view-header>
      <view-title>Verifying Email</view-title>
    </view-header>
    <loading-container :loading="isLoading">
      <section></section>
    </loading-container>
  </view-container>
</template>

<script setup lang="ts">
import client from 'src/shared/api-client'
import { onMounted, ref } from 'vue'
import { useFlash } from 'src/shared/hooks/flash'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const isLoading = ref(true)
const router = useRouter()
const flash = useFlash()
const store = useStore()

const props = defineProps<{ token: string }>()

onMounted(async () => {
  try {
    await client.auth.validateEmailToken({ token: props.token })
    await store.dispatch('refreshAuth')
    flash.success('Email verified successfully')
    router.push({ name: 'edit_profile' })
  } catch (error: any) {
    flash.error(error?.body.errors[0].message)
  } finally {
    isLoading.value = false
  }
})
</script>
