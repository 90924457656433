<template>
  <view-container>
    <view-header>
      <view-title>Create Class</view-title>
    </view-header>
    <div v-if="needsPayment" class="no_license_warning">
      <p class="first_sentence">
        You must be on a license in order to create a class.
      </p>

      <p>
        Please reach out to our partnership managers about how to convert any
        remaining unused legacy seats on your account to a license. You can
        reach us via email
        <a href="mailto:sales@pivotinteractives.com"
          >sales@pivotinteractives.com</a
        >{{ intercomEnabled ? ' or via our chat below:' : '.' }}
      </p>
      <form-button v-if="true" @click="startChat" class="chatButton"
        >Chat</form-button
      >
    </div>
    <async-form v-else class="form-horizontal" @submit="onSubmit">
      <form-group>
        <form-label for="name" class="col-sm-2">Name</form-label>
        <div class="col-sm-10 col-md-8 col-lg-6">
          <text-input id="name" v-model="name" rules="required" label="name" />
        </div>
      </form-group>
      <form-group>
        <form-label for="paymentSource" class="col-sm-2"
          >Payment Source</form-label
        >
        <div>
          <static-value aria-labelledby="payment-label" class="col-sm-2"
            >{{ payment.charAt(0).toUpperCase() + payment.slice(1) }}
            <help-popover v-if="payment === 'student'">
              {{ licenseTypeHelpText + studentPaymentHelpText }}
            </help-popover>
            <help-popover v-if="payment === 'institution'">
              {{ licenseTypeHelpText + institutionPaymentHelpText }}
            </help-popover>
          </static-value>
        </div>
      </form-group>
      <form-group>
        <form-label for="endDate" class="col-sm-2">End Date</form-label>
        <div class="col-sm-10 col-md-8 col-lg-6">
          <date-input
            id="endDate"
            v-model="endDate"
            :maxDate="maxEndDate"
            rules="required"
            label="end date"
          />
        </div>
      </form-group>
      <form-group>
        <form-label id="subject" class="col-sm-2">Subject</form-label>
        <div class="col-sm-10 col-md-8 col-lg-6">
          <autocomplete
            v-model="subject"
            :options="subjectOptions"
            override
            multiple
            placeholder="Select or enter one or more subjects."
          />
        </div>
      </form-group>
      <form-group>
        <form-label id="gradeLevel" class="col-sm-2">Grade Level</form-label>
        <div class="col-sm-10 col-md-8 col-lg-6">
          <autocomplete
            v-model="grade"
            :options="gradeOptions"
            override
            multiple
            placeholder="Select or enter one or more grade levels."
          />
        </div>
      </form-group>
      <form-group>
        <form-label id="instructionalLevel" class="col-sm-2"
          >Instructional Level</form-label
        >
        <div class="col-sm-10 col-md-8 col-lg-6">
          <autocomplete
            v-model="instructionalLevel"
            :options="instructionalLevelOptions"
            override
            multiple
            placeholder="Select or enter one or more instructional levels."
          />
        </div>
      </form-group>
      <form-group>
        <div class="col-sm-12 col-md-10 col-lg-8">
          <submit-button class="pull-right">
            <template #default>Create</template>
            <template #submitting>Creating Class</template>
          </submit-button>
        </div>
      </form-group>
    </async-form>
  </view-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Autocomplete from 'src/shared/components/Autocomplete'
import client from 'src/shared/api-client'
import { add, addYears } from 'date-fns'

export default {
  name: 'CreateClassView',
  components: { Autocomplete },

  data() {
    return {
      name: '',
      payment: 'institution',
      level: false,
      isLoading: false,
      endDate: null,
      subject: [],
      subjectOptions: [
        'Astronomy',
        'Biology',
        'Chemistry',
        'Earth and Space',
        'Environmental Science',
        'Physics'
      ],
      gradeOptions: ['6', '7', '8', '9', '10', '11', '12', 'college'],
      instructionalLevelOptions: [
        'Remedial',
        'On-Level',
        'Honors',
        'Pre-AP',
        'AP',
        'IB'
      ],
      grade: [],
      instructionalLevel: []
    }
  },
  computed: {
    ...mapGetters(['isTrialTeacher']),
    ...mapState({
      user: state => state.auth.user || {}
    }),
    needsPayment() {
      return !this.user.siteLicense
    },
    needsLevel() {
      return (
        !this.user.siteLicense ||
        !this.user.siteLicense.level ||
        (this.user.siteLicense.level !== 'high-school' &&
          this.user.siteLicense.level !== 'college')
      )
    },
    licenseTypeHelpText() {
      const text = this.isTrialTeacher
        ? ''
        : ` Because you're on
          ${this.payment === 'institution' ? 'an Institution' : 'a Student Pay'}

        license, `
      return text
    },
    institutionPaymentHelpText() {
      const text =
        'students use seats that you or your institution have paid for in advance.'

      if (this.isTrialTeacher) {
        return (
          'During your trial, this class will be free for you and your students. After your trial ends, ' +
          text.charAt(0).toLowerCase() +
          text.slice(1)
        )
      }

      return text
    },
    studentPaymentHelpText() {
      const text =
        'students will need to use a credit card to pay for the class or purchase a code from the bookstore.'

      if (this.isTrialTeacher) {
        return (
          'Even during your trial, ' +
          text.charAt(0).toLowerCase() +
          text.slice(1)
        )
      }

      return text
    },
    intercomEnabled() {
      return !!window.Intercom
    },
    maxEndDate() {
      return addYears(new Date(), 1)
    }
  },
  methods: {
    ...mapActions(['refreshAuth']),
    async onSubmit(e) {
      try {
        await client.classes.create({
          name: this.name,
          endDate: this.endDate,
          subject: this.subject,
          grade: this.grade,
          instructionalLevel: this.instructionalLevel
        })
        await this.refreshAuth()
        this.$success(`Class ${this.name} created successfully!`)
        await this.$router.push({ name: 'classes' })
        e.done()
      } catch (error) {
        e.done(false)
        throw error
      }
    },
    startChat() {
      window.Intercom(
        'showNewMessage',
        'Hi, I would like to upgrade my legacy account to the license model.'
      )
    }
  },
  mounted() {
    if (this.user.siteLicense) {
      this.level = this.user.siteLicense.level
      this.payment =
        this.user.siteLicense.type === 'trial'
          ? 'institution'
          : this.user.siteLicense.type
      this.endDate =
        this.level === 'high-school'
          ? add(new Date(), { months: 10 })
          : add(new Date(), { days: 135 })
    }
  }
}
</script>

<style lang="scss" scoped>
.no_license_warning {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.first_sentence {
  align-self: flex-start;
}

.chatButton {
  width: 75px;
}
</style>
