import { GetLtiSessionResponse, LtiConfigRequest } from '@pi/api-types'
import { LtiMessageTypeValue } from '@pi/types'
import { postRedirect } from 'src/setup/mixins/form-post'
import client from 'src/shared/api-client'

export interface UIStepItem {
  key: string
  name: string
  href?: string | object
}

export async function cancelLTIFlow(
  session: GetLtiSessionResponse | undefined
) {
  if (
    session &&
    session.context.messageType === LtiMessageTypeValue.DeepLinkingRequest
  ) {
    const response = await client.lti.cancelConnection()
    const { redirectUrl, openedInNewTab, token } = response

    if (openedInNewTab) {
      window.opener.postMessage(
        JSON.stringify({
          type: 'pivot:deep-link-response',
          redirectUrl,
          token
        }),
        `${window.location.protocol}//${window.PI_API_HOST}`
      )
      window.close()
    } else {
      postRedirect(redirectUrl, { JWT: token })
    }
  } else {
    window.close()
  }
}

export function getLtiSteps(
  ltiSession: GetLtiSessionResponse,
  additional?: {
    noClasses?: boolean
    noAssignment?: boolean
    noClassConnected?: boolean
  }
): UIStepItem[] {
  let savedSteps = sessionStorage.getItem('lti-steps')
  if (savedSteps) {
    savedSteps = JSON.parse(savedSteps)
  }

  const startOnSignin = ltiSession.state === 'CONNECT_ACCOUNT'
  const includeSignin =
    ltiSession.state === 'CONNECT_ACCOUNT' || savedSteps?.includes('signin')
  const includeAddClass =
    additional?.noClasses || savedSteps?.includes('addClass')
  const includeClassConnected =
    additional?.noClassConnected || savedSteps?.includes('connectClass')
  const includeAddAssignment =
    ltiSession.state === 'CONNECT_CLASS' ||
    additional?.noAssignment ||
    savedSteps?.includes('addAssignment')

  const inDeeplinkFlow =
    ltiSession.context.messageType === 'LtiDeepLinkingRequest'
  const isTeacher = ltiSession.context.role === 'teacher'

  const newSteps = [
    ...(includeSignin
      ? [
          {
            name: 'Sign In',
            key: 'signin'
          }
        ]
      : []),
    ...(isTeacher && (includeAddClass || startOnSignin)
      ? [
          {
            name: 'Add Pivot Class',
            key: 'addClass'
          }
        ]
      : []),
    ...(isTeacher && (includeClassConnected || startOnSignin)
      ? [
          {
            name: 'Connect Course to Pivot Class',
            key: 'connectClass'
          }
        ]
      : []),
    ...(isTeacher && inDeeplinkFlow && (includeAddAssignment || startOnSignin)
      ? [
          {
            name: 'Add Assignment to Pivot Class',
            key: 'addAssignment'
          }
        ]
      : []),
    ...(isTeacher && inDeeplinkFlow
      ? [
          {
            name: 'Connect Pivot Assignment',
            key: 'connectAssignment'
          }
        ]
      : []),
    ...(!inDeeplinkFlow
      ? [
          {
            name: 'Launch Assignment',
            key: 'launchAssignment'
          }
        ]
      : [])
  ]

  localStorage.setItem(
    'lti-steps',
    JSON.stringify(newSteps.map(step => step.key))
  )

  return newSteps
}

//yoinked from https://github.com/auth0/jwt-decode/blob/main/lib/index.ts
export interface JwtDecodeOptions {
  header?: boolean
}

export interface JwtHeader {
  typ?: string
  alg?: string
  kid?: string
}

export interface JwtPayload {
  iss?: string
  sub?: string
  aud?: string[] | string
  exp?: number
  nbf?: number
  iat?: number
  jti?: string
}

export class InvalidTokenError extends Error {}

InvalidTokenError.prototype.name = 'InvalidTokenError'

function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, (m, p) => {
      let code = (p as string).charCodeAt(0).toString(16).toUpperCase()
      if (code.length < 2) {
        code = '0' + code
      }
      return '%' + code
    })
  )
}

function base64UrlDecode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/')
  switch (output.length % 4) {
    case 0:
      break
    case 2:
      output += '=='
      break
    case 3:
      output += '='
      break
    default:
      throw new Error('base64 string is not of the correct length')
  }

  try {
    return b64DecodeUnicode(output)
  } catch (err) {
    return atob(output)
  }
}

export function jwtDecode<T = JwtHeader>(
  token: string,
  options: JwtDecodeOptions & { header: true }
): T
export function jwtDecode<T = JwtPayload>(
  token: string,
  options?: JwtDecodeOptions
): T
export function jwtDecode<T = JwtHeader | JwtPayload>(
  token: string,
  options?: JwtDecodeOptions
): T {
  if (typeof token !== 'string') {
    throw new InvalidTokenError('Invalid token specified: must be a string')
  }

  options ||= {}

  const pos = options.header === true ? 0 : 1
  const part = token.split('.')[pos]

  if (typeof part !== 'string') {
    throw new InvalidTokenError(
      `Invalid token specified: missing part #${pos + 1}`
    )
  }

  let decoded: string
  try {
    decoded = base64UrlDecode(part)
  } catch (e) {
    throw new InvalidTokenError(
      `Invalid token specified: invalid base64 for part #${pos + 1} (${(e as Error).message})`
    )
  }

  try {
    return JSON.parse(decoded) as T
  } catch (e) {
    throw new InvalidTokenError(
      `Invalid token specified: invalid json for part #${pos + 1} (${(e as Error).message})`
    )
  }
}
