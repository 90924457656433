<template>
  <div class="pivot-instance">
    <div v-show="limitFunctionality" class="pivot-instance__overlay">
      <span class="pivot-instance__overlay-text hidden-print">
        The player is disabled. Please
        <router-link
          :to="{ name: 'login', query: { redirect: this.$route.path } }"
        >
          Sign In
        </router-link>
        or
        <router-link
          :to="{
            name: 'new_license_session',
            params: { licenseType: 'trial' }
          }"
        >
          Request a Free Trial
        </router-link>
      </span>
    </div>
    <div class="instance-container">
      <hyper-link class="player-help" :to="{ name: 'player_help' }">
        <div aria-hidden="true">?</div>
        <span class="sr-only">Get Help with the Video Player</span>
      </hyper-link>
      <base-popover
        placement="top"
        :anchor-ref="popoverAnchorRef"
        :visible="popoverVisible"
        class="instance-random--popover"
      >
        This video instance is randomized. Each student will be presented with a
        different trial of this instance, resulting in different measurements.
      </base-popover>
      <div class="player-random" v-if="isRandomized" ref="popoverAnchorRef">
        <span
          class="border-2 border-solid border-white rounded-full bg-pivot-purple-600 text-white"
          @mouseover="openPopover"
          @mouseout="closePopover"
        >
          <icon class="icon" icon="shuffle" />
        </span>
      </div>
      <div ref="instance" class="instance hidden-print"></div>
    </div>
    <div class="visible-print-block">Video instance not printed.</div>
  </div>
</template>

<script>
import PivotPlayer from '@pi/player'
import debounce from 'lodash/debounce'
import * as Sentry from '@sentry/vue'

export default {
  name: 'PivotInstance',
  emits: ['calibration-change'],
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    limitFunctionality: {
      type: Boolean,
      default: false
    },
    isRandomized: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    load() {
      if (this.instance) {
        this.instance.destroy()
        this.instance = null
      }
      this.instance = new PivotPlayer({
        ...this.settings,
        el: this.$refs.instance,
        preview: this.limitFunctionality,
        onCalibrationChange: debounce(
          calibration => this.$emit('calibration-change', calibration),
          250
        ),
        onError: this.onError.bind(this)
      })
    },
    closePopover() {
      this.popoverVisible = false
    },
    openPopover() {
      this.popoverVisible = true
    },
    onError(error) {
      if (error.message?.includes('AUDIO_RENDERER_ERROR')) {
        const hasAudioTrack =
          this.videoElement?.mozHasAudio ||
          Boolean(this.videoElement?.webkitAudioDecodedByteCount) ||
          Boolean(this.videoElement?.audioTracks?.length)

        if (!hasAudioTrack) {
          console.log('Video player: No audio track found', {
            settings: this.settings
          })
          return
        }
      }

      if (error.message?.includes('Format error') && error.code === 4) {
        console.log('Video player: Format error', {
          settings: this.settings
        })
        return
      }

      if (error instanceof MediaError) {
        Sentry.captureException(error.message, {
          extra: {
            code: error.code,
            instance: this.settings
          },
          tags: {
            features: 'player'
          }
        })
      } else {
        Sentry.captureException(error, {
          extra: {
            instance: this.settings
          },
          tags: {
            features: 'player'
          }
        })
      }
    }
  },
  data() {
    return {
      popoverAnchorRef: null,
      popoverVisible: false
    }
  },
  mounted() {
    this.load()
    this.popoverAnchorRef = this.$refs.popoverAnchorRef
  },
  watch: {
    settings: {
      handler(oldSettings, newSettings) {
        // The calibration only changes from within the instance.
        // If we don't strip it out in our comparison,
        // then the video reloads whenever you change it
        const { calibration: _, ...oldWithoutCalibration } = oldSettings
        const { calibration: __, ...newWithoutCalibration } = newSettings
        if (
          JSON.stringify(oldWithoutCalibration) !==
          JSON.stringify(newWithoutCalibration)
        ) {
          this.load()
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@pi/player/index.css';

.player-help {
  position: absolute;
  text-align: center;
  z-index: 100;
  width: 44px;
  height: 44px;
  margin: 8px;
  padding: 8px;

  div {
    padding: 2px;
    background: black;
    border: 2px solid white;
    color: white;
    border-radius: 100%;
    font-size: 12px;
    height: 28px;
    width: 28px;
  }

  &:focus div {
    box-shadow: 0 0 0 4px rgba(66, 153, 225, 0.75);
  }
}
.player-random {
  position: absolute;
  text-align: center;
  z-index: 100;
  left: 40px;
  width: 44px;
  height: 44px;
  margin: 8px;
  padding: 8px;

  span {
    display: inline-block;
    width: 28px;
    height: 28px;
    font-size: 12px;
    line-height: 24px;
  }
}

.instance {
  margin: 16px 0 0 0;
}

.pivot-instance {
  position: relative;
  border-style: hidden;
  border-width: 1px;
  border-color: black;
}

.pivot-instance__overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.pivot-instance__overlay-text {
  text-align: center;
}
</style>
