<template>
  <div class="activity-bulk-actions">
    <select-field
      id="bulkActionSelect"
      aria-label="ActivityBulkActions"
      v-model="bulkActionSelect"
      class="bulkaction--select"
    >
      <option value="">Bulk Actions</option>
      <option
        value="assign"
        v-if="
          browseActivities?.library?.id !== 'pivot' &&
          !browseActivities?.isUnvetted
        "
      >
        Assign
      </option>
      <option
        value="archive"
        v-if="browseActivities?.canManageLibraryActivities"
      >
        Archive
      </option>
      <option value="copy">Copy to Library</option>
      <option value="move" v-if="browseActivities?.canManageLibraryActivities">
        Move To Library
      </option>
      <option value="share" v-if="browseActivities?.canManageLibrary">
        Share to Community Library
      </option>
    </select-field>
    <form-button secondary @click="doBulkActions()" :disabled="!canApplyActions"
      >Apply</form-button
    >
  </div>
</template>
<script setup lang="ts">
import { computed, inject, ref } from 'vue'
interface ActivityBulkActionProps {
  selectedActivities: string[]
}
interface BrowseActivities {
  canManageLibraryActivities: boolean
  canManageLibrary: boolean
  library: { id: string }
  isUnvetted: boolean
}
const props = defineProps<ActivityBulkActionProps>()
const browseActivities = inject<BrowseActivities>('browseActivities')
const bulkActionSelect = ref('')
const emit = defineEmits(['applyBulkAction'])
const canApplyActions = computed(() => {
  return props.selectedActivities.length > 0 && bulkActionSelect.value !== ''
})

const doBulkActions = () => {
  emit('applyBulkAction', bulkActionSelect.value)
  bulkActionSelect.value = ''
}
</script>

<style lang="scss" scoped>
.activity-bulk-actions {
  margin-left: auto;

  .bulkaction--select {
    display: inline-flex;
    margin-right: 10px;
  }
}
</style>
