import { Node, mergeAttributes } from '@tiptap/core'
import InlineDropTargetContext from './InlineDropTargetContext'
import { VueNodeViewRenderer, isTextSelection } from '@tiptap/vue-3'
import { Selection, TextSelection, NodeSelection } from 'prosemirror-state'

function maybeSetSelection(state, dispatch, selection) {
  if (selection.eq(state.selection)) return false
  if (dispatch) dispatch(state.tr.setSelection(selection).scrollIntoView())
  return true
}

function arrow(dir, props) {
  const { state, view } = props.editor
  if (!view) return false
  const sel = state.selection

  if (sel instanceof NodeSelection && dir == 1) {
    return maybeSetSelection(
      state,
      view.dispatch,
      Selection.near(sel.$head, dir)
    )
  }
  if (!sel.empty) return false

  return maybeSetSelection(
    state,
    view.dispatch,
    Selection.near(state.doc.resolve(sel.head + dir), dir)
  )
}

const DropTargetContext = Node.create({
  name: 'dropTargetContext',
  content: 'dropTarget*', //only allow  0 or more drop targets.
  group: 'block',

  addStorage() {
    return {}
  },
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: element =>
          element.getAttribute('src')
            ? element.getAttribute('src').replace(`//${PI_API_HOST}`, '')
            : '',
        renderHTML: ({ src }) => ({
          src:
            src && !src.startsWith('data:') && !src.startsWith('http')
              ? `//${PI_API_HOST}${src}`
              : src
        })
      },
      width: {},
      height: {},
      alt: ''
    }
  },
  addKeyboardShortcuts() {
    return {
      ArrowLeft(props) {
        arrow(-1, props)
      },
      ArrowRight(props) {
        arrow(1, props)
      },
      ArrowUp(props) {
        arrow(-1, props)
      },
      ArrowDown(props) {
        arrow(1, props)
      },
      Enter({ editor }) {
        if (editor.isActive('dropTargetContext')) {
          editor.commands.openDropTargetContextModal()
          return true
        }
      }
    }
  },

  addCommands() {
    return {
      openDropTargetContextModal: () => () => {
        this.storage.onEditDropTargetContext?.()
      },
      addDropTargetContext:
        () =>
        ({ commands }) => {
          commands.insertContent({
            type: 'dropTargetContext',
            attrs: {
              width: 32,
              height: 32
            }
          })
        }
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(InlineDropTargetContext)
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes)]
  },

  parseHTML() {
    return [{ tag: 'span' }]
  },

  renderText({ node }) {
    return `[droptargetContext]`
  }
})

export default DropTargetContext
