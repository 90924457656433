<template>
  <span>
    <div class="sections_header">
      <h3>Sections {{ sectionsCount }}</h3>
      <form-button link @click="toggleSections">
        {{ allOpen ? 'Hide' : 'Show' }} All Sections
      </form-button>
    </div>
    <div class="sections" role="tablist" aria-multiselectable="true">
      <draggable
        v-model="activity.sections"
        ghost-class="moving-section"
        :animation="200"
        :handle="`.${sectionDragHandle}`"
        :scroll-sensitivity="200"
        :force-fallback="true"
        @end="handleEnd"
        @start="isDragging = true"
        item-key="keyId"
      >
        <template #item="{ element: section, index }">
          <progress-element
            :element-id="index"
            :title="`Section ${index + 1}: ${section.name}`"
          >
            <drag-panel
              class="truncate-title section-panel"
              :class="isDragging ? 'sortable' : ''"
              :panel-object="section"
              :aria-label="section.name || 'Section'"
              :first="index == 0"
              :last="index == sections.length - 1"
              :component-length="section.components.length || 0"
              :labels-title="labelsTitle()"
              :highlight-error="failedSections[section.keyId]"
              @movePanel="arg => movePanel(index, arg)"
              @panelAction="arg => panelAction(index, arg)"
              @componentDrag="arg => componentDrag(index, arg)"
              tabindex="-1"
              data-queryid="activity-section"
              :id="`sections.s${section.keyId}`"
            >
              <template #title>
                {{ section.name || `Section ${index + 1}` }}
              </template>
              <template #collapsedHeader>
                <span>{{ componentCountText(section) }}</span>
              </template>
              <template #panel>
                <activity-dragged-section
                  :ref="el => setSectionRef(index, el)"
                  :section="section"
                  :section-id="'section-' + index"
                  :section-index="index"
                  :last="index === sections.length - 1"
                  :component-dragging="isComponentDragging"
                  :variable-context="variableContext"
                  :name="`sections.s${section.keyId}`"
                  @componentDragging="setComponentDragging"
                  @copyInClipboard="copyToClipboard"
                />
              </template>
            </drag-panel>
          </progress-element>
        </template>
      </draggable>
    </div>
    <div class="add-section">
      <form-button link @click="addSection()">
        <icon icon="plus" />
        Add Section
      </form-button>
      <div>
        <span>or</span>
        <form-button
          secondary
          class="clipboard-btn"
          aria-label="Paste Section from clipboard"
          @click="pasteSectionFromClipboard()"
        >
          Paste Section from Clipboard
        </form-button>
      </div>
    </div>
  </span>
</template>

<script>
import draggable from 'vuedraggable'
import ActivityDraggedSection from './ActivityDraggedSection.vue'
import DragPanel from './DraggablePanel.vue'
import humanizeComponentType from 'src/setup/mixins/humanizeComponentType'
import useArrayRef from 'src/shared/hooks/array-ref'
import { useFormErrors } from 'vee-validate'
import ConfirmModal from 'src/shared/components/modals/ConfirmModal'
import { generateObjectId } from '../../../shared/utils/objectId'

export default {
  name: 'ActivityPanelsSection',
  inject: ['inherited', '$modal'],
  components: {
    DragPanel,
    ActivityDraggedSection,
    draggable
  },
  mixins: [humanizeComponentType],
  props: {
    variableContext: {
      type: Object,
      required: true
    }
  },
  setup() {
    const [sectionRefs, setSectionRef] = useArrayRef()
    const formErrors = useFormErrors()

    return {
      sectionRefs,
      setSectionRef,
      formErrors
    }
  },
  created() {
    let hashParams = window.location.hash

    hashParams = hashParams.replace('#component-', '')
    if (hashParams) {
      this.activity.sections.find(section => {
        const component = section.components.find(component => {
          if (component._id === hashParams) return true
          if (component.componentType === 'SplitView') {
            if (component.leftContent.find(lc => lc._id === hashParams))
              return true
            if (component.rightContent.find(lc => lc._id === hashParams))
              return true
          }
          return false
        })
        if (component) {
          section.collapsed = false
          return true
        }
      })
    }
  },
  data() {
    return {
      newSection: {
        name: '',
        summary: '',
        components: [],
        collapsed: false
      },
      isDragging: false,
      sectionDragHandle: 'section-drag-handle',
      isComponentDragging: false,
      failedSections: {},
      sourceActivity: undefined
    }
  },
  computed: {
    activity() {
      return this.inherited.activity
    },
    sourceVariables() {
      return this.sourceActivity?.variables
    },
    sectionsCount() {
      return this.activity.sections.length > 0
        ? `(${this.activity.sections.length})`
        : ''
    },
    sections() {
      return this.activity.sections
    },
    allOpen() {
      return this.activity.sections.every(section => !section.collapsed)
    }
  },
  methods: {
    componentDrag(index, event) {
      if (this.isComponentDragging === true) {
        setTimeout(() => {
          this.openSectionPanel(index)
        }, 500)
      }
    },
    setComponentDragging(isComponentDragging) {
      this.isComponentDragging = isComponentDragging
    },
    componentCountText(section) {
      let count = 0
      section.components.forEach(component => {
        if (component.componentType === 'SplitView') {
          count += component.rightContent.length + component.leftContent.length
        } else {
          count += 1
        }
      })
      return count > 0 ? `Components: ${count}` : ''
    },

    labelsTitle() {
      return {
        up: 'Move Section Up',
        down: 'Move Section Down',
        duplicate: 'Duplicate Section',
        clipboard: 'Copy Section',
        delete: 'Delete Section',
        add: 'Add Section Below'
      }
    },

    movePanel(index, args) {
      if (args.action === 'up') this.moveSectionUp(index, args.panel)
      if (args.action === 'down') this.moveSectionDown(index, args.panel)
    },

    panelAction(index, args) {
      if (args.action === 'delete') this.deleteSection(index)
      if (args.action === 'add') this.addSection(index)

      if (args.action === 'toggle-collapse')
        args.panel.collapsed = !args.panel.collapsed

      if (args.action === 'duplicate') this.duplicateSection(index, args.panel)
      if (args.action === 'clipboard') this.copySectionToClipboard(args.panel)
    },

    moveSectionUp(index, section) {
      this.activity.sections.splice(index, 1)
      this.activity.sections.splice(index - 1, 0, section)

      const focusEl = document.activeElement
      this.$nextTick(() => {
        if (index - 1 === 0) {
          focusEl.nextElementSibling.focus()
        } else {
          focusEl.focus()
        }
      })
    },

    moveSectionDown(index, section) {
      this.activity.sections.splice(index, 1)
      this.activity.sections.splice(index + 1, 0, section)

      const focusEl = document.activeElement

      this.$nextTick(() => {
        if (index + 1 === this.activity.sections.length - 1) {
          focusEl.previousElementSibling.focus()
        } else {
          focusEl.focus()
        }
      })
    },

    async deleteSection(index) {
      const { status } = await this.$modal.show(ConfirmModal, {
        text: `Deleting this section will delete this section and all the components it contains. This action cannot be undone.`,
        prompt: `Are you sure you want to delete this section?`
      })

      if (status === 'ok') {
        this.activity.sections.splice(index, 1)
        this.$success('section deleted successfully.')
      }
    },

    addSection(index) {
      if (index === undefined) {
        index = this.activity.sections.length
      } else {
        index += 1
      }

      this.newSection = {
        name: '',
        summary: '',
        components: [],
        collapsed: false,
        keyId: Math.floor(Math.random() * 100000)
      }
      this.activity.sections.splice(index, 0, this.newSection)

      this.$nextTick(() => {
        this.sectionRefs[index].setFocus()
      })
    },
    copySectionToClipboard(section) {
      try {
        const components = section.components.map(component => {
          if (component.componentType === 'SplitView') {
            return {
              ...component,
              _id: generateObjectId(),
              collapsed: false,
              leftContent: component.leftContent.map(leftComponent => {
                return {
                  ...leftComponent,
                  _id: generateObjectId(),
                  keyId: Math.floor(Math.random() * 100000),
                  collapsed: false
                }
              }),
              rightContent: component.rightContent.map(rightComponent => {
                return {
                  ...rightComponent,
                  _id: generateObjectId(),
                  keyId: Math.floor(Math.random() * 100000),
                  collapsed: false
                }
              })
            }
          } else {
            return {
              ...component,
              _id: generateObjectId(),
              keyId: Math.floor(Math.random() * 100000),
              collapsed: false
            }
          }
        })
        const payload = {
          fromPivot: true,
          type: 'activity-section',
          data: {
            ...section,
            collapsed: false,
            components,
            keyId: Math.floor(Math.random() * 100000),
            activityId: this.activity.id,
            variables: this.activityVariables(),
            summary: this.parseVariables(section.summary, true)
          }
        }
        navigator.clipboard.writeText(JSON.stringify(payload))
        if (section !== undefined) {
          this.$success(`Section has been copied to clipboard`)
        }
      } catch (error) {
        this.$error('Something went wrong, please try again.')
      }
    },
    isValidJson(value) {
      try {
        JSON.parse(value)
      } catch (error) {
        return false
      }
      return true
    },
    async getClipboardData() {
      if (document.hasFocus()) {
        try {
          const payload = JSON.parse(await navigator.clipboard.readText())

          if (
            payload.fromPivot === true &&
            payload.type === 'activity-section'
          ) {
            const section = payload.data

            if (section.activityId === this.inherited.activity.id) {
              delete this.sourceActivity
            } else {
              this.sourceActivity = {
                variables: section.variables
              }
            }

            return section
          }
        } catch {
          delete this.sourceActivity
          return
        }
      }
    },

    parseComponent(component) {
      if (component.componentType === 'IFrame') {
        component.url = this.parseVariables(component.url)
      }
      if (component.componentType === 'NumericalQuestion') {
        // parse conditions
        component.conditions.map(condition => {
          condition.condition = this.parseVariables(condition?.condition)
          condition.response = this.parseVariables(condition.response, true)
          return condition
        })
      }

      if (component.componentType === 'MultipleChoiceQuestion') {
        // parse conditions
        component.choices.map(choice => {
          choice.text = this.parseVariables(choice.text, true)
          choice.response = this.parseVariables(choice?.response, true)
          return choice
        })
      }

      if (this.hasText(component)) {
        // parse text
        component.text = this.parseVariables(component.text, true)
      }
      if (this.isQuestion(component)) {
        // parse hint
        component.hint = this.parseVariables(component.hint, true)
      }
    },
    parseVariables(str, enCloseBrackets = false) {
      if (this.sourceVariables === undefined || str === undefined) {
        return str
      }
      this.sourceVariables.forEach(variable => {
        const indexOfVariable = str.indexOf(`${variable.id}`)
        if (indexOfVariable !== -1) {
          str = str.replace(
            new RegExp(`${variable.id}`, 'g'),
            `${variable.name}_UND`
          )
        }
      })
      return str
    },

    hasText(component) {
      return !['EmbeddedInstance', 'IFrame', 'PhetIOSim'].includes(
        component.componentType
      )
    },
    isQuestion(component) {
      return component.componentType.indexOf('Question') !== -1
    },
    isEmbeddedInstance(component) {
      return ['EmbeddedInstance'].includes(component.componentType)
    },

    duplicateSection(index, section) {
      const insertionIndex = index + 1
      const components = section.components.map(component => {
        if (component.componentType === 'SplitView') {
          return {
            ...component,
            _id: generateObjectId(),
            collapsed: false,
            leftContent: component.leftContent.map(leftComponent => {
              return {
                ...leftComponent,
                _id: generateObjectId(),
                keyId: Math.floor(Math.random() * 100000),
                collapsed: false
              }
            }),
            rightContent: component.rightContent.map(rightComponent => {
              return {
                ...rightComponent,
                _id: generateObjectId(),
                keyId: Math.floor(Math.random() * 100000),
                collapsed: false
              }
            })
          }
        } else {
          return {
            ...component,
            _id: generateObjectId(),
            keyId: Math.floor(Math.random() * 100000),
            collapsed: false
          }
        }
      })
      const sectionCopy = {
        ...section,
        collapsed: false,
        components,
        keyId: Math.floor(Math.random() * 100000)
      }

      this.activity.sections.splice(insertionIndex, 0, sectionCopy)

      this.$nextTick(() => {
        this.sectionRefs[insertionIndex].setFocus()
      })
    },

    openSectionPanel(index) {
      const section = this.activity.sections[index]
      if (section) {
        section.collapsed = false
      }
    },

    activityVariables() {
      return this.variableContext.variables.map(variable => {
        return {
          id: variable.id,
          name: variable.name
        }
      })
    },
    async pasteSectionFromClipboard() {
      const section = await this.getClipboardData()
      if (!section) {
        this.$error('Clipboard does not contain a valid section')
        return
      }

      for (let component of section.components) {
        this.parseComponent(component)
      }

      const sectionCopy = {
        ...section,
        collapsed: false,
        keyId: Math.floor(Math.random() * 100000)
      }
      this.sections.push(sectionCopy)

      this.$nextTick(() => {
        this.sectionRefs[this.sections.length - 1].setFocus()
      })
    },

    async copyToClipboard(component) {
      try {
        const payload = {
          fromPivot: true,
          type: 'activity-component',
          data: {
            component,
            activityId: this.activity.id,
            variables: this.activityVariables()
          }
        }
        await navigator.clipboard.writeText(JSON.stringify(payload))
        this.$success(`Component has been copied to clipboard`)
      } catch (error) {
        this.$error('Something went wrong, please try again.')
      }
    },
    handleEnd(event) {
      event.item.firstElementChild.focus()
      this.isDragging = false
    },
    toggleSections() {
      const newCollapsed = this.allOpen
      this.activity.sections.forEach(
        section => (section.collapsed = newCollapsed)
      )
    }
  },
  watch: {
    formErrors: {
      handler() {
        this.sections.forEach(section => {
          const hasError = Object.keys(this.formErrors).some(key =>
            key.startsWith(`sections.s${section.keyId}`)
          )
          if (this.failedSections[section.keyId] !== hasError) {
            this.failedSections[section.keyId] = hasError
          }
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sections_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}
.section-panel {
  border: none;
  border-top: 2px solid $light-teal;
  padding-top: 8px;

  &:focus-within {
    background-color: #ffd9191a;
  }
}

.add-section {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid $light-teal;
  padding-top: 10px;
}

.collapsed-header {
  & span {
    font-size: 15px;
    font-weight: 500;
    color: black;
    margin-left: 30px;
  }
}

.truncate-title :deep() {
  .panel-text {
    width: 100%;
  }

  .panel-title {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.moving-section {
  border: 2px solid $teal !important;
  box-shadow: 10px 10px 5px -1px rgba($color: #000000, $alpha: 0.14);
  border-radius: 4px;
  padding: 10px;
  cursor: move;
  margin-bottom: 22px;

  & .drag-handle {
    color: #ccc !important;
  }

  & .sortable {
    border: none;
    padding-bottom: 0;
    background-color: #ffd9191a;
  }
}

.sections .sortable-drag {
  opacity: 0;
  cursor: move;
}

.sortable {
  border: 2px dashed $teal;
  cursor: move;
  padding-bottom: 20px;
}
.clipboard-btn {
  margin-left: 10px;
}
</style>
