<template>
  <section
    class="gradable-component-response"
    :aria-labelledby="`component-header-${component._id}`"
  >
    <h3 :id="`component-header-${component._id}`">
      Question {{ component.questionNumber }}
    </h3>
    <div>
      <grid-graph-question-response
        v-if="isGridGraphQuestion"
        :component="component"
        :response="response"
        :gradable-response="assignmentResponse"
        :show-correct-answer="showCorrectAnswer"
        :variable-context="variableContext"
      >
      </grid-graph-question-response>

      <open-ended-question-response
        v-if="isOpenEndedQuestion"
        :component="component"
        :response="response"
        :gradable-response="assignmentResponse"
        :show-correct-answer="showCorrectAnswer"
        :variable-context="variableContext"
      >
      </open-ended-question-response>

      <choice-question-response
        v-if="isChoiceQuestion"
        :component="component"
        :response="response"
        :gradable-response="assignmentResponse"
        :show-correct-answer="showCorrectAnswer"
        :variable-context="variableContext"
      >
      </choice-question-response>

      <div v-if="isNumericalQuestion" class="response">
        <div class="student-response">
          <h4 v-if="showCorrectAnswer" class="answer-heading">
            Student Response
          </h4>
          <component-response
            :component="component"
            :response="response"
            :name="componentResponseName"
            :variable-context="variableContext"
          />
        </div>
        <numerical-answer-key
          class="answer-key"
          v-if="showCorrectAnswer"
          :component="component"
          :response="response"
          :variable-context="variableContext"
          :grading="true"
        />
      </div>
      <div class="gradable-component-response__comments-wrapper">
        <div class="score-comments">
          <div v-if="isScorable" class="score-area">
            <label for="scoreFieldName">Score:</label>
            <div class="score-section">
              <number-input
                v-model="score"
                aria-label="score"
                :rules="`min_value:0|max_value:${component.pointValue}`"
                class="score-field"
                step="any"
              >
              </number-input>
              <span class="score-total"> / {{ component.pointValue }}</span>
            </div>
            <div v-if="component.autograde">
              <icon v-if="response.correct" icon="check" style="color: green" />
              <icon
                v-else-if="response.correct === false"
                icon="times"
                style="color: red"
              />
              <span class="submissions">
                {{ response.submissions || 0 }} /
                {{ component.autogradeLimit || '&infin;' }} submissions
              </span>
            </div>
          </div>
          <form-button
            v-if="!showFeedback"
            link
            class="comments-add__btn"
            @click="addFeedback"
          >
            <icon icon="plus" />
            Add Feedback
          </form-button>
          <div class="comment-box" v-if="showFeedback">
            <div class="comments-label__container">
              <label for="commentsFieldName" class="comments-label"
                >Feedback:</label
              >
              <form-button
                destructive
                link
                class="comments-remove__btn"
                @click="removeFeedback"
                ><icon icon="trash"
              /></form-button>
            </div>
            <editor v-model:text="comments" class="comment-box-input" />
          </div>
        </div>
        <p
          class="gradable-component-response__exhausted-submissions"
          v-if="
            component.autogradeLimit &&
            (response.submissions ?? 0) > component.autogradeLimit
          "
        >
          This student exhausted their submissions for this question, and can no
          longer receive credit from the autograder even if they get the answer
          correct.
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
import GridGraphQuestionResponse from './GridGraphQuestionResponse.vue'
import OpenEndedQuestionResponse from './OpenEndedQuestionResponse.vue'
import ChoiceQuestionResponse from './ChoiceQuestionResponse.vue'
import ComponentResponse from './ComponentResponse'
import NumericalAnswerKey from '../../../shared/components/NumericalAnswerKey'
import { computed, ref } from 'vue'

const emit = defineEmits(['grading-changed'])
const props = defineProps({
  component: {},
  assignmentResponse: {},
  showCorrectAnswer: {},
  variableContext: {
    value: Object,
    required: true
  }
})

// "response" is the student's response to a single component
const response = computed(() => {
  return (props.assignmentResponse.responses || []).find(
    r => r.component === props.component._id
  )
})

const score = computed({
  get() {
    return response.value.score
  },
  set(score) {
    const parsedNewScore = parseFloat(score)
    const validatedNewScore = isNaN(parsedNewScore) ? undefined : parsedNewScore

    emit('grading-changed', {
      newScore: validatedNewScore,
      newComments: response.value.comments
    })
  }
})
const comments = computed({
  get() {
    return response.value.comments
  },
  set(comments) {
    emit('grading-changed', {
      newComments: comments,
      newScore: response.value.score
    })
  }
})

const showFeedback = ref(!!comments.value)

const componentResponseName = computed(() => {
  return `question-choice-${props.component._id}-${props.assignmentResponse._id}`
})
const scoreFieldName = computed(() => {
  return `score-${props.component._id}-${props.assignmentResponse._id}`
})
const commentsFieldName = computed(() => {
  return `comments-${props.component._id}-${props.assignmentResponse._id}`
})
const isScorable = computed(
  () => props.component.pointValue && props.component.pointValue > 0
)
const isGridGraphQuestion = computed(() => {
  return (
    props.component.componentType === 'GridGraphQuestion' ||
    props.component.componentType === 'GridQuestion'
  )
})
const isOpenEndedQuestion = computed(
  () => props.component.componentType === 'OpenEndedQuestion'
)
const isChoiceQuestion = computed(
  () => props.component.componentType === 'MultipleChoiceQuestion'
)

const isNumericalQuestion = computed(
  () => props.component.componentType === 'NumericalQuestion'
)

const removeFeedback = () => {
  comments.value = ''
  showFeedback.value = false
}

const addFeedback = () => {
  showFeedback.value = true
}
</script>
<style lang="scss" scoped>
.score-field {
  display: inline-block;
  margin: 0 4px 0 0;
  width: 70px;
}

.width-auto {
  width: auto !important;
}
.gradable-component-response__comments-wrapper {
  background-color: rgba(52, 189, 189, 0.1);
}
.score-comments {
  display: flex;
  align-items: baseline;
  padding: 10px;
  margin: 8px 0;

  & label {
    font-size: 16px;
    margin-bottom: 0;
  }

  & .score-area {
    flex-shrink: 0;
    max-width: 200px;
    margin-right: 45px;

    & label {
      display: inline-block;
      padding-right: 9px;
    }
    & .score-section {
      margin-bottom: 10px;
      display: inline-block;
    }
  }

  .comments-add__btn {
    background: none;
  }

  & .comment-box {
    flex-grow: 1;
  }

  & .comment-box-input {
    background-color: #fff;
    display: inline-flex;
    width: 84%;

    :deep(.editor__control) {
      width: 100%;
    }
  }

  & .comments-label__container {
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-right: 10px;

    label {
      width: 100%;
      text-align: right;
      font-size: 16px;
    }

    .form-button {
      display: block;
      font-size: 1.3em;
    }
  }
}

.response {
  display: flex;
}
.student-response {
  width: 50%;
  padding: 10px;
}

.answer-key {
  width: 50%;
  padding: 10px;
  :deep(table) {
    width: 100%;
  }
}

:deep(.grouped-graph) {
  width: 100% !important;
  left: auto !important;
  right: auto !important;
  margin: 0 auto !important;
}

.submissions {
  margin-left: 5px;
}

.gradable-component-response__exhausted-submissions {
  padding: 0px 10px 10px 10px;
  font-size: 16px;
  font-weight: 400;
}
</style>
