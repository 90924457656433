import { EditorState, Plugin, PluginKey } from '@tiptap/pm/state'
import { Editor, posToDOMRect } from '@tiptap/core'
import { EditorView } from '@tiptap/pm/view'
import { getAttributes } from '@tiptap/core'

interface VocabTooltipPluginProps {
  pluginKey: PluginKey | string
  editor: Editor
  onTipActivated: (tip: any) => void
}

export type VocabTooltipPluginViewProps = VocabTooltipPluginProps & {
  view: EditorView
}

export const VocabTooltipPlugin = (options: VocabTooltipPluginProps) => {
  return new Plugin({
    key:
      typeof options.pluginKey === 'string'
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    props: {
      handleClick: (view, pos, event) => {
        if (event.button !== 0) {
          return false
        }

        const resolvedPos = view.state.doc.resolve(pos)
        const marks = resolvedPos.marks()
        const vocabMark = marks.find(mark => mark.type.name === 'vocabTooltip')

        if (vocabMark?.attrs.tip && options.onTipActivated) {
          event.preventDefault()
          options.onTipActivated(vocabMark.attrs.tip)
          return true
        }

        return false // Allow normal text editing for non-tooltip clicks
      }
    }
  })
}
