<template>
  <loading-container :loading="isLoadingInitialLibrary">
    <slot
      :library="library"
      :canManageLibraryActivities="canManageLibraryActivities"
      :isLibraryViewer="isLibraryViewer"
      :updateQuery="updateQuery"
    />
  </loading-container>
</template>

<script>
import * as Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import client from 'src/shared/api-client.ts'
import useTitle from 'src/shared/hooks/title'

const PIVOT_LIBRARY = {
  id: 'pivot',
  name: 'Pivot Interactives Library',
  description:
    'This is the collection of activities created by Pivot Interactives.'
}
const COMMUNITY_LIBRARY = {
  id: 'community',
  name: 'Community Library',
  description:
    "This is a collection of activities submitted by the community of instructors who use Pivot Interactives. Use this library to see creative ways that other instructors use Pivot Interactives with their students. This library is not curated by Pivot Interactives, so it's up to users to verify the activity before using."
}

export default {
  name: 'BrowseActivities',
  provide() {
    return {
      browseActivities: Vue.computed(() => ({
        query: this.query,
        library: this.library,
        libraries: this.libraries,
        categories: this.categories,
        categoryTree: this.categoryTree,
        activities: this.activities,
        activityCount: this.activityCount,
        activityLimit: this.activityLimit,
        isLoadingLibraries: this.isLoadingLibraries,
        isLoadingActivities: this.isLoadingActivities,
        updateQuery: this.updateQuery,
        isUnvetted: this.isUnvetted,
        canManageLibraryActivities: this.canManageLibraryActivities,
        isLibraryViewer: this.isLibraryViewer,
        canManageLibrary: this.canManageLibrary,
        getLibrary: this.getLibrary,
        loadLibraries: this.loadLibraries,
        loadSelectedLibrary: this.loadSelectedLibrary,
        updateViewSelected: this.updateViewSelected,
        viewSelected: this.viewSelected,
        totalChanges: this.totalChanges,
        refreshLibrary: this.refreshLibrary
      }))
    }
  },
  props: {
    query: {
      type: Object,
      required: true,
      validator(value) {
        return (
          (!value.library || typeof value.library === 'string') &&
          typeof value.page === 'number' &&
          typeof value.sort === 'string' &&
          typeof value.dir === 'string' &&
          typeof value.q === 'string'
        )
      }
    },
    shouldLoadLibraries: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      libraries: [],
      categories: [],
      categoryTree: [],
      activities: [],
      activityCount: 0,
      activityLimit: 24,
      isLoadingLibraries: false,
      isLoadingInitialLibrary: true,
      isLoadingActivities: false,
      viewSelected: 'card',
      library: undefined
    }
  },
  computed: {
    ...mapGetters(['user', 'userId', 'isAdmin', 'isContentDeveloper']),
    ...mapState({
      user: state => state.auth.user
    }),
    canManageLibraryActivities() {
      if (!this.library) {
        return false
      }

      if (this.library.id === 'pivot') {
        return this.isAdmin || this.isContentDeveloper
      }

      const membership = this.library.members?.find(
        ({ id }) => id === this.userId
      )
      if (!membership) {
        return false
      }

      return ['editor', 'owner', 'admin'].includes(membership.permissions)
    },
    isUnvetted() {
      return this.user.vetStatus === 'unvetted'
    },
    isLibraryViewer() {
      if (!this.library) {
        return false
      }

      const membership = this.library.members?.find(
        ({ id }) => id === this.userId
      )
      if (!membership) {
        return false
      }

      return ['viewer'].includes(membership.permissions)
    },
    canManageLibrary() {
      if (!this.library) {
        return false
      }

      if (this.library.id === 'pivot') {
        return false
      }

      const membership = this.library.members?.find(
        ({ id }) => id === this.userId
      )
      if (!membership) {
        return false
      }

      return ['owner', 'admin'].includes(membership.permissions)
    },
    totalChanges() {
      return this.library?.changedActivities && this.library?.changedActivities
        ? this.library?.changedActivities?.length
        : 0
    }
  },
  methods: {
    async loadActivities() {
      if (this.query.library) {
        const request = client.libraries.searchActivities({
          libraryId: this.query.library,
          page: this.query.page,
          limit: this.activityLimit,
          ...(!!this.query.q && { text: this.query.q }),
          ...(this.query.isFree &&
            this.query.isFree !== 'both' && {
              isFree: this.query.isFree === 'true'
            }),
          ...(this.query.isArchived !== 'both' && {
            isArchived: this.query.isArchived === 'true'
          }),
          ...(this.query.autograde && {
            minAutograde: parseFloat(this.query.autograde.split('-')[0]) / 100,
            maxAutograde: parseFloat(this.query.autograde.split('-')[1]) / 100
          }),
          sort: this.query.sort,
          dir: this.query.dir,
          ...Object.keys(this.query.categories || {}).reduce((filters, key) => {
            if (this.query.categories[key]?.length > 0) {
              filters[key] = this.query.categories[key]
            }
            return filters
          }, {})
        })
        this.request = request
        const response = await request
        // If loadActivities was called while this request was in progress,
        // this.request will be the later request, so we should ignore it.
        if (request === this.request) {
          this.activities = response.page
          this.activityCount = response.total
        }
      } else {
        this.activities = []
        this.activityCount = 0
      }
    },
    async loadLibraries() {
      if (this.shouldLoadLibraries) {
        this.libraries = await client.libraries.list()
      }
    },
    async loadSelectedLibrary() {
      const library = this.getLibrary(this.query.library)
      if (library && (library.id == 'pivot' || library.id == 'community')) {
        this.library = library
      } else if (this.query.library) {
        this.library = await client.libraries.get(this.query.library)
      } else {
        this.library = undefined
      }
    },
    resolveLibrary() {
      if (!this.query.library) {
        const library = this.libraries.find(l => l.status !== 'archived')
        if (library) {
          this.updateQuery({ library: library.id })
        }
      }
      this.isLoadingInitialLibrary = false
    },
    refreshLibrary() {
      this.loadSelectedLibrary()
    },
    getLibrary(libraryId) {
      switch (libraryId) {
        case 'pivot':
          return PIVOT_LIBRARY
        case 'community':
          return COMMUNITY_LIBRARY
        default:
          return this.libraries.find(library => library.id === libraryId)
      }
    },
    updateQuery(query) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          ...query,
          categories: {
            ...this.$route.query.categories,
            ...query.categories
          }
        }
      })
    },
    updateViewSelected(view) {
      this.viewSelected = view
    },
    buildCategoryTree(list) {
      // Make one pass through to associate each category with the list of its children.
      const childrenDict = { root: { children: [] } }
      list.forEach(category => {
        let parentEntry = childrenDict[category.parent || 'root']
        let selfEntry = childrenDict[category._id]
        if (!parentEntry) {
          parentEntry = { children: [] }
          childrenDict[category.parent] = parentEntry
        }
        if (!selfEntry) {
          selfEntry = { children: [] }
          childrenDict[category._id] = selfEntry
        }
        selfEntry.category = category
        parentEntry.children.push(category)
      })

      // We make a second pass to set the children list on the category now that they are associated.
      Object.values(childrenDict).forEach(({ category, children }) => {
        if (category) {
          category.children = children
          children.forEach(child => (child.parent = category))
        }
        return category
      })
      // The first pass identified the root categories, and we return those here.
      return childrenDict.root.children
    }
  },
  async created() {
    this.isLoadingLibraries = true
    this.isLoadingActivities = true

    await Promise.all([
      this.loadLibraries().then(() => {
        this.isLoadingLibraries = false
      }),
      this.loadActivities().then(() => {
        this.isLoadingActivities = false
      })
    ])
    this.resolveLibrary()
    useTitle(() =>
      this.library ? `${this.library.name} | Activities` : 'Activities'
    )
  },
  watch: {
    async query() {
      if (this.query.library) {
        this.isLoadingActivities = true
        await this.loadActivities()
        this.isLoadingActivities = false
      }
    },
    'query.library': {
      async handler() {
        await this.loadSelectedLibrary()
      },
      immediate: true
    }
  }
}
</script>
