<template>
  <div
    class="date-input"
    :class="{
      'date-input--time': time,
      'date-input--error': !!errorMessage
    }"
  >
    <date-picker
      ref="picker"
      :model-value="inputValue"
      :disabled="disabled"
      :min-date="minDate"
      :max-date="maxDate"
      :enable-time-picker="time"
      :clearable="clearable"
      :text-input="{ openMenu: false, format: parseDate }"
      :teleport="true"
      :is-24="false"
      :auto-apply="!time"
      time-picker-inline
      :aria-labels="{ input: label }"
      @update:model-value="onChange"
      @text-input="onTextInput"
      @keydown.enter.prevent
    >
      <template
        #dp-input="{
          value,
          onInput,
          onEnter,
          onTab,
          onBlur,
          onFocus,
          onKeypress,
          onPaste,
          toggleMenu
        }"
      >
        <form-button
          class="date-input__button"
          aria-hidden="true"
          tabindex="-1"
          @click="toggleMenu"
        >
          <icon icon="calendar" />
        </form-button>
        <input
          class="dp__input"
          :value="value"
          :disabled="disabled"
          :aria-label="label"
          @input="onInput"
          @blur="onBlur"
          @focus="onFocus"
          @keydown="onKeypress"
          @paste="onPaste"
          @keydown.enter="onEnter"
          @keydown.tab="onTab"
        />
      </template>
      <template #action-row="{ selectDate, closePicker, disabled }">
        <div class="date-input__actions">
          <form-button
            v-if="clearable"
            link
            destructive
            class="mr-auto date-input__clear"
            @click="clearDate"
          >
            Clear
          </form-button>
          <form-button link class="date-input__cancel" @click="closePicker">
            Cancel
          </form-button>
          <form-button primary :disabled="disabled" @click="selectDate">
            Select
          </form-button>
        </div>
      </template>
    </date-picker>
    <span v-if="errorMessage || helpText" :id="helpId" class="date-input__help">
      {{ errorMessage || helpText }}
    </span>
  </div>
</template>

<script setup>
import { ref, toRef, watch } from 'vue'
import { useField } from 'vee-validate'
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const picker = ref()

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  id: {
    type: String,
    default: null
  },
  modelValue: {
    type: Date,
    default: null
  },
  label: {
    type: String,
    default: 'field'
  },
  name: {
    type: String,
    default: () => `date-input-${Math.floor(Math.random() * 100000)}`
  },
  rules: {
    type: [String, Object],
    default: ''
  },
  time: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  helpText: {
    type: String,
    default: null
  },
  minDate: {
    type: Date,
    default: null
  },
  maxDate: {
    type: Date,
    default: null
  },
  clearable: {
    type: Boolean,
    default: true
  }
})

const {
  value: inputValue,
  errorMessage,
  handleChange,
  resetField
} = useField(toRef(props, 'name'), toRef(props, 'rules'), {
  label: toRef(props, 'label'),
  initialValue: props.modelValue,
  syncVModel: false
})

const helpId = `date-input-help-${Math.floor(Math.random() * 100000)}`

function onTextInput(event) {
  if (!props.time) return
  //if we don't have time, then autoapply is true and we don't need this.
  const date = event.target?.value
  if (date?.valueOf() !== props.modelValue?.valueOf()) {
    handleChange(date)
    emit('update:modelValue', date)
  }
}
function onChange(date) {
  if (date?.valueOf() !== props.modelValue?.valueOf()) {
    handleChange(date)
    emit('update:modelValue', date)
  }
}

function clearDate() {
  handleChange(null)
  emit('update:modelValue', null)
}

const DATE_TIME_REGEX =
  /^\s*(\d+)\s*([/\-.])\s*(\d+)(?:\s*\2\s*(\d+))?(?:(?:\s*,\s*|\s+)(\d+)\s*:\s*(\d+)(?:\s*([APap][mM]))?)?\s*$/

function parseDate(datestr) {
  const date = new Date()
  date.setSeconds(0)
  date.setMilliseconds(0)

  const match = DATE_TIME_REGEX.exec(datestr)

  if (!match) {
    return null
  }

  if (match[4]) {
    const year = parseInt(match[4])
    date.setYear(year < 100 ? year + 2000 : year)
  }

  date.setMonth(parseInt(match[1]) - 1)
  date.setDate(parseInt(match[3]))

  if (match[5] && match[6]) {
    const isPm = match[7]?.toLowerCase() == 'pm'

    let hours = parseInt(match[5])
    date.setHours(hours < 12 && isPm ? hours + 12 : hours)
    date.setMinutes(parseInt(match[6]))
  }
  return date
}

watch(
  () => props.modelValue,
  value => {
    if (value?.valueOf() !== inputValue.value?.valueOf()) {
      resetField({ value })
    }
  }
)
</script>

<style lang="scss" scoped>
.date-input {
  width: 176px;
}
.date-input--time {
  width: 208px;
}

:global(.dp__theme_light) {
  --dp-font-size: 14px;
  --dp-font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --dp-primary-color: #{$teal};
}

:deep(.date-input__button) {
  height: 36px;
  width: 36px;
  background-color: $teal;
  color: white;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1;
  position: relative;
}

:global(.dp__input) {
  height: 36px;
  width: 100%;
  border-radius: 0 4px 4px 0;
  color: #555555;
  padding: 6px 32px 6px 46px;
  line-height: 1.6;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  position: absolute;
  left: 0;
  top: 0;
}

:global(.dp__input:focus),
:global(.dp__input:hover) {
  border-color: #ccc;
}

:global(.dp__input_focus) {
  box-shadow: $focus-shadow;
  border: 1px solid #ccc;
}

.date-input__help {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.date-input__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.date-input__cancel {
  margin-right: 8px;
}

:global(.date-input--error .dp__input) {
  border: 1px solid $color-error;
}

.date-input--error {
  .date-input__help {
    color: $color-error;
  }
}
</style>
