<template>
  <PaginatedList v-bind="pagination" @loadpage="loadPage">
    <ListColumn v-slot="{ timestamp }" title="Date">
      {{ format(parseJSON(timestamp), 'MM/dd/yyyy') }}
    </ListColumn>
    <ListColumn title="IP" property="ip" />
    <ListColumn title="Status" property="status" />
    <ListColumn title="Method" property="method" />
    <ListColumn title="User Agent" property="userAgent" />
  </PaginatedList>
</template>

<script setup lang="ts">
import { format, parseJSON } from 'date-fns'
import ListColumn from 'src/shared/components/global/ListColumn.vue'
import { ref } from 'vue'

interface LoginRecord {
  timestamp: Date
  method: string
  ip: string
  userAgent?: string
  status: 'success' | 'failure'
}
const props = defineProps<{
  loginHistory: LoginRecord[]
}>()

const chunkArray = <T,>(array: T[], size: number): T[][] => {
  const chunked_arr: T[][] = []
  let index = 0
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index))
    index += size
  }
  return chunked_arr
}

const paginatedList = ref(chunkArray(props.loginHistory, 5))
const pagination = ref({
  page: 1,
  count: props.loginHistory.length,
  limit: 5,
  items: paginatedList.value[0]
})

const loadPage = async ({ page }: { page: number }) => {
  console.log('Loading page', page)
  pagination.value = {
    ...pagination.value,
    page,
    items: paginatedList.value[page - 1]
  }
}
</script>
