import {
  GetActivityRequest,
  GetActivityResponse,
  GetActivityChangelogRequest,
  GetActivityChangelogResponse,
  GetActivityTagsResponse,
  GetPublicActivityTagsResponse,
  CreateActivityChangelogEntryRequest,
  CopyActivityRequest,
  CopyActivityResponse,
  CopyActivityBulkRequest,
  ArchiveActivityRequest,
  MoveActivityRequest,
  MoveActivityBulkRequest,
  ShareActivityRequest,
  ShareActivityBulkRequest,
  ReplaceActivityRequest,
  ArchiveActivityBulkRequest,
  UpdateActivityRequest,
  GetPreviewResponseRequest,
  GetPreviewResponseResponse,
  SubmitPreviewComponentResponseRequest,
  SubmitPreviewComponentResponseResponse,
  UpdateActivityIgnoredUpdatesRequest
} from '@pi/api-types'
import Client from './client'

export default class Activities {
  private client: Client

  constructor(client: Client) {
    this.client = client
  }

  async get({ activityId }: GetActivityRequest): Promise<GetActivityResponse> {
    const { body } = await this.client.get<GetActivityResponse>({
      path: `/api/v3/activities/${activityId}`
    })
    return body
  }

  async copy({
    activityId,
    ...request
  }: CopyActivityRequest): Promise<CopyActivityResponse> {
    const { headers } = await this.client.post({
      path: `/api/v3/activities/${activityId}/copy`,
      body: JSON.stringify(request)
    })

    const location = headers.get('location')
    if (!location) {
      throw new Error('Location header not found')
    }
    return { activityId: location?.split('/').slice(-1)[0] }
  }

  async copyBulk(request: CopyActivityBulkRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/copy`,
      body: JSON.stringify(request)
    })
  }

  async getChangelog({
    activityId
  }: GetActivityChangelogRequest): Promise<GetActivityChangelogResponse> {
    const { body } = await this.client.get<GetActivityChangelogResponse>({
      path: `/api/v3/activities/${activityId}/changelog`
    })
    return body
  }

  async createChangelogEntry({
    activityId,
    ...request
  }: CreateActivityChangelogEntryRequest): Promise<void> {
    const { body } = await this.client.post<void>({
      path: `/api/v3/activities/${activityId}/changelog`,
      body: JSON.stringify(request)
    })
    return body
  }

  async updateActivityIgnoredUpdates({
    activityId,
    remove
  }: UpdateActivityIgnoredUpdatesRequest): Promise<void> {
    await this.client.put({
      path: `/api/v3/activities/${activityId}/ignored-updates`,
      body: JSON.stringify({ remove })
    })
    return
  }

  async archive({
    activityId,
    ...request
  }: ArchiveActivityRequest): Promise<void> {
    await this.client.put({
      path: `/api/v3/activities/${activityId}/archive`,
      body: JSON.stringify(request)
    })
  }

  async archiveBulk(request: ArchiveActivityBulkRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/archive`,
      body: JSON.stringify(request)
    })
  }

  async getTags(): Promise<GetActivityTagsResponse> {
    const { body } = await this.client.get<GetActivityTagsResponse>({
      path: `/api/v3/activities/tags`
    })
    return body
  }

  async getPublicTags(): Promise<GetPublicActivityTagsResponse> {
    const { body } = await this.client.get<GetPublicActivityTagsResponse>({
      path: `/api/v3/activities/public-tags`
    })
    return body
  }

  async getCategories(): Promise<GetActivityTagsResponse> {
    const { body } = await this.client.get<GetActivityTagsResponse>({
      path: `/api/v3/activities/categories`
    })
    return body
  }

  async move({ activityId, ...request }: MoveActivityRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/${activityId}/move`,
      body: JSON.stringify(request)
    })
  }

  async moveBulk(request: MoveActivityBulkRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/move`,
      body: JSON.stringify(request)
    })
  }

  async share({ activityId, ...request }: ShareActivityRequest): Promise<void> {
    await this.client.put({
      path: `/api/v3/activities/${activityId}/shared`,
      body: JSON.stringify(request)
    })
  }

  async shareBulk(request: ShareActivityBulkRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/shared`,
      body: JSON.stringify(request)
    })
  }

  async replace({
    activityId,
    ...request
  }: ReplaceActivityRequest): Promise<void> {
    await this.client.post({
      path: `/api/v3/activities/${activityId}/replace`,
      body: JSON.stringify(request)
    })
  }

  async update({
    activityId,
    ...request
  }: UpdateActivityRequest): Promise<void> {
    await this.client.patch({
      path: `/api/v3/activities/${activityId}`,
      body: JSON.stringify(request)
    })
  }

  async getActivityPreviewResponse(
    request: GetPreviewResponseRequest
  ): Promise<GetPreviewResponseResponse> {
    const { body } = await this.client.get<GetPreviewResponseResponse>({
      path: `/api/v3/activities/${request.activityId}/preview-response`
    })
    return body
  }

  async submitPreviewComponentResponse(
    request: SubmitPreviewComponentResponseRequest
  ): Promise<SubmitPreviewComponentResponseResponse> {
    const { activityId, componentId, ...rest } = request
    const { body } =
      await this.client.post<SubmitPreviewComponentResponseResponse>({
        path: `/api/v3/activities/${activityId}/preview-response/component/${componentId}`,
        body: JSON.stringify(rest)
      })
    return body
  }
}
